import React from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import styled from 'styled-components';

// Utility function for contrast color
function getContrastColor(backgroundColor: string): string {
  const hex = backgroundColor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5 ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)';
}

interface PaymentTermsEditorProps {
  initialContent: string;
  onUpdate: (content: string) => void;
  backgroundColor?: string;
  bodyTextColor?: string;
}

const PaymentTermsEditor: React.FC<PaymentTermsEditorProps> = ({ 
  initialContent, 
  onUpdate, 
  backgroundColor,
  bodyTextColor
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Payment details...',
      }),
    ],
    content: initialContent,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onUpdate(html);
    },
  });

  return (
    <EditorWrapper $backgroundColor={backgroundColor} $bodyTextColor={bodyTextColor}>
      <EditorContent editor={editor} />
    </EditorWrapper>
  );
};

const EditorWrapper = styled.div<{ $backgroundColor?: string; $bodyTextColor?: string }>`

  .ProseMirror {
    outline: none;
    border-radius: 4px;
    background-color: ${props => props.$backgroundColor || 'transparent'};
    color: ${props => props.$bodyTextColor || 'inherit'};
    font-family: inherit;
    padding: 32px 76px 76px 76px;
    box-sizing: border-box;

    p.is-editor-empty:first-child::before {
      color: ${props => props.$backgroundColor ? getContrastColor(props.$backgroundColor) : 'rgba(0, 0, 0, 0.5)'};
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

export default PaymentTermsEditor; 