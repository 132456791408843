import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { useOrganization } from './useOrganization';

const defaultBrandSettings = {
  default_template: "minimalist",
  default_font: "Inter",
  default_header_color: "#ffffff",
  default_background_color: "#ffffff",
  default_header_text_color: "#000000",
  default_body_text_color: "#000000",
  auto_color_text: false,
  show_logo: true,
  default_payment_terms: "",
};

export function useBrandSettings() {
  const { data: organizationId } = useOrganization();

  return useQuery({
    queryKey: ['brandSettings', organizationId],
    queryFn: async () => {
      if (!organizationId) throw new Error("Organization not found");

      const { data, error } = await supabase
        .from("brand_settings")
        .select("*")
        .eq("organization_id", organizationId);

      if (error) {
        console.error("Error fetching brand settings:", error);
        return defaultBrandSettings;
      }

      if (data && data.length > 0) {
        return data[0];
      } else {
        console.log("No brand settings found for this organization. Using default values.");
        return defaultBrandSettings;
      }
    },
    enabled: !!organizationId,
  });
}