import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import EntityPicker from '../EntityPicker';
import { Client, Project, OrganizationUser } from '../../types';
import { Client12, Project12, Close12, Trash12 } from '../Icon';
import Button from '../Button';

const OverlayWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
`;

const OverlayContainer = styled(motion.div)`
  max-width: calc(100vw - 40px);
  background-color: #fff;
  box-shadow: 
    0 1px 0 0 rgba(0, 0, 0, 0.025),
    0 1px 2px rgba(0, 0, 0, 0.1),
    0 4px 8px rgba(0, 0, 0, 0.05), 
    0 8px 8px rgba(0, 0, 0, 0.1),
    0 0 0 0.5px rgba(0, 0, 0, 0.1);
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 12px;
  user-select: none;
`;

const OverlayContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectedCount = styled.div`
  font-weight: 500;
  margin-right: 4px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.5);

`;

const SelectedCountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CloseButton = styled(Button)`
  background-color: transparent;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const PickersContainer = styled.div`
  display: flex;
  gap: 4px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 12px;
`;

const DeleteButton = styled(Button)`
  color: #ff4d4f;
  background-color: transparent;
  svg path {
      fill: #ff4d4f;
    }
  &:hover {
    color: #ff4d4f;
  }
`;

interface SelectedTasksOverlayProps {
  selectedCount: number;
  clients: Client[];
  projects: Project[];
  organizationUsers: OrganizationUser[];
  onClientChange: (clientId: string | null) => void;
  onProjectChange: (projectId: string | null) => void;
  onAssigneeChange: (userId: string | null) => void;
  onDelete: () => void;
  onClearSelection: () => void;
}

const SelectedTasksOverlay: React.FC<SelectedTasksOverlayProps> = ({
  selectedCount,
  clients,
  projects,
  organizationUsers,
  onClientChange,
  onProjectChange,
  onAssigneeChange,
  onDelete,
  onClearSelection,
}) => {
  return (
    <AnimatePresence>
      {selectedCount > 0 && (
        <OverlayWrapper
          as={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{
            duration: 0.1,
            ease: "easeInOut"
          }}
        >
          <OverlayContainer>
            <OverlayContent>
              <SelectedCountWrapper>
                <SelectedCount>{selectedCount} task{selectedCount !== 1 ? 's' : ''} selected</SelectedCount>
                <CloseButton onClick={onClearSelection}>
                  <Close12 />
                </CloseButton>
              </SelectedCountWrapper>
              <PickersContainer>
                <EntityPicker
                  selectedId={null}
                  onChange={onClientChange}
                  entities={clients.map(client => ({ id: client.id, name: client.full_name }))}
                  label="Client"
                  allowUnassigned={true}
                  icon={<Client12 />}
                  placement="top-start"
                />
                <EntityPicker
                  selectedId={null}
                  onChange={onProjectChange}
                  entities={projects.map(project => ({ 
                    id: project.id, 
                    name: project.name || 'Untitled Project' 
                  }))}
                  label="Project"
                  allowUnassigned={true}
                  icon={<Project12 />}
                  placement="top-start"
                />
                <EntityPicker
                  selectedId={null}
                  onChange={onAssigneeChange}
                  entities={organizationUsers.map(user => ({ id: user.id, name: user.full_name || user.email }))}
                  label="Assign"
                  allowUnassigned={true}
                  icon={<Close12 />}
                  placement="top-start"
                />
                <DeleteButton onClick={onDelete} disabled={selectedCount === 0}>
                  <Trash12 /> Delete
                </DeleteButton>
              </PickersContainer>
            </OverlayContent>
          </OverlayContainer>
        </OverlayWrapper>
      )}
    </AnimatePresence>
  );
};

export default SelectedTasksOverlay;