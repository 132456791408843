import { z } from 'zod';

export const invoiceItemSchema = z.object({
  id: z.string().optional(),
  invoice_id: z.string().optional(),
  description: z.string().min(0, 'Description is required'),
  quantity: z.number().min(0, 'Quantity must be positive'),
  price: z.number().min(0, 'Price must be positive'),
  taxable: z.boolean(),
  order: z.number().min(0)
});

const dateSchema = z.string().refine(
  (date) => {
    const parsed = new Date(date);
    return !isNaN(parsed.getTime());
  },
  {
    message: "Invalid date format"
  }
);

export const invoiceSchema = z.object({
  id: z.string().optional(),
  organization_id: z.string().min(1, 'Organization is required'),
  client_id: z.string().min(1, 'Client is required'),
  project_id: z.string().nullable(),
  invoice_number: z.string().nullable(),
  invoice_date: dateSchema,
  due_date: dateSchema.nullable(),
  due_days: z.number().min(0).nullable(),
  status: z.enum(['draft', 'unpaid', 'paid', 'overdue']),
  currency: z.string().min(1, 'Currency is required'),
  tax_rate: z.number().min(0).max(100, 'Tax rate must be between 0 and 100'),
  subtotal: z.number().min(0),
  total: z.number().min(0),
  amount_due: z.number().min(0),
  notes: z.string().optional().or(z.literal('')),
  payment_terms: z.string().optional().or(z.literal('')),
  items: z.array(invoiceItemSchema),
  invoice_template: z.enum(['minimalist', 'detailed', 'structure', 'boxed', 'gradient', 'circular', 'compact', 'split']),
  header_color: z.string().optional(),
  header_text_color: z.string().optional(),
  background_color: z.string().optional(),
  body_text_color: z.string().optional(),
  font: z.string().optional(),
  show_logo: z.boolean().optional(),
  collect_stripe_payments: z.boolean().optional()
}).refine(
  (data) => {
    if (data.due_date && data.invoice_date) {
      const dueDate = new Date(data.due_date);
      const invoiceDate = new Date(data.invoice_date);
      return !isNaN(dueDate.getTime()) && !isNaN(invoiceDate.getTime()) && dueDate >= invoiceDate;
    }
    return true;
  },
  {
    message: "Due date must be after or equal to invoice date",
    path: ["due_date"]
  }
);

export type InvoiceSchema = z.infer<typeof invoiceSchema>;
export type InvoiceItemSchema = z.infer<typeof invoiceItemSchema>; 