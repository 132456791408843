import React, { useState, useCallback } from "react";
import { useNavigate, Link } from '@tanstack/react-router';
import styled from "styled-components";
import { useOrganization } from "./hooks/useOrganization";
import Button from "./components/Button";
import PageSkeleton from "./components/PageSkeleton";
import Tabs from "./components/Tabs";
import { usePageContext } from "./hooks/usePageContext";
import { Outlet } from '@tanstack/react-router';
import AddServiceDrawer from "./components/Services/AddServiceDrawer";
import { Service } from "./types";

const PageContainer = styled.div`
  height: calc(100vh - 60px);
  overflow-y: auto;
  width: 100%;
`;

const MainContent = styled.div`
  padding: 20px 48px;
  width: 100%;
  min-height: 0;
  box-sizing: border-box;
`;

const SettingsPage: React.FC = () => {
  const { setPageHeaderProps } = usePageContext();
  const navigate = useNavigate();
  const {
    data: organizationId,
    isLoading,
    error,
  } = useOrganization();

  // State for AddServiceDrawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editingService, setEditingService] = useState<Service | null>(null);

  // Handle opening the drawer for creating a new service
  const handleCreateService = useCallback(() => {
    setEditingService(null);
    setIsDrawerOpen(true);
  }, []);

  React.useEffect(() => {
    setPageHeaderProps({
      title: "Settings",
    });
  }, [setPageHeaderProps]);

  if (isLoading) {
    return <PageSkeleton title="Settings" />;
  }

  if (error) {
    return <PageContainer>Error: {error.message}</PageContainer>;
  }

  if (!organizationId) {
    return (
      <PageContainer>
        <MainContent>
          <p>
            Please select an organization to manage its settings or create a new
            one.
          </p>
          <Button onClick={() => navigate({ to: "/settings" })}>
            Select Organization
          </Button>
        </MainContent>
      </PageContainer>
    );
  }

  const activeTab = window.location.pathname.split('/settings/')[1] || 'profile';

  // Create action buttons based on active tab
  const getActionButtons = () => {
    if (activeTab === "services") {
      return (
        <Button buttonType="primary" onClick={handleCreateService}>
          New Service
        </Button>
      );
    }
    return null;
  };

  const tabs = [
    { id: "profile", label: "Profile", to: "/settings/profile" },
    { id: "organization", label: "Organization", to: "/settings/organization" },
    { id: "users", label: "Users", to: "/settings/users" },
    { id: "services", label: "Services", to: "/settings/services" },
    { id: "subscription", label: "Subscription", to: "/settings/subscription" },
    { id: "payments", label: "Payments", to: "/settings/payments" },
  ];

  return (
    <PageContainer>
      <Tabs
        tabs={tabs.map(tab => ({
          id: tab.id,
          label: tab.label,
          content: <Link to={tab.to}>{tab.label}</Link>
        }))}
        activeTab={activeTab}
        onTabChange={(tabId) => navigate({ to: `/settings/${tabId}` })}
        actionButtons={getActionButtons()} // Use the built-in actionButtons prop
      />
      <MainContent>
        <Outlet />
      </MainContent>
      
      <AddServiceDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        serviceToEdit={editingService}
      />
    </PageContainer>
  );
};

export default SettingsPage;
