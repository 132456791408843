import { z } from 'zod';

export const timeEntrySchema = z.object({
  organization_id: z.string().min(1, 'Organization is required'),
  client_id: z.string().nullable(),
  project_id: z.string().nullable(),
  service_id: z.string().nullable(),
  start_time: z.string().datetime('Invalid start time'),
  end_time: z.string().datetime('Invalid end time').nullable(),
  duration: z.number().min(0, 'Duration must be positive'),
  description: z.string().optional().or(z.literal('')),
  is_billable: z.boolean(),
}).refine(
  (data) => {
    // If end_time exists, it must be after start_time
    if (data.end_time) {
      return new Date(data.end_time) > new Date(data.start_time);
    }
    return true;
  },
  {
    message: "End time must be after start time",
    path: ["end_time"]
  }
).refine(
  (data) => {
    // If both times exist, duration should match their difference
    if (data.end_time) {
      const durationMs = new Date(data.end_time).getTime() - new Date(data.start_time).getTime();
      const durationSeconds = Math.floor(durationMs / 1000);
      return data.duration === durationSeconds;
    }
    return true;
  },
  {
    message: "Duration must match time difference",
    path: ["duration"]
  }
);

export type TimeEntrySchema = z.infer<typeof timeEntrySchema>; 