import { Invoice, Expense } from '../types';
import { format } from 'date-fns';
import { Client, Payment } from '../types';

export function exportInvoicesToCSV(invoices: Invoice[], clients: Client[], payments: Payment[] = []): string {
  // CSV Headers
  const headers = [
    'Invoice Number',
    'Client Name',
    'Invoice Date',
    'Due Date',
    'Status',
    'Subtotal',
    'Total Amount',
    'Amount Due',
    'Amount Paid',
    'Latest Payment Date',
    'All Payment Dates',
    'Currency',
    'Payment Terms',
    'Client Email',
    'Client Company',
    'Client Address',
    'Client City',
    'Client State',
    'Client Postal Code',
    'Client Country'
  ].join(',');

  // Format each invoice into a CSV row
  const rows = invoices.map(invoice => {
    const client = clients.find(c => c.id === invoice.client_id);
    const formatDate = (date: string) => format(new Date(date), 'yyyy-MM-dd');
    const formatAmount = (amount: number) => amount.toFixed(2);
    
    const subtotal = invoice.subtotal || 0;
    
    // Get all payments for this invoice
    const invoicePayments = payments.filter(p => p.invoice_id === invoice.id);
    const totalPaid = invoicePayments.reduce((sum, p) => sum + (p.amount || 0), 0);
    
    // Sort payments by date and get the latest
    const sortedPayments = [...invoicePayments].sort((a, b) => 
      new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime()
    );
    const latestPayment = sortedPayments[0];
    
    // Format all payment dates
    const allPaymentDates = sortedPayments
      .map(p => formatDate(p.payment_date))
      .join('; ');
    
    return [
      `"${invoice.invoice_number || ''}"`,
      `"${client?.full_name || ''}"`,
      formatDate(invoice.invoice_date),
      formatDate(invoice.due_date),
      `"${invoice.status || ''}"`,
      formatAmount(subtotal),
      formatAmount(invoice.total || 0),
      formatAmount(invoice.amount_due || 0),
      formatAmount(totalPaid),
      latestPayment ? formatDate(latestPayment.payment_date) : '',
      `"${allPaymentDates}"`,
      `"${invoice.currency || 'USD'}"`,
      `"${invoice.payment_terms || ''}"`,
      `"${client?.email || ''}"`,
      `"${client?.company || ''}"`,
      `"${client?.address_line1 || ''}"`,
      `"${client?.city || ''}"`,
      `"${client?.state || ''}"`,
      `"${client?.postal_code || ''}"`,
      `"${client?.country || ''}"`,
    ].join(',');
  });

  // Combine headers and rows
  return [headers, ...rows].join('\n');
}

export function exportExpensesToCSV(expenses: Expense[]): string {
  // CSV Headers
  const headers = [
    'Date',
    'Merchant',
    'Description',
    'Category',
    'Amount',
    'Client Name',
    'Project Name',
    'Invoice Status',
    'Invoice Number',
    'Receipt Path'
  ].join(',');

  // Format each expense into a CSV row
  const rows = expenses.map(expense => {
    const formatDate = (date: string) => format(new Date(date), 'yyyy-MM-dd');
    const formatAmount = (amount: number) => amount.toFixed(2);
    
    // Check if expense is invoiced and get invoice number
    const isInvoiced = expense.expense_invoice_items && expense.expense_invoice_items.length > 0;
    const invoiceNumber = isInvoiced && expense.expense_invoice_items?.[0]?.invoice_item?.invoice?.invoice_number;
    
    return [
      formatDate(expense.date),
      `"${expense.merchant || ''}"`,
      `"${expense.description || ''}"`,
      `"${expense.category || ''}"`,
      formatAmount(expense.amount),
      `"${expense.client?.full_name || ''}"`,
      `"${expense.project?.name || ''}"`,
      `"${isInvoiced ? 'Invoiced' : 'Not Invoiced'}"`,
      `"${invoiceNumber || ''}"`,
      `"${expense.receipt_image_path || ''}"`,
    ].join(',');
  });

  // Combine headers and rows
  return [headers, ...rows].join('\n');
}

export function downloadCSV(csvContent: string, filename: string) {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
} 