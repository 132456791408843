import React from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_CONFIG } from '../config/stripe';
import { useNavigate } from '@tanstack/react-router';

const stripePromise = loadStripe(STRIPE_CONFIG.PUBLIC_KEY);

interface Subscription {
    id: string;
    organization_id: string;
    status: string;
    trial_end: string | null;
    trial_start: string | null;
    current_period_end: string;
    stripe_subscription_id: string | null;
    stripe_customer_id: string | null;
    price_id: string | null;
}

interface OrganizationMember {
    role: string;
    organization_id: string;
    is_owner: boolean;
}

export const useSubscription = (organizationId?: string) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { data: orgMember } = useQuery({
        queryKey: ['organizationMember', organizationId],
        queryFn: async () => {
            if (!organizationId) return null;
            console.log('Fetching organization member for:', organizationId);
            
            const { data: { user } } = await supabase.auth.getUser();
            if (!user) {
                console.log('No authenticated user found');
                return null;
            }

            const { data, error } = await supabase
                .from('organization_members')
                .select('role, organization_id, is_owner')
                .eq('organization_id', organizationId)
                .eq('user_id', user.id)
                .single();

            if (error) {
                console.error('Error fetching organization member:', error);
                throw error;
            }
            return data as OrganizationMember;
        },
        enabled: !!organizationId,
    });

    const { data: subscription, error: subscriptionError } = useQuery<Subscription | null>({
        queryKey: ['subscription', organizationId],
        queryFn: async () => {
            if (!organizationId) return null;
            console.log('Fetching subscription for organization:', organizationId);
            
            const { data, error } = await supabase
                .from('subscriptions')
                .select('*')
                .eq('organization_id', organizationId)
                .order('created_at', { ascending: false })
                .limit(1)
                .maybeSingle();

            if (error) {
                console.error('Subscription query error:', error);
                throw error;
            }

            console.log('Raw subscription data:', { 
                data, 
                error, 
                hasStripeIds: data?.stripe_subscription_id && data?.stripe_customer_id 
            });
            return data;
        },
        enabled: !!organizationId,
        retry: false,
        staleTime: 0 // Ensure we always get fresh data
    });

    // Log subscription error if present
    React.useEffect(() => {
        if (subscriptionError) {
            console.error('Subscription hook error:', subscriptionError);
        }
    }, [subscriptionError]);

    const createCheckoutSession = useMutation({
        mutationFn: async (billingInterval: 'month' | 'year') => {
            console.log('Starting checkout session creation...');
            
            const stripe = await stripePromise;
            if (!stripe) throw new Error('Stripe not initialized');
            
            console.log('Organization ID:', organizationId);
            if (!organizationId) throw new Error('No organization selected');
            
            console.log('Organization member:', orgMember);
            if (!orgMember || (orgMember.role !== 'admin' && !orgMember.is_owner)) {
                throw new Error('Only organization admins and owners can manage subscriptions');
            }

            console.log('Creating checkout session for organization:', organizationId);

            // Get the current session
            const sessionResult = await supabase.auth.getSession();
            console.log('Auth session result:', {
                session: sessionResult.data.session,
                error: sessionResult.error,
                hasAccessToken: !!sessionResult.data.session?.access_token
            });

            const { data: { session: authSession } } = sessionResult;
            if (!authSession) throw new Error('Not authenticated');

            console.log('Making API request with token:', {
                tokenLength: authSession.access_token.length,
                tokenPrefix: authSession.access_token.substring(0, 10) + '...',
                url: '/api/create-checkout-session'
            });

            const response = await fetch('/api/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authSession.access_token}`
                },
                body: JSON.stringify({
                    priceId: billingInterval === 'month' 
                        ? STRIPE_CONFIG.MONTHLY_PRICE_ID 
                        : STRIPE_CONFIG.ANNUAL_PRICE_ID,
                    successUrl: `${window.location.origin}/settings/subscription?success=true`,
                    cancelUrl: `${window.location.origin}/settings/subscription?canceled=true`,
                    organizationId,
                }),
            });

            console.log('API Response:', {
                status: response.status,
                statusText: response.statusText,
                headers: Object.fromEntries(response.headers.entries())
            });

            if (!response.ok) {
                const error = await response.json();
                console.error('Checkout session creation failed:', {
                    error,
                    status: response.status,
                    statusText: response.statusText
                });
                throw new Error(error.message || 'Failed to create checkout session');
            }

            const checkoutSession = await response.json();
            console.log('Checkout session created:', checkoutSession);

            const result = await stripe.redirectToCheckout({
                sessionId: checkoutSession.id,
            });

            if (result.error) {
                console.error('Stripe redirect error:', result.error);
                throw new Error(result.error.message);
            }
        },
        onError: (error) => {
            console.error('Create checkout session error:', {
                error,
                message: error.message,
                stack: error.stack
            });
            if (error.message === 'Only organization admins and owners can manage subscriptions') {
                navigate({ to: '/settings/organization' });
            }
        },
    });

    const createPortalSession = useMutation({
        mutationFn: async () => {
            console.log('Creating portal session...');
            if (!organizationId) throw new Error('No organization selected');

            const { data: { session } } = await supabase.auth.getSession();
            if (!session) throw new Error('Not authenticated');

            const response = await fetch('/api/create-portal-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${session.access_token}`
                },
                body: JSON.stringify({
                    organizationId,
                    returnUrl: `${window.location.origin}/settings/subscription`
                }),
            });

            if (!response.ok) {
                const error = await response.json();
                console.error('Portal session creation failed:', error);
                throw new Error(error.message || 'Failed to create portal session');
            }

            const { url } = await response.json();
            console.log('Created portal session, redirecting...');
            window.location.href = url;
        }
    });

    const cancelSubscription = useMutation({
        mutationFn: async () => {
            if (!organizationId) throw new Error('No organization selected');
            if (!orgMember || (orgMember.role !== 'admin' && !orgMember.is_owner)) {
                throw new Error('Only organization admins and owners can manage subscriptions');
            }
            if (!subscription?.stripe_subscription_id) {
                throw new Error('No active paid subscription to cancel');
            }
            
            console.log('Cancelling subscription:', subscription.stripe_subscription_id);

            // Get the current session
            const { data: { session: authSession } } = await supabase.auth.getSession();
            if (!authSession) throw new Error('Not authenticated');

            const response = await fetch('/api/cancel-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authSession.access_token}`
                },
                body: JSON.stringify({
                    subscriptionId: subscription.stripe_subscription_id,
                    organizationId,
                }),
            });

            if (!response.ok) {
                const error = await response.json();
                console.error('Cancel subscription failed:', error);
                throw new Error(error.message || 'Failed to cancel subscription');
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['subscription', organizationId] });
        },
        onError: (error) => {
            console.error('Cancel subscription error:', error);
            if (error.message === 'Only organization admins and owners can manage subscriptions') {
                navigate({ to: '/settings/organization' });
            }
        },
    });

    const isActive = subscription?.status === 'active' || 
        (subscription?.status === 'trialing' && subscription.trial_end && new Date(subscription.trial_end) > new Date());

    const isTrialing = subscription?.status === 'trialing' && 
        subscription.trial_end && new Date(subscription.trial_end) > new Date();

    const trialDaysRemaining = subscription?.trial_end 
        ? Math.max(0, Math.ceil((new Date(subscription.trial_end).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)))
        : 0;

    const isPaidSubscription = subscription?.stripe_customer_id != null && 
        subscription?.stripe_subscription_id != null;

    const canManageSubscription = orgMember?.role === 'admin' || orgMember?.is_owner === true;

    const isMonthlyPlan = subscription?.price_id === STRIPE_CONFIG.MONTHLY_PRICE_ID;
    const annualSavings = isMonthlyPlan ? (STRIPE_CONFIG.MONTHLY_PRICE * 12) - STRIPE_CONFIG.ANNUAL_PRICE : 0;

    const getPlanDetails = () => {
        if (!subscription) return null;

        const isMonthly = subscription.price_id === STRIPE_CONFIG.MONTHLY_PRICE_ID;
        const price = isMonthly ? STRIPE_CONFIG.MONTHLY_PRICE : STRIPE_CONFIG.ANNUAL_PRICE / 12;

        if (isTrialing && subscription.trial_end) {
            if (isPaidSubscription) {
                return `Pro Plan - $${price}/month${!isMonthly ? ' billed annually' : ''}`;
            }
            const trialEndDate = new Date(subscription.trial_end).toLocaleDateString();
            return `Pro Trial (Ends ${trialEndDate})`;
        }

        if (subscription.status === 'active') {
            return `Pro Plan - $${price}/month${!isMonthly ? ' billed annually' : ''}`;
        }

        return null;
    };

    return {
        subscription,
        error: subscriptionError,
        isActive,
        isTrialing,
        trialDaysRemaining,
        isPaidSubscription,
        canManageSubscription,
        createCheckoutSession,
        cancelSubscription,
        planDetails: getPlanDetails(),
        isMonthlyPlan,
        annualSavings,
        createPortalSession,
    };
}; 