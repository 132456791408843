import styled, { css } from 'styled-components';
import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $primary?: boolean;
  $size?: 'small' | 'medium' | 'large' | 'icon';
  $buttonType?: 'primary' | 'secondary' | 'icon' | 'outline' | 'danger';
  $as?: React.ElementType;
  $dark?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: background-color 0.2s ease, color 0.2s ease;
  font-family: inherit;
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  user-select: none;

  ${({ $buttonType, $dark }) => {
    switch ($buttonType) {
      case 'primary':
        return css`
          background-color: #FFFFFF;
          color: rgba(0,0,0,0.6);
          box-shadow: 0 1px 0 rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.05), 0 1px 4px rgba(0,0,0,0.05), 0 2px 6px rgba(0,0,0,0.05);
          transition: transform 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
          svg path {
            fill: currentColor;
          }
          &:hover {
            color: rgba(0,0,0,0.8);
            box-shadow: 0 1px 0 rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.1), 0 2px 4px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.05);
          }
          &:active {
            margin-top: 0px;
            transform: scale(0.98);
            box-shadow: 0 0 0 0.5px rgba(0,0,0,0.05), 0 1px 4px rgba(0,0,0,0.05), 0 2px 8px rgba(0,0,0,0.05);
          }
        `;
      case 'secondary':
        return css`
          background-color: transparent;
          color: ${$dark ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'};
          &:hover {
            color: ${$dark ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,0.8)'};
            background-color: ${$dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'};
          }
        `;
      case 'icon':
        return css`
          width: 28px;
          height: 28px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          color: ${$dark ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'};
          &:hover {
            color: ${$dark ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,0.8)'};
            background-color: ${$dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'};
            svg path {
              fill: ${$dark ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,0.8)'};
            }
          }
          svg path {
            fill: ${$dark ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'};
          }
        `;
      case 'outline':
        return css`
          background-color: transparent;
          color: ${$dark ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.5)'};
          box-shadow: inset 0 0 0 1px ${$dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'};
          &:hover {
            background-color: ${$dark ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'};
            color: ${$dark ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)'};
          }
        `;
      case 'danger':
        return css`
          background-color: #dc3545;
          color: white;
          border: none;

          &:hover:not(:disabled) {
            background-color: #c82333;
          }

          &:active:not(:disabled) {
            background-color: #bd2130;
          }
        `;
      default:
        return css`
          background-color: ${$dark ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)'};
          color: ${$dark ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'};
          svg path {
            fill: ${$dark ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)'};
          }
          &:hover {
            background-color: ${$dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'};
            color: ${$dark ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,0.8)'};
            svg path {
              fill: ${$dark ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,0.8)'};
            }
          }
        `;
    }
  }}

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return 'padding: 4px 12px; font-size: 14px;';
      case 'large':
        return 'padding: 12px 24px; font-size: 14px;';
      case 'icon':
        return 'width: 28px; height: 28px; padding: 0;';
      default:
        return 'padding: 4px 8px; font-size: 14px;';
    }
  }}
`;

interface ComponentProps extends Omit<ButtonProps, '$primary' | '$size' | '$buttonType' | '$as' | '$dark'> {
  primary?: boolean;
  size?: 'small' | 'medium' | 'large' | 'icon';
  buttonType?: | 'primary' | 'secondary' | 'icon' | 'outline' | 'danger';
  as?: React.ElementType;
  dark?: boolean;
}

const Button: React.FC<ComponentProps> = ({ primary, size, buttonType, as, dark, children, ...props }) => (
  <StyledButton $primary={primary} $size={size} $buttonType={buttonType} $dark={dark} as={as} {...props}>{children}</StyledButton>
);

export default Button;
