import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { toast } from 'sonner';

export const useLogoOperations = (organizationId: string | undefined) => {
  const queryClient = useQueryClient();

  const handleLogoUpload = useCallback(async (uploadedLogoUrl: string) => {
    try {
      if (!organizationId) return;

      const toastId = toast.loading('Updating logo...');
      const { error } = await supabase
        .from('organizations')
        .update({ logo_url: uploadedLogoUrl })
        .eq('id', organizationId);

      if (error) throw error;

      // Invalidate all relevant queries to refetch the updated data
      queryClient.invalidateQueries({ queryKey: ['organization', organizationId] });
      queryClient.invalidateQueries({ queryKey: ['organizationDetails', organizationId] });
      queryClient.invalidateQueries({ queryKey: ['logoUrl', organizationId] });
      queryClient.invalidateQueries({ queryKey: ['userOrganizations'] });

      toast.success('Logo updated successfully', { id: toastId });
    } catch (error) {
      console.error('Error updating logo:', error);
      toast.error('Failed to update logo');
    }
  }, [organizationId, queryClient]);

  const handleLogoRemove = useCallback(async (fileName: string | null) => {
    try {
      if (!organizationId) return;

      const toastId = toast.loading('Removing logo...');
      // Update the organization table
      const { error: updateError } = await supabase
        .from('organizations')
        .update({ logo_url: null })
        .eq('id', organizationId);

      if (updateError) throw updateError;

      // Remove the file from storage if fileName is provided
      if (fileName) {
        const { error: storageError } = await supabase.storage
          .from('logos')
          .remove([fileName]);

        if (storageError) throw storageError;
      }

      // Invalidate all relevant queries to refetch the updated data
      queryClient.invalidateQueries({ queryKey: ['organization', organizationId] });
      queryClient.invalidateQueries({ queryKey: ['organizationDetails', organizationId] });
      queryClient.invalidateQueries({ queryKey: ['logoUrl', organizationId] });
      queryClient.invalidateQueries({ queryKey: ['userOrganizations'] });

      toast.success('Logo removed successfully', { id: toastId });
    } catch (error) {
      console.error('Error removing logo:', error);
      toast.error('Failed to remove logo');
    }
  }, [organizationId, queryClient]);

  return {
    handleLogoUpload,
    handleLogoRemove
  };
}; 