import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { InvoiceTemplatesProps } from './types';
import { ClientSelector } from '../components/Clients/ClientSelector';
import DatePicker from '../components/DatePicker';
import { addDays } from 'date-fns';
import { calculateInvoiceTotals } from '../utils/invoiceCalculations';
import { InvoiceData } from '../types';
import AnimatedAmount from '../components/AnimatedAmount';
import { Organization, Client } from '../types';
import { getContrastColor } from '../utils/contrast';

const mobile = (inner: TemplateStringsArray) => css`
  @media (max-width: 768px) {
    ${inner}
  }
`;

const FontWrapper = styled.div<{ $font: string }>`
  font-family: ${props => props.$font};
`;

const HeaderContainer = styled.div.attrs<{ $headerColor: string }>(props => ({
  style: {
    backgroundColor: props.$headerColor || '#f8f8f8',
  },
}))`
  padding: 48px 80px;
  position: relative;
  overflow: hidden;
  ${mobile`
    padding: 24px;
  `}
`;

const HeaderContent = styled.div<{ $headerTextColor: string }>`
  color: ${props => props.$headerTextColor || 'inherit'};
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  ${mobile`
    grid-template-columns: 1fr;
    gap: 16px;
  `}
`;

const DetailRow = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  width: 50%;

  //max-content
  width: fit-content;
`;

const Input = styled.input<{ $headerColor?: string; $isReadOnly?: boolean }>`
  border: none;
  font-family: inherit;
  background: transparent;
  outline: none;
  padding: 4px 8px;
  margin: 0;
  color: inherit;
  width: ${props => props.width || 'auto'};
  min-width: 60px;
  border-radius: 8px;
  &::placeholder {
    color: inherit;
  }
  &:hover {
    background-color: ${props => !props.$isReadOnly && (props.$headerColor ? getContrastColor(props.$headerColor) : 'rgba(0, 0, 0, 0.05)')};
  }
`;

const InputWrapper = styled.div<{ $headerColor: string; $isGenerating?: boolean }>`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  display: inline-block;
  
  ${props => {
    return props.$isGenerating && `
      //background: rgba(0, 0, 0, 0.05);
      
      &::after {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 1;
        background: linear-gradient(
          90deg,
          transparent 0%,
          ${props.$headerColor} 50%,
          transparent 100%
        );
        animation: shimmer 1.5s infinite linear;
        pointer-events: none;
      }

      @keyframes shimmer {
        from {
          transform: translateX(-200%);
        }
        to {
          transform: translateX(200%);
        }
      }
    `
  }}
`;

const HiddenSpan = styled.span`
  position: absolute;
  visibility: hidden;
  white-space: pre;
`;

const Logo = styled.img`
  max-width: 96px;
  max-height: 96px;
  object-fit: contain;
  grid-column: 1 / -1;
  margin: 80px 0 120px 0;
  ${mobile`
    margin: 64px 0 72px 0;
  `}
`;

const TopItem = styled.div`
  display: flex;
  grid-column: 1 / -1;
  justify-content: center;
  align-items: center;
`;

const BottomLeftItem = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  flex-wrap: wrap;
  ${mobile`
    gap: 16px;
    margin-bottom: 0;
  `}
`;

const Label = styled.div`
  font-size: 12px;
  margin-left: 8px;
  opacity: 0.5;
`;

const BottomRightItem = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 14px;
  gap: 24px;

  ${Label} {
    margin-left: 0;
  }

  ${mobile`
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 8px;
  `}
`;

const AmountDueContainer = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
`;

const OrganizationAddress = styled.div<{ $headerColor?: string; $isReadOnly?: boolean }>`
  white-space: pre-line;
  padding: 4px 8px;
  cursor: ${props => props.$isReadOnly ? 'default' : 'pointer'};
  border-radius: 8px;
  margin-left: -8px;
  width: fit-content;
  max-width: 100%;
  &:hover {
    background-color: ${props => !props.$isReadOnly && (props.$headerColor ? getContrastColor(props.$headerColor) : 'rgba(0, 0, 0, 0.05)')};
  }
`;

const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

interface CircularHeaderProps extends Omit<InvoiceTemplatesProps, 'onCreateNewClient'> {
  organization: Organization | null;
  onOpenUpdateOrganizationDrawer: () => void;
  onCreateNewClient: (client?: Client) => void;
  isGeneratingNumber?: boolean;
}

export const CircularHeader: React.FC<CircularHeaderProps> = ({
  invoice,
  updateInvoice,
  isReadOnly = false,
  clients,
  ClientSelectorComponent = ClientSelector,
  onCreateNewClient,
  organization,
  showLogo,
  onOpenUpdateOrganizationDrawer,
  isGeneratingNumber = false,
}) => {
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState(invoice.invoice_number || '');
  const [inputWidth, setInputWidth] = useState('auto');
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setLocalInvoiceNumber(invoice.invoice_number || '');
    updateInputWidth();
  }, [invoice.invoice_number]);

  useEffect(() => {
    updateInputWidth();
  }, [localInvoiceNumber]);

  const updateInputWidth = () => {
    if (hiddenSpanRef.current) {
      const width = hiddenSpanRef.current.offsetWidth;
      setInputWidth(`${width + 8}px`); // Add 8px for padding
    }
  };

  const handleInvoiceDateChange = (date: Date | null) => {
    updateInvoice('invoice_date', date ? date.toISOString() : '');
    if (date && invoice.due_days != null) {
      const newDueDate = addDays(date, invoice.due_days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDaysChange = (days: number) => {
    updateInvoice('due_days', days);
    if (invoice.invoice_date) {
      const newDueDate = addDays(new Date(invoice.invoice_date), days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDateChange = (date: Date | null) => {
    if (date && invoice.invoice_date) {
      const invoiceDate = new Date(invoice.invoice_date);
      const diffDays = Math.round((date.getTime() - invoiceDate.getTime()) / (1000 * 60 * 60 * 24));
      handleDueDaysChange(diffDays);
      updateInvoice('due_date', date.toISOString());
    }
  };

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalInvoiceNumber(newValue);
    updateInvoice('invoice_number', newValue);
  };

  const totals = calculateInvoiceTotals(invoice as InvoiceData);

  const getFormattedOrganizationAddress = (org: Organization) => {
    const addressParts = [
      org.business_name || org.name,
      org.address_line1,
      org.address_line2,
      [org.city, org.state, org.postal_code].filter(Boolean).join(', '),
      org.country
    ].filter(Boolean);
    return addressParts.join('\n');
  };

  const handleClientSelection = (client: Client | undefined) => {
    updateInvoice('client_id', client?.id || undefined);
  };

  return (
    <FontWrapper $font={invoice.font || 'Inter'}>
      <HeaderContainer $headerColor={invoice.header_color}>
        <HeaderContent $headerTextColor={invoice.header_text_color}>
          <TopItem>
            {showLogo && invoice.logo_url && <Logo src={invoice.logo_url} alt="Company Logo" />}
          </TopItem>
          <BottomLeftItem>
            <DetailRow>
              <Label>Invoice Date</Label>
              <DatePicker
                id="invoice-date"
                label="Invoice Date"
                selectedDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                onChange={handleInvoiceDateChange}
                disabled={isReadOnly}
                isInvoiceDate={true}
                customFontSize={true}
                backgroundColor={invoice.header_color}
                isPublic={isReadOnly}
                placement="bottom-start"
              />
            </DetailRow>
            <DetailRow>
              <Label>Due Date</Label>
              <DatePicker
                id="due-date"
                label="Due Date"
                selectedDate={invoice.due_date ? new Date(invoice.due_date) : null}
                onChange={handleDueDateChange}
                disabled={isReadOnly}
                onSetDueDate={handleDueDaysChange}
                dueDays={invoice.due_days ?? 0}
                invoiceDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                customFontSize={true}
                backgroundColor={invoice.header_color}
                isPublic={isReadOnly}
                placement="bottom-start"

              />
            </DetailRow>
            <DetailRow>
              <Label>Invoice #</Label>
              <InputWrapper 
                $headerColor={invoice.header_color} 
                $isGenerating={isGeneratingNumber}
              >
                <Input
                  type="text"
                  value={localInvoiceNumber}
                  onChange={handleInvoiceNumberChange}
                  placeholder="Invoice #"
                  readOnly={isReadOnly}
                  width={inputWidth}
                  $headerColor={invoice.header_color}
                  $isReadOnly={isReadOnly}
                />
              </InputWrapper>
              <HiddenSpan ref={hiddenSpanRef}>{localInvoiceNumber || 'Invoice #'}</HiddenSpan>
            </DetailRow>
            <DetailRow>
              <Label>Amount Due</Label>
              <AmountDueContainer>
                <AnimatedAmount 
                  value={totals.amountDue} 
                  currency={invoice.currency || 'USD'}
                />
              </AmountDueContainer>
            </DetailRow>
          </BottomLeftItem>
          <BottomRightItem>
            <DetailColumn> 
              <Label>Client</Label>            
              <ClientSelectorComponent
                clients={clients}
                selectedClientId={invoice.client_id || null}
                onSelectClient={handleClientSelection}
                disabled={isReadOnly}
                onCreateNewClient={onCreateNewClient}
                headerTextColor={invoice.header_text_color}
                backgroundColor={invoice.header_color}
              />
            </DetailColumn>
            <DetailColumn>
              <Label>Organization</Label>
              {organization && (
                <OrganizationAddress 
                  onClick={!isReadOnly ? onOpenUpdateOrganizationDrawer : undefined}
                  $headerColor={invoice.header_color}
                  $isReadOnly={isReadOnly}
                >
                  {getFormattedOrganizationAddress(organization)}
                </OrganizationAddress>
              )}
            </DetailColumn>
          </BottomRightItem>
        </HeaderContent>
      </HeaderContainer>
    </FontWrapper>
  );
};