import { z } from 'zod';

const dateSchema = z.string().refine(
  (date) => {
    if (!date) return true;
    const parsed = new Date(date);
    return !isNaN(parsed.getTime());
  },
  {
    message: "Invalid date format"
  }
);

export const taskStatusSchema = z.enum([
  'todo',
  'in_progress',
  'completed',
  'on_hold',
  'cancelled'
]);

export const taskPrioritySchema = z.enum([
  'low',
  'medium',
  'high',
  'urgent'
]);

export const taskSchema = z.object({
  id: z.string().optional(),
  organization_id: z.string().min(1, 'Organization is required'),
  user_id: z.string().min(1, 'User is required'),
  description: z.string().optional().default(''),
  status: taskStatusSchema.optional().default('todo'),
  priority: taskPrioritySchema.optional().default('medium'),
  due_date: dateSchema.nullable().optional(),
  client_id: z.string().nullable().optional(),
  project_id: z.string().nullable().optional(),
  invoice_id: z.string().nullable().optional(),
  created_at: dateSchema.nullable().optional(),
  updated_at: dateSchema.nullable().optional(),
  completed_at: dateSchema.nullable().optional(),
  assigned_to: z.string().nullable().optional(),
  is_active: z.boolean().optional().default(true),
  status_id: z.string().optional(),
  position: z.number().optional(),
  notes: z.string().optional().default(''),
}).refine(
  (data) => {
    if (data.due_date) {
      const dueDate = new Date(data.due_date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return dueDate >= today;
    }
    return true;
  },
  {
    message: "Due date cannot be in the past",
    path: ["due_date"]
  }
).refine(
  (data) => {
    if (data.status === 'completed' && !data.completed_at) {
      return false;
    }
    return true;
  },
  {
    message: "Completed tasks must have a completion date",
    path: ["completed_at"]
  }
); 