import React from 'react';
import { useSubscription } from './hooks/useSubscription';
import { toast } from 'sonner';
import { useOrganization } from './hooks/useOrganization';
import styled from 'styled-components';
import AnimatedNumber from './components/AnimatedNumber';
import Button from './components/Button';
import PlanCard from './components/PlanCard';

type BillingInterval = 'month' | 'year';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  max-width: 400px;
  margin: 0 auto;
`;

const EarlyMemberLabel = styled.div`
  color: #EF4611;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
`;

const PageTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin: 0 0 24px 0;
  color: #111827;
  text-align: center;
`;

const TrialStatus = styled.div`
  background: rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 8px 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  box-sizing: border-box;
  user-select: none;
`;

const TrialText = styled.span<{ $isExpired?: boolean; $isPaid?: boolean }>`
  color: ${props => props.$isExpired ? '#EF4611' : props.$isPaid ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.5)'};
  font-size: 14px;
  font-weight: 500;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;

  > button {
    width: 100%;
    justify-content: center;
    padding: 8px 16px;
  }
`;

const SubscriptionPage: React.FC = () => {
    const { data: organizationId } = useOrganization();
    const [selectedInterval, setSelectedInterval] = React.useState<BillingInterval>('month');
    
    const {
        subscription,
        error,
        trialDaysRemaining,
        createCheckoutSession,
        createPortalSession,
        isActive,
        isPaidSubscription,
    } = useSubscription(organizationId || undefined);

    const monthlyPrice = 12;
    const yearlyPrice = 120;
    const price = selectedInterval === 'month' ? monthlyPrice : yearlyPrice / 12;
    const yearlyDiscount = Math.round(((monthlyPrice * 12 - yearlyPrice) / (monthlyPrice * 12)) * 100);

    const handleSubscribe = async () => {
        try {
            if (isPaidSubscription) {
                await createPortalSession.mutateAsync();
            } else {
                await createCheckoutSession.mutateAsync(selectedInterval);
            }
        } catch (error) {
            toast.error(isPaidSubscription ? 'Failed to open subscription management' : 'Failed to start subscription process');
        }
    };

    if (!organizationId || !subscription) {
        return null;
    }

    if (error) {
        return <div>Error loading subscription. Please try refreshing the page.</div>;
    }

    return (
        <PageWrapper>
            <EarlyMemberLabel>Early Member</EarlyMemberLabel>
            <PageTitle>Subscription</PageTitle>
            
            <TrialStatus>
                {isActive ? (
                    <>
                        <TrialText $isPaid={isPaidSubscription}>
                            {isPaidSubscription ? 'Pro Trial' : 'Current on Free Trial'}
                        </TrialText>
                        <TrialText $isPaid={isPaidSubscription}>
                            {isPaidSubscription ? (
                                <>First charge in <AnimatedNumber value={trialDaysRemaining} /> days</>
                            ) : (
                                <>Ends in <AnimatedNumber value={trialDaysRemaining} /> days</>
                            )}
                        </TrialText>
                    </>
                ) : (
                    <>
                        <TrialText $isExpired>Trial Expired</TrialText>
                        <TrialText $isExpired>Subscribe to Continue Using Due</TrialText>
                    </>
                )}
            </TrialStatus>

            <PlanCard
                title="Pro"
                price={price}
                description="Unlock all of Due for one simple price."
                showDiscount={selectedInterval === 'year'}
                discountPercentage={yearlyDiscount}
                showIntervalToggle={!isPaidSubscription}
                selectedInterval={selectedInterval}
                onIntervalChange={setSelectedInterval}
            >
                <ButtonWrapper>
                    <Button
                        buttonType="primary"
                        onClick={handleSubscribe}
                        disabled={createCheckoutSession.isPending || createPortalSession.isPending}
                    >
                        {createCheckoutSession.isPending || createPortalSession.isPending ? 'Processing...' : 
                            isPaidSubscription ? 'Manage Subscription' : 'Upgrade'}
                    </Button>
                </ButtonWrapper>
            </PlanCard>
        </PageWrapper>
    );
};

export default SubscriptionPage; 