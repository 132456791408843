import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { InvoiceTemplatesProps } from './types';
import { ClientSelector } from '../components/Clients/ClientSelector';
import DatePicker from '../components/DatePicker';
import { addDays } from 'date-fns';
import { calculateInvoiceTotals } from '../utils/invoiceCalculations';
import { InvoiceData } from '../types';
import AnimatedAmount from '../components/AnimatedAmount';
import { Organization, Client } from '../types';
import { getContrastColor } from '../utils/contrast';

const mobile = (inner: TemplateStringsArray) => css`
  @media (max-width: 768px) {
    ${inner}
  }
`;

const FontWrapper = styled.div<{ $font: string }>`
  font-family: ${props => props.$font};
`;

const HeaderContainer = styled.div.attrs<{ $headerColor: string }>(props => ({
  style: {
    backgroundColor: props.$headerColor || '#f8f8f8',
  },
}))`
  padding: 64px 72px 72px 72px;
  border: 2px solid ${props => props.$headerColor || '#f8f8f8'};
  box-shadow: 0 0 0 10px ${props => props.$headerColor || '#f8f8f8'};

  ${mobile`
    padding: 32px;
  `}
`;

const HeaderContent = styled.div<{ $headerTextColor: string }>`
  color: ${props => props.$headerTextColor || 'inherit'};
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
`;

const Input = styled.input<{ $headerColor?: string; $isReadOnly?: boolean }>`
  border: none;
  font-family: inherit;
  background: transparent;
  outline: none;
  padding: 4px 8px;
  margin: 0;
  color: inherit;
  width: ${props => props.width || 'auto'};
  min-width: 80px;
  border-radius: 8px;
  font-size: 24px;
  &::placeholder {
    color: inherit;
  }
  &:hover {
    background-color: ${props => !props.$isReadOnly && (props.$headerColor ? getContrastColor(props.$headerColor) : 'rgba(0, 0, 0, 0.05)')};
  }

  ${mobile`
    font-size: 16px;
  `}
`;

const InputWrapper = styled.div<{ $headerColor: string; $isGenerating?: boolean }>`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  display: inline-block;
  
  ${props => {
    return props.$isGenerating && `
      //background: rgba(0, 0, 0, 0.05);
      
      &::after {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 1;
        background: linear-gradient(
          90deg,
          transparent 0%,
          ${props.$headerColor} 50%,
          transparent 100%
        );
        animation: shimmer 1.5s infinite linear;
        pointer-events: none;
      }

      @keyframes shimmer {
        from {
          transform: translateX(-200%);
        }
        to {
          transform: translateX(200%);
        }
      }
    `
  }}
`;

const Logo = styled.img`
  max-width: 96px;
  max-height: 96px;
  object-fit: contain;
  grid-column: 1 / -1;
  margin-bottom: 20px;
`;

const TopLeftItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;

  ${mobile`
    font-size: 16px;
  `}
`;

const TopRightItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 24px;
  font-weight: 500;

  ${mobile`
    font-size: 16px;
  `}
`;

const BottomLeftItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  flex-direction: column;
  align-self: flex-end;
`;

const BottomRightItem = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 14px;
  gap: 16px;
  flex-direction: column;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 500;
  opacity: 0.5;
  margin-bottom: 4px;
`;

const ItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const DatePickerWrapper = styled.div`
  margin-right: -8px;
`;

const OrganizationAddress = styled.div<{ $headerColor?: string; $isReadOnly?: boolean }>`
  font-size: 24px;
  text-align: center;
  white-space: pre-line;
  cursor: ${props => props.$isReadOnly ? 'default' : 'pointer'};
  padding: 4px 8px;
  border-radius: 8px;
  &:hover {
    background-color: ${props => !props.$isReadOnly && (props.$headerColor ? getContrastColor(props.$headerColor) : 'rgba(0, 0, 0, 0.05)')};
  }

  ${mobile`
    font-size: 16px;
  `}
`;

const HiddenSpan = styled.span`
  position: absolute;
  visibility: hidden;
  white-space: pre;
`;

// Update the props interface
interface BoxedHeaderProps extends Omit<InvoiceTemplatesProps, 'onCreateNewClient'> {
  organization: Organization | null;
  onOpenUpdateOrganizationDrawer: () => void;
  onCreateNewClient: (client?: Client) => void;
  isGeneratingNumber?: boolean;
}

export const BoxedHeader: React.FC<BoxedHeaderProps> = ({
  invoice,
  updateInvoice,
  isReadOnly = false,
  clients,
  ClientSelectorComponent = ClientSelector,
  onCreateNewClient,
  organization,
  showLogo,
  onOpenUpdateOrganizationDrawer,
  isGeneratingNumber = false,
}) => {
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState(invoice.invoice_number || '');
  const [inputWidth, setInputWidth] = useState('auto');
  const hiddenSpanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setLocalInvoiceNumber(invoice.invoice_number || '');
    updateInputWidth();
  }, [invoice.invoice_number]);

  useEffect(() => {
    updateInputWidth();
  }, [localInvoiceNumber]);

  const updateInputWidth = () => {
    if (hiddenSpanRef.current) {
      const width = hiddenSpanRef.current.offsetWidth;
      setInputWidth(`${width + 8}px`); // Add 16px for padding
    }
  };

  const handleInvoiceDateChange = (date: Date | null) => {
    updateInvoice('invoice_date', date ? date.toISOString() : '');
    if (date && invoice.due_days != null) {
      const newDueDate = addDays(date, invoice.due_days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDaysChange = (days: number) => {
    updateInvoice('due_days', days);
    if (invoice.invoice_date) {
      const newDueDate = addDays(new Date(invoice.invoice_date), days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDateChange = (date: Date | null) => {
    if (date && invoice.invoice_date) {
      const invoiceDate = new Date(invoice.invoice_date);
      const diffDays = Math.round((date.getTime() - invoiceDate.getTime()) / (1000 * 60 * 60 * 24));
      handleDueDaysChange(diffDays);
      updateInvoice('due_date', date.toISOString());
    }
  };

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalInvoiceNumber(newValue);
    updateInvoice('invoice_number', newValue);
  };

  const totals = calculateInvoiceTotals(invoice as InvoiceData);

  const getFormattedOrganizationAddress = (org: Organization) => {
    const addressParts = [
      org.business_name || org.name,
      org.address_line1,
      org.address_line2,
      [org.city, org.state, org.postal_code].filter(Boolean).join(', '),
      org.country
    ].filter(Boolean);
    return addressParts.join('\n');
  };

  const handleClientSelection = (client: Client | undefined) => {
    updateInvoice('client_id', client?.id || undefined);
  };

  return (
    <FontWrapper $font={invoice.font || 'Inter'}>
      <HeaderContainer $headerColor={invoice.header_color}>
        <HeaderContent $headerTextColor={invoice.header_text_color}>
            <TopLeftItem>
                <InputWrapper 
                  $headerColor={invoice.header_color} 
                  $isGenerating={isGeneratingNumber}
                >
                  <Input
                    type="text"
                    value={localInvoiceNumber}
                    onChange={handleInvoiceNumberChange}
                    placeholder="Invoice #"
                    readOnly={isReadOnly}
                    width={inputWidth}
                    $headerColor={invoice.header_color}
                    $isReadOnly={isReadOnly}
                  />
                </InputWrapper>
                <HiddenSpan ref={hiddenSpanRef}>{localInvoiceNumber || 'Invoice #'}</HiddenSpan>
                </TopLeftItem>
                <TopRightItem>
                    <AnimatedAmount 
                      value={totals.amountDue} 
                      currency={invoice.currency || 'USD'}
                    />
                </TopRightItem>

                <div style={{gridColumn: '1 / -1', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {showLogo && invoice.logo_url && <Logo src={invoice.logo_url} alt="Company Logo" />}
                {organization && (
                  <OrganizationAddress 
                    onClick={!isReadOnly ? onOpenUpdateOrganizationDrawer : undefined}
                    $headerColor={invoice.header_color}
                    $isReadOnly={isReadOnly}
                  >
                    {getFormattedOrganizationAddress(organization)}
                  </OrganizationAddress>
                )}
                </div>

          <BottomLeftItem>
            <ClientSelectorComponent
              clients={clients}
              selectedClientId={invoice.client_id || null}
              onSelectClient={handleClientSelection}
              disabled={isReadOnly}
              onCreateNewClient={onCreateNewClient}
              headerTextColor={invoice.header_text_color}
              backgroundColor={invoice.header_color}
            />
          </BottomLeftItem>
          <BottomRightItem>
            <ItemGroup>
            <Label>Invoice Date</Label>
            <DatePickerWrapper>
              <DatePicker
                id="invoice-date"
                label="Invoice Date"
                selectedDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                onChange={handleInvoiceDateChange}
                disabled={isReadOnly}
                isInvoiceDate={true}
                showYear={false}
                customFontSize={true}
                backgroundColor={invoice.header_color}
                isPublic={isReadOnly}
              />
              </DatePickerWrapper>
            </ItemGroup>
            <ItemGroup>
              <Label>Due Date</Label>
              <DatePickerWrapper>
              <DatePicker
                id="due-date"
                label="Due Date"
                selectedDate={invoice.due_date ? new Date(invoice.due_date) : null}
                onChange={handleDueDateChange}
                disabled={isReadOnly}
                onSetDueDate={handleDueDaysChange}
                dueDays={invoice.due_days ?? 0}
                invoiceDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
                showYear={false}
                customFontSize={true}
                backgroundColor={invoice.header_color}
                isPublic={isReadOnly}
              /></DatePickerWrapper>
            </ItemGroup>
          </BottomRightItem>
        </HeaderContent>
      </HeaderContainer>
    </FontWrapper>
  );
};