import React, { useState, useEffect, useRef } from 'react';
import { supabase } from './supabaseClient';
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useSearch } from "@tanstack/react-router";
import styled from 'styled-components';
import { toast } from 'sonner';

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 32px;
  font-weight: 500;
`;

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
`;

const AuthForm = styled.form`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

`;

const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  background-color: rgba(0,0,0,0.05);
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s;
  box-sizing: border-box;

  &:focus {
    border-color: #0066cc;
  }
`;

const Button = styled.button<{ variant?: 'primary' | 'secondary' | 'google' }>`
  width: 100%;
  padding: 0.75rem;
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  transition: background-color 0.2s;
  background-color: ${props => props.variant === 'google' ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.05)'};
  color: ${props => props.variant === 'google' ? '#fff' : '#000'};

  &:hover {
    background-color: ${props => props.variant === 'google' ? 'rgba(0,0,0,0.9)' : 'rgba(0,0,0,0.1)'};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-family: 'Inter', sans-serif;

  &:hover {
    color: #333;
  }
`;

const VerificationInput = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 24px;
`;

const CodeInput = styled.input`
  width: 40px;
  height: 48px;
  border: 2px solid rgba(0,0,0,0.1);
  border: 0;
  border-radius: 8px;
  font-size: 20px;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  background: white;
  transition: all 0.2s;
  box-shadow: 0 0 0 2px rgba(0,0,0,0.1);

  &:focus {
    border-color: #000;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,0,0,0.5);
  }

  &:disabled {
    background: rgba(0,0,0,0.05);
    cursor: not-allowed;
  }
`;

const TimerCircle = styled.div<{ progress: number }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: conic-gradient(
    #000 ${props => props.progress * 360}deg,
    #eee ${props => props.progress * 360}deg 360deg
  );
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
`;

const SuccessMessage = styled.div`
  color: #16a34a;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
  font-weight: 500;
`;

const ForgotPasswordLink = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 14px;
  padding: 4px 8px;
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Inter', sans-serif;

  &:hover {
    color: #333;
  }
`;

interface SearchParams {
  email?: string;
  organizationId?: string;
  action?: string;
}

const Auth: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [authMode, setAuthMode] = useState<'initial' | 'login' | 'signup'>('initial');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const search = useSearch({ from: '__root__' }) as SearchParams;
  const [verificationInputs, setVerificationInputs] = useState(['', '', '', '', '', '']);
  const [verifyingCode, setVerifyingCode] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [resetPasswordSent, setResetPasswordSent] = useState(false);

  useEffect(() => {
    // Check if there's an invitation in the URL
    if (search.email && search.organizationId && search.action === 'accept-invite') {
      localStorage.setItem('pendingInvitation', JSON.stringify({ 
        email: search.email, 
        organizationId: search.organizationId 
      }));
      setEmail(search.email);
    }
  }, [search]);

  const handleSignInSuccess = async () => {
    queryClient.clear(); // Clear cache on successful sign-in
    
    const { data: { user }, error } = await supabase.auth.getUser();
    
    if (error) {
      console.error('Error fetching user data:', error);
      return;
    }

    if (!user) {
      console.error('No user data found');
      return;
    }

    // Check if email is verified
    if (!user.email_confirmed_at) {
      console.log('Email not verified, showing verification screen');
      setVerificationSent(true);
      // Send verification code
      const { error: otpError } = await supabase.auth.signInWithOtp({
        email: user.email || '',
        options: {
          shouldCreateUser: false
        }
      });
      if (otpError) {
        console.error('Error sending verification code:', otpError);
      }
      return;
    }

    const pendingInvitation = localStorage.getItem('pendingInvitation');
    if (pendingInvitation) {
      const { email, organizationId } = JSON.parse(pendingInvitation);
      navigate({ 
        to: '/accept-invite', 
        search: { email, organizationId } 
      });
      localStorage.removeItem('pendingInvitation');
    } else if (user?.user_metadata?.current_organization_id) {
      navigate({ to: '/' });
    } else {
      navigate({ to: '/create-organization' });
    }
  };

  // Add session listener
  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth state changed:', event, session?.user?.email_confirmed_at);
      
      if (event === 'SIGNED_IN') {
        const user = session?.user;
        if (!user) {
          console.log('No user data in session');
          return;
        }

        console.log('User state:', {
          email: user.email,
          email_confirmed_at: user.email_confirmed_at,
          confirmation_sent_at: user.confirmation_sent_at
        });

        if (!user.email_confirmed_at) {
          console.log('Email not verified, showing verification screen');
          setVerificationSent(true);
          setAuthMode('signup');
          // Send verification code if not already sent
          if (!user.confirmation_sent_at) {
            console.log('Sending verification code...');
            const { error: otpError } = await supabase.auth.signInWithOtp({
              email: user.email || '',
              options: {
                shouldCreateUser: false
              }
            });
            if (otpError) {
              console.error('Error sending verification code:', otpError);
              toast.error('Failed to send verification code. Please try again.');
            }
          }
          return;
        }

        // Only proceed with sign in success if email is verified
        console.log('Email verified, proceeding with sign in');
        await handleSignInSuccess();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleEmailSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const { data: signInData, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      if (error.message.includes('Email not confirmed')) {
        toast.error('Please check your email and confirm your account before signing in.');
        // Send verification code again
        const { error: otpError } = await supabase.auth.signInWithOtp({
          email,
          options: {
            shouldCreateUser: false
          }
        });
        if (!otpError) {
          setVerificationSent(true);
          setVerificationInputs(['', '', '', '', '', '']);
        }
      } else {
        toast.error(error.message);
      }
    } else if (!signInData.user?.email_confirmed_at) {
      console.log('Email not verified, showing verification screen');
      setVerificationSent(true);
      setVerificationInputs(['', '', '', '', '', '']);
      // Send verification code
      const { error: otpError } = await supabase.auth.signInWithOtp({
        email,
        options: {
          shouldCreateUser: false
        }
      });
      if (otpError) {
        console.error('Error sending verification code:', otpError);
      }
    } else {
      const pendingInvitation = localStorage.getItem('pendingInvitation');
      if (pendingInvitation) {
        const { email, organizationId } = JSON.parse(pendingInvitation);
        navigate({ 
          to: '/accept-invite', 
          search: { email, organizationId } 
        });
        localStorage.removeItem('pendingInvitation');
      } else if (signInData.user?.user_metadata?.current_organization_id) {
        navigate({ to: '/' });
      } else {
        navigate({ to: '/create-organization' });
      }
    }
    setLoading(false);
  };

  const handleEmailSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    console.log('Starting signup process for email:', email);
    
    try {
      // Create user first
      console.log('Creating user account...');
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: `${window.location.origin}/auth/callback`,
          data: {
            email_confirmed: false
          }
        }
      });

      console.log('Signup response:', {
        user: data?.user ? {
          id: data.user.id,
          email: data.user.email,
          email_confirmed_at: data.user.email_confirmed_at,
          confirmation_sent_at: data.user.confirmation_sent_at,
        } : null,
        session: data?.session ? 'Session exists' : 'No session',
        error: error ? {
          message: error.message,
          status: error.status,
          name: error.name
        } : null
      });

      if (error) {
        console.error('Error during signup:', error);
        if (error.message.includes('already')) {
          toast.error('An account with this email already exists. Please sign in instead.');
        } else {
          toast.error('Failed to start signup process. Please try again.');
        }
        return;
      } 
      
      if (!data.user) {
        console.error('No user data returned from signup');
        toast.error('Failed to create account. Please try again.');
        return;
      }

      // Show verification screen
      console.log('Showing verification screen');
      toast.success('Please check your email for your verification code.');
      setVerificationSent(true);
      setVerificationInputs(['', '', '', '', '', '']);
      
      // Focus first input
      setTimeout(() => {
        inputRefs.current[0]?.focus();
      }, 100);

    } catch (err) {
      console.error('Unexpected signup error:', err);
      toast.error('An unexpected error occurred during signup.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}`,
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        }
      }
    });
    if (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleResendVerification = async () => {
    if (resendCooldown > 0) return;
    
    setLoading(true);
    const { error: otpError } = await supabase.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: false,
      }
    });
    
    if (otpError) {
      console.error('Error resending verification code:', otpError);
      toast.error('Failed to resend verification code');
    } else {
      toast.success('Verification code resent successfully');
      setResendCooldown(60); // Start 60 second cooldown
      const timer = setInterval(() => {
        setResendCooldown((current) => {
          if (current <= 1) {
            clearInterval(timer);
            return 0;
          }
          return current - 1;
        });
      }, 1000);
    }
    setLoading(false);
  };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      toast.error('Please enter your email address');
      return;
    }
    
    setLoading(true);
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/auth/callback`,
    });
    
    if (error) {
      toast.error(error.message);
    } else {
      setResetPasswordSent(true);
      setVerificationInputs(['', '', '', '', '', '']);
      toast.success('Password reset instructions sent to your email');
    }
    setLoading(false);
  };

  const handleSetNewPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const { error } = await supabase.auth.updateUser({
      password: newPassword
    });

    if (error) {
      toast.error(error.message);
    } else {
      toast.success('Password updated successfully');
      // Sign in with new password
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password: newPassword
      });
      
      if (signInError) {
        toast.error(signInError.message);
      } else {
        await handleSignInSuccess();
      }
    }
    setLoading(false);
  };

  const handleVerifyCode = async (code: string) => {
    setVerifyingCode(true);
    console.log('Starting verification process with code:', code);
    
    try {
      // Verify the OTP
      console.log('Attempting to verify OTP with code:', code);
      const { data, error } = await supabase.auth.verifyOtp({
        email,
        token: code,
        type: 'email'
      });

      console.log('Verification response:', {
        data: data ? 'Verification data exists' : 'No verification data',
        error: error ? {
          message: error.message,
          status: error.status,
          name: error.name
        } : null
      });

      if (error) {
        console.error('Verification error:', error);
        toast.error(error.message);
        setVerificationInputs(['', '', '', '', '', '']);
        inputRefs.current[0]?.focus();
        return;
      }

      // Successfully verified, now sign in with password
      console.log('Successfully verified, signing in with password');
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (signInError) {
        console.error('Sign in error after verification:', signInError);
        toast.error('Verification successful but sign in failed. Please try signing in manually.');
        return;
      }

      // Successfully verified and signed in
      console.log('Successfully verified and signed in');
      setVerificationSuccess(true);
      toast.success('Email verified successfully!');

      // Wait a moment before redirecting
      setTimeout(async () => {
        const pendingInvitation = localStorage.getItem('pendingInvitation');
        if (pendingInvitation) {
          const { email, organizationId } = JSON.parse(pendingInvitation);
          console.log('Redirecting to accept invite page with:', { email, organizationId });
          navigate({ 
            to: '/accept-invite', 
            search: { email, organizationId } 
          });
          localStorage.removeItem('pendingInvitation');
        } else {
          console.log('Redirecting to create organization page');
          navigate({ to: '/create-organization' });
        }
      }, 1000);
    } catch (err) {
      console.error('Unexpected verification error:', err);
      toast.error('An unexpected error occurred during verification.');
    } finally {
      setVerifyingCode(false);
    }
  };

  const handleCodeChange = (index: number, value: string) => {
    if (verifyingCode) return;

    // Handle pasting full code
    if (value.length > 1) {
      const pastedCode = value.replace(/\D/g, '').slice(0, 6).split('');
      const newInputs = [...verificationInputs];
      pastedCode.forEach((char, i) => {
        if (i < 6) newInputs[i] = char;
      });
      setVerificationInputs(newInputs);
      if (pastedCode.length === 6) {
        handleVerifyCode(newInputs.join(''));
      }
      return;
    }

    // Handle single digit input
    const newValue = value.replace(/\D/g, '');
    const newInputs = [...verificationInputs];
    newInputs[index] = newValue;
    setVerificationInputs(newInputs);

    // Auto-advance to next input
    if (newValue && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }

    // If all digits are filled, verify the code
    if (newInputs.every(input => input) && newInputs.join('').length === 6) {
      handleVerifyCode(newInputs.join(''));
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !verificationInputs[index] && index > 0) {
      // Move to previous input on backspace if current input is empty
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <AuthContainer>
      <Title>
        {authMode === 'initial' && 'Welcome to Due'}
        {authMode === 'login' && 'Welcome Back'}
        {authMode === 'signup' && 'Create Account'}
      </Title>
      {resetPasswordSent ? (
        <div style={{ textAlign: 'center', maxWidth: '400px' }}>
          <h2>Reset your password</h2>
          <p>We've sent a verification code to <strong>{email}</strong></p>
          <p>Enter the 6-digit code to reset your password.</p>
          
          {verificationSuccess ? (
            isResettingPassword ? (
              <form onSubmit={handleSetNewPassword}>
                <Input
                  type="password"
                  placeholder="New password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                  style={{ marginBottom: '16px' }}
                />
                <Button 
                  type="submit"
                  variant="google"
                  disabled={loading || !newPassword}
                >
                  Set New Password
                </Button>
              </form>
            ) : (
              <SuccessMessage>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M16.7 5.3c.4.4.4 1 0 1.4l-8 8c-.4.4-1 .4-1.4 0l-4-4c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L8 12.6l7.3-7.3c.4-.4 1-.4 1.4 0z" fill="currentColor"/>
                </svg>
                Code verified
              </SuccessMessage>
            )
          ) : (
            <VerificationInput>
              {verificationInputs.map((value, index) => (
                <CodeInput
                  key={index}
                  ref={el => inputRefs.current[index] = el}
                  type="text"
                  inputMode="numeric"
                  autoComplete={index === 0 ? "one-time-code" : "off"}
                  value={value}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onPaste={(e) => {
                    e.preventDefault();
                    handleCodeChange(index, e.clipboardData.getData('text'));
                  }}
                  disabled={verifyingCode}
                  maxLength={6}
                  required
                />
              ))}
            </VerificationInput>
          )}

          {!verificationSuccess && (
            <>
              <p style={{ fontSize: '0.9em', color: '#666', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {resendCooldown > 0 && (
                  <TimerCircle progress={1 - (resendCooldown / 60)} />
                )}
                {resendCooldown > 0 ? `Resend code in ${resendCooldown}s` : "Didn't receive the code?"}
              </p>
              
              {resendCooldown === 0 && (
                <Button 
                  type="button"
                  onClick={handleResendVerification}
                  disabled={loading || verifyingCode}
                  style={{ marginTop: '10px' }}
                >
                  Resend code
                </Button>
              )}
              
              <Button 
                type="button"
                onClick={() => {
                  setResetPasswordSent(false);
                  setVerificationInputs(['', '', '', '', '', '']);
                  setVerifyingCode(false);
                  setVerificationSuccess(false);
                  setIsResettingPassword(false);
                }}
                disabled={loading || verifyingCode}
                style={{ marginTop: '20px' }}
              >
                Back to Sign In
              </Button>
            </>
          )}
        </div>
      ) : verificationSent ? (
        <div style={{ textAlign: 'center', maxWidth: '400px' }}>
          <h2>Verify your email</h2>
          <p>We've sent a verification code to <strong>{email}</strong></p>
          <p>Enter the 6-digit code to verify your account.</p>
          
          {verificationSuccess ? (
            <SuccessMessage>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M16.7 5.3c.4.4.4 1 0 1.4l-8 8c-.4.4-1 .4-1.4 0l-4-4c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L8 12.6l7.3-7.3c.4-.4 1-.4 1.4 0z" fill="currentColor"/>
              </svg>
              Verification successful
            </SuccessMessage>
          ) : (
            <VerificationInput>
              {verificationInputs.map((value, index) => (
                <CodeInput
                  key={index}
                  ref={el => inputRefs.current[index] = el}
                  type="text"
                  inputMode="numeric"
                  autoComplete={index === 0 ? "one-time-code" : "off"}
                  value={value}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onPaste={(e) => {
                    e.preventDefault();
                    handleCodeChange(index, e.clipboardData.getData('text'));
                  }}
                  disabled={verifyingCode}
                  maxLength={6}
                  required
                />
              ))}
            </VerificationInput>
          )}

          {!verificationSuccess && (
            <>
              <p style={{ fontSize: '0.9em', color: '#666', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {resendCooldown > 0 && (
                  <TimerCircle progress={1 - (resendCooldown / 60)} />
                )}
                {resendCooldown > 0 ? `Resend code in ${resendCooldown}s` : "Didn't receive the code?"}
              </p>
              
              {resendCooldown === 0 && (
                <Button 
                  type="button"
                  onClick={handleResendVerification}
                  disabled={loading || verifyingCode}
                  style={{ marginTop: '10px' }}
                >
                  Resend verification code
                </Button>
              )}
              
              <Button 
                type="button"
                onClick={() => {
                  setVerificationSent(false);
                  setVerificationInputs(['', '', '', '', '', '']);
                  setVerifyingCode(false);
                  setVerificationSuccess(false);
                }}
                disabled={loading || verifyingCode}
                style={{ marginTop: '20px' }}
              >
                Back to Sign In
              </Button>
            </>
          )}
        </div>
      ) : (
        <AuthForm onSubmit={authMode === 'login' ? handleEmailSignIn : handleEmailSignUp}>
          {authMode !== 'initial' ? (
            <>
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="email"
              />
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete={authMode === 'login' ? 'current-password' : 'new-password'}
              />
              <Button type="submit" variant="google" disabled={loading}>
                {authMode === 'login' 
                  ? 'Sign In' 
                  : loading ? 'Creating Account...' : 'Create Account'}
              </Button>
              
              {authMode === 'login' && (
                <ForgotPasswordLink
                  type="button"
                  onClick={handleForgotPassword}
                  disabled={loading}
                >
                  Forgot password?
                </ForgotPasswordLink>
              )}

              <div style={{ textAlign: 'center', marginTop: '16px', color: '#666' }}>
                {authMode === 'login' ? (
                  <>
                    Don't have an account?{' '}
                    <ForgotPasswordLink type="button" onClick={() => setAuthMode('signup')}>
                      Sign up
                    </ForgotPasswordLink>
                  </>
                ) : (
                  <>
                    Already have an account?{' '}
                    <ForgotPasswordLink type="button" onClick={() => setAuthMode('login')}>
                      Sign in
                    </ForgotPasswordLink>
                  </>
                )}
              </div>

              <BackButton 
                type="button" 
                onClick={() => setAuthMode('initial')}
              >
                ← Back to all options
              </BackButton>
            </>
          ) : (
            <>
              <Button 
                type="button"
                variant="google"
                onClick={handleGoogleSignIn}
                disabled={loading}
              >
                Continue with Google
              </Button>
              <ButtonGroup>
                <Button 
                  type="button"
                  onClick={() => setAuthMode('login')}
                  disabled={loading}
                >
                  Sign in with email
                </Button>
                <Button 
                  type="button"
                  onClick={() => setAuthMode('signup')}
                  disabled={loading}
                >
                  Create account
                </Button>
              </ButtonGroup>
            </>
          )}
        </AuthForm>
      )}
    </AuthContainer>
  );
};

export default Auth;