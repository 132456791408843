import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { invoiceTemplates } from "./templates/InvoiceTemplates";
import SidebarRight from "./components/SidebarRight";
import PaymentsDrawer from "./components/PaymentsDrawer";
import NewClientDrawer from "./components/Clients/AddClientDrawer";
import { Client, InvoiceData, Payment, Organization } from "./types";
import type { InvoiceItem, Service } from "./types";
import { useQueryClient } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { useInvoiceMutations } from "./hooks/useInvoiceMutations";
import { useServices } from "./hooks/useServices";
import InvoiceTotals from "./components/Invoices/InvoiceTotals";
import InvoiceItems from "./components/Invoices/InvoiceItems";
import { supabase } from "./supabaseClient";
import { useNavigate, useParams } from '@tanstack/react-router';
import NotesEditor from "./components/NotesEditor";
import InvoiceMoreMenu from "./components/Invoices/InvoiceMoreMenu";
import { RightSidebar16 } from "./components/Icon";
import AnimatedHeader from "./components/AnimatedHeader";
import Button from "./components/Button";
import { useProjects } from "./hooks/useProjects";
import { useOrganization } from "./hooks/useOrganization";
import { invoiceRoute } from './router';
import SendInvoiceDrawer from './components/Invoices/SendInvoiceDrawer';
import { ClientSelector } from "./components/Clients/ClientSelector";
import AddServiceDrawer from './components/Services/AddServiceDrawer';
import InvoicePageSkeleton from './components/Invoices/InvoicePageSkeleton';
import { isAfter, isSameDay, startOfDay } from 'date-fns';
import { StatusBadge } from './components/StatusBadge';
import { defaultSettings } from './utils/invoiceUtils';
import { usePageContext } from './hooks/usePageContext';
import { useEmailTrackingData } from './hooks/useEmailTrackingData';
import { calculateInvoiceTotals } from './utils/invoiceCalculations';
import { debounce } from 'lodash';
import chroma from "chroma-js";
import UpdateOrganizationDrawer from './components/Organizations/UpdateOrganizationDrawer';
import { ClientSelectorProps } from './components/Clients/ClientSelector';
import { useInvoice, useClients, useBrandSettings, useOrganizationDetails } from './hooks/useInvoiceHooks';
import InvoiceHistory from './components/Invoices/InvoiceHistory';
import { addDays } from 'date-fns';
import { toast } from 'sonner';
import PaymentTermsEditor from "./components/PaymentTermsEditor";
import { invoiceSchema, invoiceItemSchema } from './schemas/invoice';
import { z } from 'zod';
import { useLogoOperations } from './hooks/useLogoOperations';
import ConfirmationModal from './components/ConfirmationModal';

const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

const MainContent = styled.div<MainContentProps>`
  flex: 1;
  max-width: 880px;
  width: 100%;
  transition: max-width 0.3s ease-in-out;
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.$bodyTextColor};
  font-family: ${(props) => props.$font};
  box-shadow: 
  0 8px 8px rgba(0, 0, 0, 0.05),
  0 24px 32px rgba(0, 0, 0, 0.05),
  0 40px 64px rgba(0, 0, 0, 0.05),
  0 64px 80px rgba(0, 0, 0, 0.05);
`;

const ToggleButton = styled.button`
  width: 28px;
  height: 28px;
  z-index: 1;
  border: 0;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const InvoiceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
`;

const InvoiceDetailsContainer = styled.div`
  padding: 64px;
`;

const TwoColumnContainer = styled.div`
  display: flex;
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  flex: 1;
`;

const InvoiceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px 120px;
  box-sizing: border-box;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const NavInvoiceTitle = styled.div`
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
`;

interface MainContentProps {
  $isSidebarVisible: boolean;
  $backgroundColor: string;
  $bodyTextColor: string;
  $font: string; // Add this line
}

const InvoicePage: React.FC = () => {
  const { data: organizationId } = useOrganization();
  const { data: organization } = useOrganizationDetails(organizationId);
  const { data: brandSettings } = useBrandSettings();
  const { data: clients } = useClients();
  const { data: services } = useServices();
  const { data: projects } = useProjects();
  const { id } = useParams({ from: invoiceRoute.id });
  const { data: emailTrackingData } = useEmailTrackingData(id);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setPageHeaderProps } = usePageContext();

  // Prefetch related data
  useEffect(() => {
    if (organizationId) {
      // Prefetch clients
      queryClient.prefetchQuery({
        queryKey: ['clients', organizationId],
        queryFn: async () => {
          const { data } = await supabase
            .from('clients')
            .select('*')
            .eq('organization_id', organizationId)
            .order('full_name', { ascending: true });
          return data;
        },
        staleTime: 5 * 60 * 1000,
      });

      // Prefetch services
      queryClient.prefetchQuery({
        queryKey: ['services', organizationId],
        queryFn: async () => {
          const { data } = await supabase
            .from('services')
            .select('*')
            .eq('organization_id', organizationId)
            .eq('is_deleted', false)
            .order('created_at', { ascending: false });
          return data;
        },
        staleTime: 5 * 60 * 1000,
      });

      // Prefetch projects
      queryClient.prefetchQuery({
        queryKey: ['projects', organizationId],
        queryFn: async () => {
          const { data } = await supabase
            .from('projects')
            .select('*')
            .eq('organization_id', organizationId);
          return data;
        },
        staleTime: 5 * 60 * 1000,
      });
    }
  }, [organizationId, queryClient]);

  // Get initial data from the query cache with type assertion
  const initialData = queryClient.getQueryData(['invoice', id, organizationId]) as InvoiceData | undefined;

  const { data: invoice, isLoading: isLoadingInvoice, isError: isErrorInvoice } = useInvoice(id, {
    initialData,
    organization
  });

  // Add effect to check organization access and redirect if needed
  useEffect(() => {
    if (!isLoadingInvoice && !organizationId) {
      console.log('No organization ID found');
      navigate({ to: '/' });
      return;
    }

    if (isErrorInvoice) {
      console.log('Error loading invoice');
      navigate({ to: '/' });
      return;
    }

    if (!isLoadingInvoice && invoice && organizationId) {
      if (invoice.organization_id !== organizationId) {
        console.log('User does not have access to this invoice');
        navigate({ to: '/' });
        return;
      }
    }
  }, [invoice, organizationId, isLoadingInvoice, isErrorInvoice, navigate]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNewClientDrawerOpen, setIsNewClientDrawerOpen] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isAddServiceDrawerOpen, setIsAddServiceDrawerOpen] = useState(false);
  const [isSendDrawerOpen, setIsSendDrawerOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [localInvoice, setLocalInvoice] = useState<InvoiceData | null>(() => initialData || null);
  const [localChanges] = useState<Partial<InvoiceData>>({});
  const [deletingItems, setDeletingItems] = useState<Set<string>>(new Set());
  const [collectStripePayments, setCollectStripePayments] = useState(() => {
    const cachedInvoice = queryClient.getQueryData(['invoice', id, organizationId]) as InvoiceData | null;
    return cachedInvoice?.collect_stripe_payments ?? false;
  });

  const [showLogo, setShowLogo] = useState(() => {
    const cachedInvoice = queryClient.getQueryData(['invoice', id, organizationId]) as InvoiceData | null;
    return cachedInvoice?.show_logo ?? true;  // Default to true instead of false
  });

  const [localHeaderColor, setLocalHeaderColor] = useState(() => invoice?.header_color || '');
  const [localHeaderTextColor, setLocalHeaderTextColor] = useState(() => invoice?.header_text_color || '');
  const [localBackgroundColor, setLocalBackgroundColor] = useState(() => invoice?.background_color || '');
  const [localBodyTextColor, setLocalBodyTextColor] = useState(() => invoice?.body_text_color || '');

  // Add state for autoColorText that reads from brandSettings
  const [autoColorText, setAutoColorText] = useState(() => 
    brandSettings?.auto_color_text ?? true
  );

  // Update useEffect to include autoColorText initialization
  useEffect(() => {
    if (invoice) {
      setLocalInvoice(invoice);
      setShowLogo(invoice.show_logo ?? true);
      setCollectStripePayments(invoice.collect_stripe_payments ?? false);
      setLocalHeaderColor(invoice.header_color || brandSettings?.default_header_color || '');
      setLocalHeaderTextColor(invoice.header_text_color || brandSettings?.default_header_text_color || '');
      setLocalBackgroundColor(invoice.background_color || brandSettings?.default_background_color || '');
      setLocalBodyTextColor(invoice.body_text_color || brandSettings?.default_body_text_color || '');
      
      // Initialize payment terms if they're missing
      if (!invoice.payment_terms && brandSettings?.default_payment_terms) {
        console.log('Initializing missing payment terms with default:', brandSettings.default_payment_terms);
        updateInvoice("payment_terms", brandSettings.default_payment_terms);
      }
      
      // Initialize autoColorText from brandSettings
      setAutoColorText(brandSettings?.auto_color_text ?? true);
    }
  }, [invoice, brandSettings]);

  const {
    // Remove updateInvoiceMutation from destructuring since we'll use it directly
    updateInvoiceItemsMutation,
    addInvoiceItemMutation,
    deleteInvoiceItemMutation,
    addPaymentMutation,
    removePaymentMutation,
    deleteInvoiceMutation,
    shareInvoiceMutation,
    reorderInvoiceItemsMutation,
    updateInvoiceItemDescriptionMutation,
    handleServiceSelect,
    handleDownloadPDF,
    handleSendInvoice,
    updateInvoice: updateInvoiceMutation, // Rename to be more clear
  } = useInvoiceMutations(id);

  const debouncedUpdateInvoice = useRef(
    debounce(
      (updates: Partial<InvoiceData>) => { // Remove the unused id parameter
        // Use the mutation to update the database and handle cache updates
        updateInvoiceMutation.mutate(updates);
      },
      500, // Delay updates by 500ms
      { maxWait: 2000 } // Never wait longer than 2 seconds to update
    )
  ).current;

  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateInvoice = (invoiceData: Partial<InvoiceData>) => {
    try {
      invoiceSchema.parse(invoiceData);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          const field = err.path[0];
          if (typeof field === 'string') {
            newErrors[field] = err.message;
          }
        });
        setErrors(newErrors);
      }
      return false;
    }
  };

  const validateInvoiceItem = (itemData: Partial<InvoiceItem>) => {
    try {
      invoiceItemSchema.parse(itemData);
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          const field = err.path[0];
          if (typeof field === 'string') {
            newErrors[field] = err.message;
          }
        });
        setErrors(newErrors);
      }
      return false;
    }
  };

  const updateInvoice = useCallback(
    <K extends keyof InvoiceData>(
      field: K,
      value: InvoiceData[K] extends string | null ? string | null : InvoiceData[K]
    ) => {
      setLocalInvoice((prev) => {
        if (!prev) return prev;
        const updatedInvoice = { ...prev, [field]: value };
        
        // Only validate if we have enough data
        if (updatedInvoice.organization_id && updatedInvoice.client_id) {
          validateInvoice(updatedInvoice);
        }
        
        const newTotals = calculateInvoiceTotals(updatedInvoice);
        return { ...updatedInvoice, ...newTotals };
      });

      if (id) {
        if (field === 'tax_rate') {
          setLocalInvoice((prev) => {
            if (!prev) return prev;
            const updatedInvoice = { ...prev, [field]: value };
            const newTotals = calculateInvoiceTotals(updatedInvoice);
            
            debouncedUpdateInvoice({
              [field]: value,
              subtotal: newTotals.subtotal,
              total: newTotals.total,
              amount_due: newTotals.amountDue
            });
            
            return updatedInvoice;
          });
        } else {
          debouncedUpdateInvoice({ [field]: value });
        }
      }
    },
    [id, debouncedUpdateInvoice]
  );

  const updateItem = useCallback(
    (itemId: string, field: keyof InvoiceItem, value: string | number | boolean) => {
      const sanitizedValue = (field === 'price' || field === 'quantity') && value === '' ? null : value;

      setLocalInvoice((prev) => {
        if (!prev) return prev;
        const updatedItems = prev.items.map(item => {
          if (item.id === itemId) {
            const updatedItem = { ...item, [field]: sanitizedValue };
            validateInvoiceItem(updatedItem);
            return updatedItem;
          }
          return item;
        });
        return { ...prev, items: updatedItems };
      });

      if (sanitizedValue !== null && /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(itemId)) {
        updateInvoiceItemsMutation.mutate([{ id: itemId, [field]: sanitizedValue }]);
      }
    },
    [updateInvoiceItemsMutation]
  );

  const addItem = useCallback(
    (serviceId?: string) => {
      if (!id) return;

      const newItem: Omit<InvoiceItem, "id"> = {
        invoice_id: id,
        description: "",
        quantity: 1,
        price: 0,
        taxable: false,
        order: localInvoice?.items?.length ?? 0,
      };

      if (serviceId) {
        const selectedService = services?.find(service => service.id === serviceId);
        if (selectedService) {
          newItem.description = selectedService.name;
          newItem.price = selectedService.price;
          newItem.taxable = selectedService.taxable;
        }
      }

      if (!validateInvoiceItem(newItem)) {
        return;
      }

      const tempId = `temp-${Date.now()}`;
      setLocalInvoice((prev) => {
        if (!prev) return prev;
        const updatedItems = [...(prev.items || []), { ...newItem, id: tempId, order: prev.items.length }];
        return { ...prev, items: updatedItems };
      });

      addInvoiceItemMutation.mutate(newItem, {
        onSuccess: ({ newItem: addedItem, updatedInvoice }) => {
          setLocalInvoice((prev) => {
            if (!prev) return updatedInvoice;
            const updatedItems = prev.items.map(item => 
              item.id === tempId ? { ...addedItem, order: prev.items.length - 1 } : item
            );
            return {
              ...prev,
              ...updatedInvoice,
              items: updatedItems,
              status: prev.status === 'draft' ? 'draft' : updatedInvoice.status
            };
          });
        },
        onError: () => {
          setLocalInvoice((prev) => {
            if (!prev) return prev;
            const updatedItems = prev.items.filter(item => item.id !== tempId);
            return { ...prev, items: updatedItems };
          });
        }
      });
    },
    [id, localInvoice, services, addInvoiceItemMutation]
  );

  const deleteItem = useCallback(async (itemId: string) => {
    if (!localInvoice || !id) return;

    setDeletingItems((prev) => new Set(prev).add(itemId));

    setLocalInvoice((prev) => {
      if (!prev) return prev;
      const updatedItems = (prev.items || []).filter((item) => item.id !== itemId);
      const reorderedItems = updatedItems.map((item, index) => ({
        ...item,
        order: index,
      }));
      return { ...prev, items: reorderedItems };
    });

    deleteInvoiceItemMutation.mutate(itemId, {
      onSuccess: ({ updatedInvoice }) => {
        setDeletingItems((prev) => {
          const newSet = new Set(prev);
          newSet.delete(itemId);
          return newSet;
        });
        setLocalInvoice((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            ...updatedInvoice,
            items: (prev.items || [])
              .filter((item) => item.id !== itemId)
              .map((item, index) => ({ ...item, order: index })),
          };
        });
      },
      onError: (error) => {
        console.error("Error deleting item:", error);
        setDeletingItems((prev) => {
          const newSet = new Set(prev);
          newSet.delete(itemId);
          return newSet;
        });
        setLocalInvoice((prev) => {
          if (!prev) return prev;
          return queryClient.getQueryData<InvoiceData>(['invoice', id]) || prev;
        });
      },
    });
  }, [deleteInvoiceItemMutation, queryClient, id]);

  const handleServiceSelectWrapper = (service: Service, itemId: string) => {
    handleServiceSelect.mutate({ service, itemId });
  };

  const handleSelectProject = useCallback(
    (projectId: string | null) => {
      setSelectedProjectId(projectId);
      updateInvoice("project_id", projectId || undefined);
    },
    [updateInvoice]
  );

  useEffect(() => {
    if (localInvoice && localInvoice.project_id) {
      setSelectedProjectId(localInvoice.project_id);
    }
  }, [localInvoice]);

  const handleDescriptionChange = (value: string, itemId: string) => {
    setLocalInvoice((prev) => {
      if (!prev) return prev;
      const updatedItems = prev.items.map(item =>
        item.id === itemId ? { ...item, description: value } : item
      );
      return { ...prev, items: updatedItems };
    });
    updateInvoiceItemDescriptionMutation.mutate({ itemId, description: value });
  };

  const handleShareInvoice = useCallback(async (): Promise<string> => {
    const result = await shareInvoiceMutation.mutateAsync();
    if (result && result.public_id) {
      const link = `${window.location.origin}/share/${result.public_id}`;
      return link;
    }
    throw new Error("Failed to generate shareable link");
  }, [shareInvoiceMutation]);

  const handleDuplicateInvoice = useCallback(async () => {
    if (!localInvoice || !organizationId) return;

    try {
      // Create a new invoice number by incrementing the last number
      let newInvoiceNumber = localInvoice.invoice_number || '';
      const numericPart = newInvoiceNumber.match(/\d+$/);
      if (numericPart) {
        const incrementedNumber = parseInt(numericPart[0]) + 1;
        newInvoiceNumber = newInvoiceNumber.replace(/\d+$/, incrementedNumber.toString().padStart(numericPart[0].length, '0'));
      } else {
        newInvoiceNumber = `${newInvoiceNumber}-2`;
      }

      // Create the new invoice with updated fields, excluding the items array
      const { data: newInvoice, error: invoiceError } = await supabase
        .from('invoices')
        .insert([{
          organization_id: localInvoice.organization_id,
          client_id: localInvoice.client_id,
          project_id: localInvoice.project_id,
          invoice_number: newInvoiceNumber,
          status: 'draft',
          invoice_date: new Date().toISOString(),
          due_date: addDays(new Date(), localInvoice.due_days || 30).toISOString(),
          invoice_created_at: new Date().toISOString(),
          due_days: localInvoice.due_days,
          currency: localInvoice.currency,
          tax_rate: localInvoice.tax_rate,
          subtotal: localInvoice.subtotal,
          total: localInvoice.total,
          amount_due: localInvoice.total,
          notes: localInvoice.notes,
          payment_terms: localInvoice.payment_terms,
          invoice_template: localInvoice.invoice_template,
          font: localInvoice.font,
          header_color: localInvoice.header_color,
          header_text_color: localInvoice.header_text_color,
          background_color: localInvoice.background_color,
          body_text_color: localInvoice.body_text_color,
          show_logo: localInvoice.show_logo,
          collect_stripe_payments: localInvoice.collect_stripe_payments,
        }])
        .select()
        .single();

      if (invoiceError) throw invoiceError;

      // Duplicate all invoice items
      if (localInvoice.items?.length) {
        const { error: itemsError } = await supabase
          .from('invoice_items')
          .insert(
            localInvoice.items.map((item, index) => ({
              description: item.description,
              quantity: item.quantity,
              price: item.price,
              taxable: item.taxable,
              invoice_id: newInvoice.id,
              order: index,
            }))
          );

        if (itemsError) throw itemsError;
      }

      // Invalidate the invoices query to refresh the list
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      
      // Navigate to the new invoice
      navigate({ to: '/invoice/$id', params: { id: newInvoice.id } });
      
      // Show success message
      toast.success('Invoice duplicated successfully');

    } catch (error) {
      console.error('Error duplicating invoice:', error);
      toast.error('Failed to duplicate invoice');
    }
  }, [localInvoice, organizationId, navigate, queryClient]);

  const addPayment = useCallback(
    (payment: Omit<Payment, "id">) => {
      if (!organizationId) {
        console.error("Organization ID is missing");
        return;
      }
      if (!localInvoice) {
        console.error("Invoice is missing");
        return;
      }
      addPaymentMutation.mutate(
        { ...payment, organization_id: organizationId, currency: localInvoice.currency },
        {
          onSuccess: ({ paymentData, updatedInvoice }) => {
            setLocalInvoice((prev) => {
              if (!prev) return null;
              return {
                ...prev,
                payments: Array.isArray(prev.payments) ? [...prev.payments, paymentData] : [paymentData],
                amount_due: updatedInvoice.amount_due,
                subtotal: updatedInvoice.subtotal,
                status: updatedInvoice.status,
              };
            });
          },
        }
      );
    },
    [addPaymentMutation, organizationId, localInvoice]
  );

  const removePayment = useCallback(async (paymentId: string) => {
    // Optimistically update the UI
    setLocalInvoice((prev) => {
      if (!prev) return prev;
      const updatedPayments = prev.payments.filter(p => p.id !== paymentId);
      const totals = calculateInvoiceTotals({
        ...prev,
        payments: updatedPayments
      });
      
      // Calculate new status using the same logic
      let newStatus = prev.status;
      if (prev.status !== 'draft') {
        if (totals.amountDue === 0 && updatedPayments.length > 0) {
          newStatus = 'paid';
        } else if (prev.due_date) {
          const dueDate = startOfDay(new Date(prev.due_date));
          const today = startOfDay(new Date());
          if (isAfter(today, dueDate) && !isSameDay(today, dueDate)) {
            newStatus = 'overdue';
          } else {
            newStatus = 'unpaid';
          }
        } else {
          newStatus = 'unpaid';
        }
      }

      return {
        ...prev,
        payments: updatedPayments,
        amount_due: totals.amountDue,
        status: newStatus
      };
    });

    // Call the mutation
    removePaymentMutation.mutate(paymentId, {
      onError: (error) => {
        console.error('Error removing payment:', error);
        queryClient.invalidateQueries({ 
          queryKey: ['invoice', id] 
        });
      }
    });
  }, [id, removePaymentMutation, queryClient]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const deleteInvoice = useCallback(() => {
    if (!localInvoice) return;
    setIsDeleteModalOpen(true);
  }, [localInvoice]);

  const handleConfirmDelete = useCallback(() => {
    deleteInvoiceMutation.mutate(undefined, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["invoices"] });
        navigate({ to: "/" });
      },
      onError: (error) => {
        console.error("Error deleting invoice:", error);
        alert("Failed to delete invoice. Please try again or contact support if the problem persists.");
      },
    });
    setIsDeleteModalOpen(false);
  }, [deleteInvoiceMutation, navigate, queryClient]);

  const handleDownloadPDFWrapper = async () => {
    if (!localInvoice) {
      toast.error("Unable to download PDF. Invoice data is not available.", {
        duration: 3000
      });
      return;
    }

    const toastId = toast.loading('Generating PDF...', {
      duration: Infinity // Prevent auto-dismiss while loading
    });

    try {
      const pdfBlob = await handleDownloadPDF.mutateAsync(localInvoice);
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice-${localInvoice.id || "unknown"}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.dismiss(toastId); // Dismiss the loading toast
      toast.success('PDF downloaded successfully', {
        duration: 3000
      });
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.dismiss(toastId); // Dismiss the loading toast
      toast.error('Failed to generate PDF. Please try again later.', {
        duration: 5000
      });
    }
  };

  const handleSendInvoiceWrapper = async (toastId: string | number, customMessage: string, replyToEmail: string) => {
    if (!localInvoice || !localInvoice.client_id) {
      toast.error("Please select a client before sending the invoice.", {
        duration: 3000
      });
      return;
    }

    const client = clients?.find((c) => c.id === localInvoice.client_id);
    if (!client || !client.email) {
      toast.error("Client email not found. Please update the client information.", {
        duration: 3000
      });
      return;
    }

    try {
      // First generate PDF
      const pdfBlob = await handleDownloadPDF.mutateAsync(localInvoice);
      
      // Update the loading toast
      toast.loading('Sending invoice...', {
        id: toastId,
        duration: Infinity // Prevent auto-dismiss while loading
      });

      // Send the invoice
      await handleSendInvoice.mutateAsync({
        invoiceData: localInvoice,
        clientEmail: client.email,
        clientName: client.full_name,
        pdfBlob,
        customMessage,
        replyToEmail,
      });

      toast.dismiss(toastId); // Dismiss the loading toast
      // Show success toast with a 3 second duration
      toast.success('Invoice sent successfully!', {
        duration: 3000
      });

      setIsSendDrawerOpen(false);
    } catch (error) {
      console.error("Error sending invoice:", error);
      toast.dismiss(toastId); // Dismiss the loading toast
      toast.error('Failed to send invoice. Please try again later.', {
        duration: 5000
      });
    }
  };

  const handleInvoiceNumberChange = useCallback((value: string) => {
    updateInvoice("invoice_number", value || null);
  }, [updateInvoice]);


  const resetToDefaultSettings = useCallback(async () => {
    if (!id || !organizationId) return;

    try {
      console.log("Fetching brand settings for organization:", organizationId);
      const { data: brandSettings, error: brandSettingsError } = await supabase
        .from("brand_settings")
        .select("*")
        .eq("organization_id", organizationId)
        .single();

      if (brandSettingsError) {
        console.error("Error fetching brand settings:", brandSettingsError);
        throw brandSettingsError;
      }

      console.log("Brand settings fetched:", brandSettings);

      const defaultInvoiceSettings = {
        font: brandSettings?.default_font || defaultSettings.default_font,
        header_color: brandSettings?.default_header_color || defaultSettings.default_header_color,
        header_text_color: brandSettings?.default_header_text_color || defaultSettings.default_header_text_color,
        background_color: brandSettings?.default_background_color || defaultSettings.default_background_color,
        body_text_color: brandSettings?.default_body_text_color || defaultSettings.default_body_text_color,
        invoice_template: brandSettings?.default_template || 'structure',
        show_logo: brandSettings?.show_logo ?? true,
        payment_terms: brandSettings?.default_payment_terms || '',
      };

      console.log("Default invoice settings to be applied:", defaultInvoiceSettings);

      console.log("Updating invoice with ID:", id);
      const { data, error } = await supabase
        .from("invoices")
        .update(defaultInvoiceSettings)
        .eq("id", id)
        .select()
        .single();

      if (error) {
        console.error("Error updating invoice:", error);
        throw error;
      }

      console.log("Invoice updated successfully:", data);

      setLocalInvoice(prev => prev ? { ...prev, ...data } : data);
      setShowLogo(data.show_logo); // Add this line to update the local state
      console.log("Local invoice state updated");
    } catch (error) {
      console.error("Error resetting to default settings:", error);
    }
  }, [id, organizationId, supabase, setLocalInvoice]);

  const sortedInvoiceItems = useMemo(() => {
    return localInvoice ? orderBy(localInvoice.items, ["order"], ["asc"]) : [];
  }, [localInvoice]);

  const reorderItems = useCallback(
    (newItems: InvoiceItem[]) => {
      const updatedItems = newItems.map((item, index) => ({
        ...item,
        order: index,
      }));

      setLocalInvoice((prev) => {
        if (!prev) return prev;
        return { ...prev, items: updatedItems };
      });
      reorderInvoiceItemsMutation.mutate(updatedItems);
    },
    [reorderInvoiceItemsMutation]
  );

  const client = useMemo(() => {
    return clients?.find((c) => c.id === localInvoice?.client_id) || null;
  }, [clients, localInvoice?.client_id]);

  const getInvoiceStatus = useCallback((invoice: InvoiceData) => {
    if (invoice.status === 'paid') return 'paid';
    if (invoice.status === 'draft') return 'draft';
    
    const dueDate = startOfDay(new Date(invoice.due_date));
    const today = startOfDay(new Date());
    
    if (isAfter(today, dueDate) && !isSameDay(today, dueDate)) {
      return 'overdue';
    }
    
    return 'unpaid';
  }, []);

  // Modify the useEffect that updates the invoice status
  useEffect(() => {
    if (localInvoice) {
      const currentStatus = getInvoiceStatus(localInvoice);
      if (currentStatus !== localInvoice.status) {
        // Instead of immediately updating the status, you might want to add a confirmation
        // or just log it for now
        console.log(`Invoice status changed from ${localInvoice.status} to ${currentStatus}`);
        // Uncomment the following line if you want to update the status automatically
        // updateInvoice('status', currentStatus);
      }
    }
  }, [localInvoice, getInvoiceStatus, updateInvoice]);

  useEffect(() => {
    setPageHeaderProps({
      parentPath: "/",
      parentName: "Invoices",
      title: (
        <>
          <NavInvoiceTitle>
            {localInvoice?.invoice_number || "#"}
          </NavInvoiceTitle>
          {localInvoice && (
            <StatusBadge $status={getInvoiceStatus(localInvoice)}>
              {getInvoiceStatus(localInvoice)}
            </StatusBadge>
          )}
        </>
      ),
      right: (
        <ButtonWrapper>
          <Button onClick={() => setIsSendDrawerOpen(true)} buttonType="primary">Send Invoice</Button>
          <Button onClick={() => setIsDrawerOpen(true)}>Payments</Button>
          <InvoiceMoreMenu
            onDelete={deleteInvoice}
            onDownloadPDF={handleDownloadPDFWrapper}
            onShare={handleShareInvoice}
            onDuplicate={handleDuplicateInvoice}
          />
          <ToggleButton onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
            <RightSidebar16 />
          </ToggleButton>
        </ButtonWrapper>
      ),
    });
  }, [
    setPageHeaderProps,
    localInvoice,
    isSidebarVisible,
    getInvoiceStatus,
  ]);

  const handleToggleCollectStripePayments = useCallback((enabled: boolean) => {
    setCollectStripePayments(enabled);
    updateInvoice('collect_stripe_payments', enabled);
    console.log('Toggled Stripe payments:', enabled); // Add this log
  }, [updateInvoice]);


  const handleToggleShowLogo = useCallback((show: boolean) => {
    setShowLogo(show);
    updateInvoice('show_logo', show);
  }, [updateInvoice]);

  const [isStripeConnected, setIsStripeConnected] = useState(false);

  // Remove the separate checkStripeConnectionStatus function and use the organization data directly
  const checkStripeConnectionStatus = useCallback(() => {
    if (!organization) return false;
    return organization.stripe_account_id && organization.stripe_account_verified;
  }, [organization]);

  // Update the useEffect to use the new function
  useEffect(() => {
    setIsStripeConnected(checkStripeConnectionStatus());
  }, [checkStripeConnectionStatus]);

  // Move calculateTextColor before handleToggleAutoColor
  const calculateTextColor = useCallback((backgroundColor: string): string => {
    const bgColor = chroma(backgroundColor);
    const isDarkBg = bgColor.luminance() < 0.5;

    let textColor = bgColor
      .set("hsl.h", (bgColor.get("hsl.h") + 30) % 360)
      .set("hsl.s", Math.min(bgColor.get("hsl.s") * 1.5, 1));

    textColor = textColor.set("hsl.l", isDarkBg ? 0.8 : 0.2);

    const minContrast = 3.5;
    let contrast = chroma.contrast(bgColor, textColor);

    const step = isDarkBg ? -0.05 : 0.05;
    while (
      contrast < minContrast &&
      textColor.get("hsl.l") > 0 &&
      textColor.get("hsl.l") < 1
    ) {
      textColor = textColor.set("hsl.l", textColor.get("hsl.l") + step);
      contrast = chroma.contrast(bgColor, textColor);
    }

    return textColor.hex();
  }, []);

  // Then define handleToggleAutoColor
  const handleToggleAutoColor = useCallback(async (enabled: boolean) => {
    if (!organizationId) return;
    
    setAutoColorText(enabled);
    
    try {
      // Update brand settings
      const { error } = await supabase
        .from('brand_settings')
        .update({ auto_color_text: enabled })
        .eq('organization_id', organizationId);
        
      if (error) throw error;
      
      // If enabled, calculate and update text colors
      if (enabled) {
        const newHeaderTextColor = calculateTextColor(localHeaderColor);
        const newBodyTextColor = calculateTextColor(localBackgroundColor);
        setLocalHeaderTextColor(newHeaderTextColor);
        setLocalBodyTextColor(newBodyTextColor);
        
        // Update invoice colors
        const updates = {
          header_text_color: newHeaderTextColor,
          body_text_color: newBodyTextColor
        };
        
        debouncedUpdateInvoice.cancel(); // Cancel any pending updates
        updateInvoiceMutation.mutate(updates);
      }
      
      // Invalidate brand settings query to refresh the data
      queryClient.invalidateQueries({ queryKey: ['brandSettings', organizationId] });
    } catch (error) {
      console.error('Error updating auto color text setting:', error);
      // Revert the local state if the update fails
      setAutoColorText(!enabled);
    }
  }, [
    organizationId, 
    queryClient, 
    calculateTextColor, 
    localHeaderColor, 
    localBackgroundColor, 
    updateInvoiceMutation,
    debouncedUpdateInvoice
  ]);

  // Then define handleImmediateColorChange
  const handleImmediateColorChange = useCallback((field: keyof InvoiceData, value: string) => {
    // Cancel any pending debounced updates to prevent race conditions
    debouncedUpdateInvoice.cancel();
    
    const updates: Partial<InvoiceData> = {};
    
    switch (field) {
      case 'header_color':
        setLocalHeaderColor(value);
        updates.header_color = value;
        
        if (autoColorText) {
          const newHeaderTextColor = calculateTextColor(value);
          setLocalHeaderTextColor(newHeaderTextColor);
          updates.header_text_color = newHeaderTextColor;
        }
        break;
        
      case 'header_text_color':
        if (!autoColorText) {
          setLocalHeaderTextColor(value);
          updates.header_text_color = value;
        }
        break;
        
      case 'background_color':
        setLocalBackgroundColor(value);
        updates.background_color = value;
        
        if (autoColorText) {
          const newBodyTextColor = calculateTextColor(value);
          setLocalBodyTextColor(newBodyTextColor);
          updates.body_text_color = newBodyTextColor;
        }
        break;
        
      case 'body_text_color':
        if (!autoColorText) {
          setLocalBodyTextColor(value);
          updates.body_text_color = value;
        }
        break;
    }
    
    // Update local state immediately for responsive UI
    Object.entries(updates).forEach(([key, value]) => {
      setLocalInvoice(prev => prev ? { ...prev, [key]: value } : prev);
    });

    // Add the missing debounced update
    if (id) {
      debouncedUpdateInvoice(updates);
    }
  }, [
    autoColorText, 
    calculateTextColor, 
    debouncedUpdateInvoice, 
    id
  ]);

  const handleDateChange = useCallback((field: 'invoice_date' | 'due_date', date: Date | null) => {
    if (!id || !localInvoice) return;

    const updates: Partial<InvoiceData> = { [field]: date ? date.toISOString() : null };

    if (field === 'invoice_date' && date) {
      updates.invoice_date = date.toISOString();

      if (localInvoice.due_days) {
        const newDueDate = addDays(date, localInvoice.due_days);
        updates.due_date = newDueDate.toISOString();
      }
    } else if (field === 'due_date' && date && localInvoice.invoice_date) {
      const invoiceDate = new Date(localInvoice.invoice_date);
      const newDueDays = Math.round((date.getTime() - invoiceDate.getTime()) / (1000 * 60 * 60 * 24));
      updates.due_days = newDueDays;
    }

    // Update local state
    setLocalInvoice(prev => {
      if (!prev) return prev;
      return { ...prev, ...updates };
    });

    // Update the database using the mutation
    updateInvoiceMutation.mutate(updates, {
      onError: (error) => {
        console.error('Error updating invoice dates:', error);
      },
      onSuccess: () => {
        console.log('Invoice dates updated successfully', updates);
      }
    });
  }, [id, localInvoice, updateInvoiceMutation]);

  const handleDueDaysChange = useCallback((days: number) => {
    if (!id || !localInvoice?.invoice_date) return;

    const invoiceDate = new Date(localInvoice.invoice_date);
    const newDueDate = addDays(invoiceDate, days);

    const updates = {
      due_days: days,
      due_date: newDueDate.toISOString()
    };

    // Update local state
    setLocalInvoice(prev => {
      if (!prev) return prev;
      return { ...prev, ...updates };
    });

    // Update the database using the mutation
    updateInvoiceMutation.mutate(updates, {
      onError: (error) => {
        console.error('Error updating due days:', error);
      },
      onSuccess: () => {
        console.log('Due days updated successfully', updates);
      }
    });
  }, [id, localInvoice, updateInvoiceMutation]);

  const [isGeneratingNumber, setIsGeneratingNumber] = useState(false);

  const handleCreateNewClient = useCallback(() => {
    setEditingClient(null);
    setIsNewClientDrawerOpen(true);
  }, []);

  const handleEditClient = useCallback((client: Client) => {
    setEditingClient(client);
    setIsNewClientDrawerOpen(true);
  }, []);

  const handleClientChange = useCallback(async (clientId: string | null) => {
    if (!organizationId) {
      updateInvoice("client_id", null);
      return;
    }

    // Immediately update the client ID both locally and in the database
    updateInvoice("client_id", clientId);
    if (localInvoice?.id) {
      const { error: updateError } = await supabase
        .from('invoices')
        .update({ client_id: clientId })
        .eq('id', localInvoice.id);

      if (updateError) {
        console.error('Error updating client:', updateError);
        toast.error('Failed to update client', {
          duration: 3000
        });
        return;
      }
    }

    // Then asynchronously generate the new invoice number
    if (clientId) {
      console.log('Client changed, setting isGeneratingNumber to true');
      setIsGeneratingNumber(true); // Set to true before generating
      
      try {
        const response = await fetch('/api/ai/generate-invoice-number', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await supabase.auth.getSession().then(res => res.data.session?.access_token)}`,
          },
          body: JSON.stringify({
            organizationId,
            clientId,
            currentNumber: localInvoice?.invoice_number,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to generate invoice number');
        }

        const data = await response.json();
        
        if (data.invoiceNumber) {
          // Update the invoice number in the database if we have an invoice ID
          if (localInvoice?.id) {
            const { error: numberUpdateError } = await supabase
              .from('invoices')
              .update({ invoice_number: data.invoiceNumber })
              .eq('id', localInvoice.id);

            if (numberUpdateError) {
              throw numberUpdateError;
            }
          }

          updateInvoice("invoice_number", data.invoiceNumber);
        }
      } catch (error) {
        console.error('Error generating invoice number:', error);
        toast.error('Failed to generate invoice number', {
          duration: 3000
        });
      } finally {
        console.log('Finished generating invoice number after client change, setting isGeneratingNumber to false');
        setTimeout(() => setIsGeneratingNumber(false), 500); // Add a small delay before setting to false
      }
    }
  }, [organizationId, localInvoice?.id, localInvoice?.invoice_number, updateInvoice]);

  const { handleLogoUpload, handleLogoRemove } = useLogoOperations(organizationId);

  const memoizedSidebarRight = useMemo(() => (
    <SidebarRight
      projects={projects ?? []}
      selectedProjectId={selectedProjectId}
      onSelectProject={handleSelectProject}
      invoiceTemplate={localInvoice?.invoice_template ?? 'minimalist'}
      updateInvoice={updateInvoice}
      headerColor={localHeaderColor}
      headerTextColor={localHeaderTextColor}
      backgroundColor={localBackgroundColor}
      bodyTextColor={localBodyTextColor}
      taxRate={localInvoice?.tax_rate ?? 0}
      font={localInvoice?.font || brandSettings?.default_font || "GeistVF"}
      currency={localInvoice?.currency ?? 'USD'}
      isVisible={isSidebarVisible}
      resetToDefaultSettings={resetToDefaultSettings}
      organizationId={organizationId ?? ''}
      clients={clients || []}
      selectedClientId={localInvoice?.client_id || null}
      onSelectClient={handleClientChange}
      invoiceDate={localInvoice?.invoice_date || null}
      dueDate={localInvoice?.due_date || null}
      dueDays={localInvoice?.due_days ?? null}
      emailTrackingData={emailTrackingData}
      invoiceNumber={localInvoice?.invoice_number || ""}
      onInvoiceNumberChange={handleInvoiceNumberChange}
      showLogo={showLogo}
      onToggleShowLogo={handleToggleShowLogo}
      collectStripePayments={collectStripePayments}
      onToggleCollectStripePayments={handleToggleCollectStripePayments}
      isStripeConnected={isStripeConnected}
      onImmediateColorChange={handleImmediateColorChange}
      autoColorText={autoColorText}
      onToggleAutoColor={handleToggleAutoColor}
      onInvoiceDateChange={(date) => handleDateChange('invoice_date', date)}
      onDueDateChange={(date) => handleDateChange('due_date', date)}
      onDueDaysChange={handleDueDaysChange}
      onCreateNewClient={handleCreateNewClient}
      logoUrl={organization?.logo_url || ''}
      errors={errors}
      onLogoUpload={handleLogoUpload}
      onLogoRemove={handleLogoRemove}
    />
  ), [
    localHeaderColor, localHeaderTextColor, localBackgroundColor, localBodyTextColor,
    updateInvoice, handleImmediateColorChange, projects, selectedProjectId, handleSelectProject,
    localInvoice, brandSettings, isSidebarVisible, resetToDefaultSettings, organizationId,
    clients, emailTrackingData, showLogo, handleToggleShowLogo, collectStripePayments,
    handleToggleCollectStripePayments, isStripeConnected, handleInvoiceNumberChange,
    autoColorText, handleToggleAutoColor, handleDateChange, handleDueDaysChange, handleCreateNewClient, organization,
    errors, handleClientChange, handleLogoUpload, handleLogoRemove
  ]);

  const [isUpdateOrganizationDrawerOpen, setIsUpdateOrganizationDrawerOpen] = useState(false);
  const [editingClient, setEditingClient] = useState<Client | null>(null);

  const handleUpdateOrganization = (updatedOrganization: Organization) => {
    // Remove null values from the organization before updating
    const cleanedOrganization = Object.fromEntries(
      Object.entries(updatedOrganization).map(([key, value]) => [key, value ?? ''])
    );

    // Invalidate and refetch the organization queries
    queryClient.invalidateQueries({ queryKey: ['organization', organizationId] });
    queryClient.invalidateQueries({ queryKey: ['organizationDetails', organizationId] });
    
    // Force refetch the invoice to get updated organization details
    queryClient.invalidateQueries({ queryKey: ['invoice', id, organizationId] });
    
    console.log('Organization updated:', cleanedOrganization);
  };

  // Add this new function to handle opening the drawer
  const handleOpenUpdateOrganizationDrawer = () => {
    setIsUpdateOrganizationDrawerOpen(true);
  };

  // Always show skeleton if we're loading or don't have complete data yet
  if (isLoadingInvoice || !localInvoice) {
    return <InvoicePageSkeleton />;
  }

  if (isErrorInvoice) {
    return (
      <PageWrapper>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          Error loading invoice. Please try again later.
        </div>
      </PageWrapper>
    );
  }

  // Only show this when we're certain there's no data
  if (!invoice && !isLoadingInvoice) {
    return (
      <PageWrapper>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          No invoice data available.
        </div>
      </PageWrapper>
    );
  }

  const SelectedTemplate = invoiceTemplates[localInvoice.invoice_template];

  const handleCreateNewService = () => {
    setIsAddServiceDrawerOpen(true);
  };

  const handleNewServiceCreated = (newService: Service) => {
    // Add the new service as a line item
    if (id) {
      const newItem: Omit<InvoiceItem, "id"> = {
        invoice_id: id,
        description: newService.name,
        quantity: 1,
        price: newService.price,
        taxable: newService.taxable,
        order: localInvoice?.items?.length ?? 0,
      };

      // Optimistic update
      const tempId = `temp-${Date.now()}`;
      setLocalInvoice((prev) => {
        if (!prev) return prev;
        const updatedItems = Array.isArray(prev.items)
          ? [...prev.items, { ...newItem, id: tempId, order: prev.items.length }]
          : [{ ...newItem, id: tempId, order: 0 }];
        return { ...prev, items: updatedItems };
      });

      // Add the item to the database
      addInvoiceItemMutation.mutate(newItem, {
        onSuccess: ({ newItem: addedItem, updatedInvoice }) => {
          console.log("Successfully added item:", addedItem);
          setLocalInvoice((prev) => {
            if (!prev) return updatedInvoice;
            const updatedItems = Array.isArray(prev.items)
              ? prev.items.map(item => item.id === tempId ? { ...addedItem, order: prev.items.length - 1 } : item)
              : [{ ...addedItem, order: 0 }];
            return {
              ...prev,
              ...updatedInvoice,
              items: updatedItems,
              // Preserve 'draft' status if it was already set
              status: prev.status === 'draft' ? 'draft' : updatedInvoice.status
            };
          });
        },
        onError: (error) => {
          console.error("Error adding new item:", error);
          // Revert the optimistic update
          setLocalInvoice((prev) => {
            if (!prev) return prev;
            const updatedItems = Array.isArray(prev.items)
              ? prev.items.filter(item => item.id !== tempId)
              : [];
            return { ...prev, items: updatedItems };
          });
          // Optionally, show an error message to the user
        }
      });
    }
  };

  return (
    <PageWrapper>
      <InvoiceContainer>
        <InvoiceWrapper>
          <MainContent
            $isSidebarVisible={isSidebarVisible}
            $backgroundColor={localBackgroundColor}
            $bodyTextColor={localBodyTextColor}
            $font={localInvoice?.font || (brandSettings?.default_font ?? "Inter")}
          >
            <AnimatedHeader>
              {SelectedTemplate && (
                <SelectedTemplate
                  invoice={{
                    ...localInvoice,
                    ...localChanges,
                    header_color: localHeaderColor,
                    header_text_color: localHeaderTextColor,
                    background_color: localBackgroundColor,
                    body_text_color: localBodyTextColor,
                    font: localInvoice?.font || brandSettings?.default_font || "Inter",
                    logo_url: organization?.logo_url,
                    show_logo: showLogo,
                  }}
                  updateInvoice={updateInvoice}
                  clients={clients || []}
                  ClientSelectorComponent={(props: ClientSelectorProps) => (
                    <ClientSelector
                      {...props}
                      onSelectClient={(client) => handleClientChange(client?.id || null)}
                      onCreateNewClient={handleCreateNewClient}
                      onEditClient={handleEditClient}
                    />
                  )}
                  onCreateNewClient={handleCreateNewClient}
                  selectedClient={client}
                  organization={organization}
                  showLogo={showLogo}
                  onOpenUpdateOrganizationDrawer={handleOpenUpdateOrganizationDrawer}
                  isGeneratingNumber={isGeneratingNumber}
                />
              )}
            </AnimatedHeader>
            <InvoiceDetailsContainer>
              <InvoiceItems
                invoice={{
                  ...localInvoice,
                  items: sortedInvoiceItems
                    .filter(item => !deletingItems.has(item.id) && item.id !== undefined)
                    .map(item => ({ ...item, id: item.id as string }))
                }}
                services={services || []}
                handleServiceSelect={handleServiceSelectWrapper}
                handleDescriptionChange={handleDescriptionChange}
                updateItem={updateItem}
                deleteItem={deleteItem}
                reorderItems={reorderItems}
                onCreateNewService={handleCreateNewService}
                backgroundColor={localBackgroundColor}
                addItem={() => addItem()}
              />
              {localInvoice && (
                <InvoiceTotals
                  invoice={localInvoice}
                  bodyTextColor={localBodyTextColor}
                  backgroundColor={localBackgroundColor}
                  onTaxRateChange={(newRate) => updateInvoice("tax_rate", newRate)}
                />
              )}
            </InvoiceDetailsContainer>
            <TwoColumnContainer>
                <LeftColumn>
                  <PaymentTermsEditor
                    initialContent={localInvoice.payment_terms || brandSettings?.default_payment_terms || ''}
                    onUpdate={async (content) => {
                      console.log('Updating payment terms:', {
                        newContent: content,
                        currentTerms: localInvoice.payment_terms,
                        defaultTerms: brandSettings?.default_payment_terms
                      });

                      // Update invoice first
                      updateInvoice("payment_terms", content);
                      
                      // Then update brand settings if organization exists
                      if (organizationId) {
                        try {
                          const { error } = await supabase
                            .from('brand_settings')
                            .upsert({
                              organization_id: organizationId,
                              default_payment_terms: content
                            }, { onConflict: 'organization_id', ignoreDuplicates: false });

                          if (error) {
                            console.error('Error updating brand settings payment terms:', error);
                            toast.error('Failed to update default payment terms', {
                              duration: 3000
                            });
                          } else {
                            queryClient.invalidateQueries({
                              queryKey: ['brandSettings', organizationId]
                            });
                            console.log('Successfully updated brand settings payment terms');
                          }
                        } catch (error) {
                          console.error('Error in payment terms upsert:', error);
                          toast.error('Failed to update default payment terms', {
                            duration: 3000
                          });
                        }
                      }
                    }}
                    backgroundColor={localBackgroundColor}
                    bodyTextColor={localBodyTextColor}
                  />
                </LeftColumn>
                <RightColumn>
                  <NotesEditor
                    initialContent={localInvoice.notes || ""}
                    onUpdate={(content) => updateInvoice("notes", content)}
                    backgroundColor={localBackgroundColor}
                    padding="32px 76px 76px 64px"
                  />
                </RightColumn>
              </TwoColumnContainer>

          </MainContent>
          <InvoiceHistory
            invoice={localInvoice}
            emailTrackingData={emailTrackingData}
          />
        </InvoiceWrapper>
        {memoizedSidebarRight}
      </InvoiceContainer>

      <PaymentsDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        payments={localInvoice?.payments || []}
        addPayment={addPayment}
        removePayment={removePayment}
        amount_due={calculateInvoiceTotals(localInvoice).amountDue} // Changed from amount_due to amountDue
        organizationId={organizationId}
        invoiceStatus={localInvoice?.status || 'draft'}
      />
      <NewClientDrawer
        isOpen={isNewClientDrawerOpen}
        setIsOpen={setIsNewClientDrawerOpen}
        onClientAdded={(updatedClient) => {
          queryClient.setQueryData<Client[]>(["clients"], (old) => {
            if (editingClient) {
              // If editing, replace the old client with the updated one
              return (old || []).map(c => c.id === updatedClient.id ? updatedClient : c);
            } else {
              // If creating, add the new client to the list
              return [...(old || []), updatedClient];
            }
          });
          updateInvoice("client_id", updatedClient.id);
          setIsNewClientDrawerOpen(false);
          setEditingClient(null); // Reset editing client
        }}
        editingClient={editingClient}
        organizationId={organizationId}
      />
      <SendInvoiceDrawer
        isOpen={isSendDrawerOpen}
        setIsOpen={setIsSendDrawerOpen}
        invoice={localInvoice}
        client={client}
        onSendInvoice={handleSendInvoiceWrapper}
        onDownloadPDF={handleDownloadPDFWrapper}
        onOverlayClick={() => setIsSendDrawerOpen(false)}
        onSelectClient={(clientId: string | null) => handleClientChange(clientId)}
        onCreateNewClient={() => {
          setEditingClient(null);
          setIsNewClientDrawerOpen(true);
        }}
        onEditClient={(client) => {
          setEditingClient(client);
          setIsNewClientDrawerOpen(true);
        }}
        clients={clients || []}
        organizationId={organizationId}
      />
      <AddServiceDrawer
        isOpen={isAddServiceDrawerOpen}
        setIsOpen={setIsAddServiceDrawerOpen}
        serviceToEdit={null}
        onServiceCreated={handleNewServiceCreated}
      />
      <UpdateOrganizationDrawer
        isOpen={isUpdateOrganizationDrawerOpen}
        setIsOpen={setIsUpdateOrganizationDrawerOpen}
        onOrganizationUpdated={handleUpdateOrganization}
      />
      {errors.client_id && (
        <div style={{ color: '#ff4d4f', padding: '8px', marginTop: '8px' }}>
          {errors.client_id}
        </div>
      )}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title="Delete Invoice"
        message={`Are you sure you want to delete invoice ${localInvoice?.invoice_number || 'Unknown'}? This action cannot be undone.`}
        confirmLabel="Delete"
        onConfirm={handleConfirmDelete}
        onCancel={() => setIsDeleteModalOpen(false)}
        isDestructive
      />
    </PageWrapper>
  );
};

export default InvoicePage;
