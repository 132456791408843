import React from 'react';

const DueLogo: React.FC<{ className?: string }> = ({ className }) => (
  <svg 
    width="419" 
    height="249" 
    viewBox="0 0 419 249" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="0.97168" y="0.40863" width="418" height="248" rx="16" fill="#EF4611"/>
    <rect x="29.4717" y="28.9086" width="47" height="31" rx="5.5" fill="#EF4611" stroke="black"/>
    <line x1="53.4717" y1="28.5056" x2="53.4717" y2="38.5056" stroke="black"/>
    <line x1="75.9717" y1="44.0056" x2="62.9717" y2="44.0056" stroke="black"/>
    <line x1="43.9717" y1="44.0056" x2="29.9717" y2="44.0056" stroke="black"/>
    <path d="M75.9717 53.5055H63.5846L60.9717 50.0056" stroke="black"/>
    <path d="M75.9719 35.5055L63.5848 35.5055L60.9717 39.5056" stroke="black"/>
    <path d="M28.9717 35.5056L41.3586 35.5056L43.4717 38.0056" stroke="black"/>
    <path d="M28.9717 53.0056L41.7858 53.0056L43.9717 50.5056" stroke="black"/>
    <line x1="53.4717" y1="51.5056" x2="53.4717" y2="59.5056" stroke="black"/>
    <path d="M43.4717 38.0056H56.9717C60.5615 38.0056 63.4717 40.9157 63.4717 44.5056C63.4717 48.0954 60.5615 51.0056 56.9717 51.0056H43.4717V38.0056Z" stroke="black"/>
    <rect x="9.47168" y="8.90863" width="401" height="231" rx="7.5" stroke="black" strokeOpacity="0.25"/>
  </svg>
);

export default DueLogo; 