import React, { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from './supabaseClient';
import styled from 'styled-components';
import { useOrganization } from './hooks/useOrganization';
import Button from './components/Button';
import { CreditCard16, Bank16 } from './components/Icon';
import DueLogo from './components/DueLogo';

const DashboardContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  position: relative;
`;

interface MousePosition {
  x: number;
  y: number;
}

const LogoContainer = styled.div<{ $isButtonHovered?: boolean; $mousePosition?: MousePosition; $isHovered?: boolean }>`
  max-width: 400px;
  margin: 0 auto;
  transform: ${props => {
    const baseRotation = 'rotate(-10deg)';
    const perspective = 'perspective(1000px)';
    
    if (props.$isButtonHovered) {
      return `${baseRotation} ${perspective} rotateY(-15deg) rotateX(-5deg) translateZ(40px)`;
    }
    
    if (props.$isHovered && props.$mousePosition) {
      const { x, y } = props.$mousePosition;
      const tiltX = (y - 0.5) * 20; // -10 to 10 degrees
      const tiltY = (x - 0.5) * 20; // -10 to 10 degrees
      return `${baseRotation} ${perspective} rotateX(${-tiltX}deg) rotateY(${tiltY}deg) translateZ(30px)`;
    }
    
    return `${baseRotation} ${perspective} rotateY(5deg) rotateX(5deg)`;
  }};
  display: flex;
  justify-content: center;
  width: 100%;
  transition: transform 0.1s ease-out;
  will-change: transform;

  &:not(:hover) {
    transition: transform 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
`;

const Title = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 24px;
`;

const Section = styled.section`
  margin-bottom: 24px;
`;

const StatusMessage = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.7);
  background-color: rgba(0, 0, 0, 0.05);
  padding: 8px 16px;
  border-radius: 8px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 24px;
`;

const ActionButton = styled(Button)`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  justify-content: center;
  border-radius: 6px;
  position: relative;
  z-index: 1;
`;

const PricingSection = styled.div`
  margin: -120px -48px 0 -48px;
  padding: 32px 48px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  backdrop-filter: blur(12px);
  background: linear-gradient(180deg, rgba(248, 247, 246, 0.50) 0%, #F8F7F6 20%);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 15%,
      rgba(0, 0, 0, 0.1) 85%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;

const PricingTitle = styled.h2`
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0 0 0;
`;

const PricingDescription = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.7);
  margin: 8px 0 32px 0;
`;

const PricingItem = styled.div`
  margin-bottom: 32px;
`;

const PricingItemTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const PricingItemRate = styled.div`
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 4px 28px;
`;

const PricingItemDescription = styled.div`
  margin: 0 0 8px 28px;
  color: rgba(0, 0, 0, 0.5);
`;

interface DashboardProps {
  verificationInProgress?: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ verificationInProgress = false }) => {
  const { data: organizationId } = useOrganization();
  const [stripeStatus, setStripeStatus] = useState<'not_connected' | 'connected' | 'verified'>('not_connected');
  const [isConnecting, setIsConnecting] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const [mousePosition, setMousePosition] = useState<MousePosition>({ x: 0.5, y: 0.5 });
  const queryClient = useQueryClient();

  const { data: orgData, isLoading, error } = useQuery({
    queryKey: ['organization', organizationId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('organizations')
        .select('*')
        .eq('id', organizationId)
        .single();
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
  });

  const disconnectStripeMutation = useMutation({
    mutationFn: async () => {
      const response = await fetch('/api/disconnect-stripe-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to disconnect Stripe account');
      }
      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization', organizationId] });
      setStripeStatus('not_connected');
    },
  });

  useEffect(() => {
    if (orgData) {
      if (orgData.stripe_account_id && orgData.stripe_account_verified) {
        setStripeStatus('verified');
      } else if (orgData.stripe_account_id) {
        setStripeStatus('connected');
      } else {
        setStripeStatus('not_connected');
      }
    }
  }, [orgData]);

  const handleStripeConnect = async () => {
    setIsConnecting(true);
    try {
      const response = await fetch('/api/create-stripe-connect-account', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`,
        },
      });
      const data = await response.json();
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (error) {
      console.error('Error connecting to Stripe:', error);
      setIsConnecting(false);
    }
  };

  const handleStripeDisconnect = async () => {
    if (window.confirm('Are you sure you want to disconnect your Stripe account? This will affect your ability to process payments.')) {
      disconnectStripeMutation.mutate();
    }
  };

  const handleGoToStripeDashboard = () => {
    if (orgData?.stripe_account_id) {
      window.open(`https://dashboard.stripe.com/${orgData.stripe_account_id}`, '_blank');
    } else {
      console.error('Stripe account ID not found');
      alert('Unable to open Stripe dashboard. Please try again later.');
    }
  };

  const handleLogoMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const element = event.currentTarget;
    const rect = element.getBoundingClientRect();
    const x = (event.clientX - rect.left) / rect.width;
    const y = (event.clientY - rect.top) / rect.height;
    setMousePosition({ x, y });
  };

  if (isLoading) return <DashboardContainer>Loading...</DashboardContainer>;
  if (error) return <DashboardContainer>Error: {error.message}</DashboardContainer>;

  return (
    <DashboardContainer>
      <Title>Stripe & Due</Title>
      <Description>Due integrates with Stripe for payments. Get paid faster and more securely while offering your clients a seamless checkout experience.</Description>
      
      <Section>
        {verificationInProgress ? (
          <>
            <StatusMessage>Stripe account verification is in progress.</StatusMessage>
            <StatusMessage>This process may take a few moments. Please refresh the page in a minute.</StatusMessage>
          </>
        ) : (
          <>
            {stripeStatus === 'not_connected' && (
              <>
                <ActionButton 
                  onClick={handleStripeConnect} 
                  disabled={isConnecting}
                  buttonType="primary"
                  onMouseEnter={() => setIsButtonHovered(true)}
                  onMouseLeave={() => setIsButtonHovered(false)}
                >
                  {isConnecting ? 'Connecting...' : 'Connect Stripe'}
                </ActionButton>
              </>
            )}
            {stripeStatus === 'connected' && (
              <>
                <StatusMessage>Your Stripe account is connected. Complete verification in your Stripe dashboard to start accepting payments.</StatusMessage>
                <ButtonGroup>
                  <ActionButton 
                    onClick={handleStripeConnect}
                    disabled={isConnecting}
                    buttonType="primary"
                  >
                    {isConnecting ? 'Connecting...' : 'Complete Verification'}
                  </ActionButton>
                  <ActionButton onClick={handleStripeDisconnect}>
                    Disconnect Stripe
                  </ActionButton>
                </ButtonGroup>
              </>
            )}
            {stripeStatus === 'verified' && (
              <>
                <StatusMessage>Your Stripe account is fully verified. You can now accept payments through your invoices.</StatusMessage>
                <ButtonGroup>
                  <ActionButton onClick={handleGoToStripeDashboard} buttonType="primary">
                    Go to Dashboard
                  </ActionButton>
                  <ActionButton onClick={handleStripeDisconnect}>
                    Disconnect Stripe
                  </ActionButton>
                </ButtonGroup>
              </>
            )}
          </>
        )}
      </Section>
      <LogoContainer 
        $isButtonHovered={isButtonHovered}
        $isHovered={isLogoHovered}
        $mousePosition={mousePosition}
        onMouseMove={handleLogoMouseMove}
        onMouseEnter={() => setIsLogoHovered(true)}
        onMouseLeave={() => setIsLogoHovered(false)}
      >
        <DueLogo />
      </LogoContainer>
      <PricingSection>
        <PricingTitle>Pricing</PricingTitle>
        <PricingDescription>
          Due receives nothing for payment charges, all fees are set my credit card processors and are industry standard.
        </PricingDescription>

        <PricingItem>
          <PricingItemTitle>
            <CreditCard16 />
            <span>Credit Card Transactions</span>
          </PricingItemTitle>
          <PricingItemRate>2.9% + C$0.30</PricingItemRate>
          <PricingItemDescription>per successful charge for domestic cards</PricingItemDescription>
        </PricingItem>

        <PricingItem>
          <PricingItemTitle>
            <Bank16 />
            <span>Bank Debits and Transfers</span>
          </PricingItemTitle>
          <PricingItemDescription>
            Accept large payments or recurring charges securely from customers with pre-authorised debits.
          </PricingItemDescription>
          <PricingItemRate>1% + C$0.40 Pre-authorised debits</PricingItemRate>
          <PricingItemRate>C$5.00 cap</PricingItemRate>
          <PricingItemRate>+ C$1.00 per instant verification.</PricingItemRate>
          <PricingItemRate>C$5 for failures and disputes.</PricingItemRate>
        </PricingItem>
      </PricingSection>
    </DashboardContainer>
  );
};

export default Dashboard;