import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { supabase } from "./supabaseClient";
import { useOrganization } from "./hooks/useOrganization";
import { Organization } from "./types";
import ImageUploader from "./components/ImageUploader";
import Switch from "./components/Switch";
import { useLogoUrl } from "./hooks/useLogoUrl";
import { debounce } from "lodash";
import { toast } from 'sonner';
import PaymentTermsEditor from "./components/PaymentTermsEditor";
import { organizationSchema } from './schemas/organization';
import { z } from 'zod';
import { useLogoOperations } from './hooks/useLogoOperations';

// Add interfaces
interface BrandSettings {
  organization_id: string;
  default_payment_terms: string | null;
}

interface OrganizationUpdate extends Partial<Organization> {
  updated_at?: string;
}

const PageWrapper = styled.div`
  padding: 40px 40px 120px 40px;
  max-width: 800px;
  margin: 0 auto;
`;

const PageTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 32px 0;
  padding-bottom: 16px;
  color: #333;
  border-bottom: 1px solid rgba(0,0,0,0.1);
`;

const InputField = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  input {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: ${props => props.hasError ? '1px solid #ff4d4f' : '0'};
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: ${props => props.hasError ? '0 0 0 2px rgba(255, 77, 79, 0.2)' : '0 0 0 1px rgba(0,0,0,0.5)'};
    }
  }
`;

const ErrorMessage = styled.span`
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
`;

const HelpText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  margin-top: 8px;
`;

const InputFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 48px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 32px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const PaymentTermsContainer = styled.div`
  margin-top: 24px;
`;

const PaymentTermsLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
  margin-bottom: 8px;
`;

const StyledPaymentTermsEditor = styled.div`
  .ProseMirror {
    padding: 1px 20px !important;
    font-size: 16px;
    border-radius: 6px;
    border: 0;
    background-color: rgba(0,0,0,0.05);
    min-height: 100px;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
    }

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;

const OrganizationDetailsPage: React.FC = () => {
  const queryClient = useQueryClient();
  const { data: organizationId } = useOrganization();
  const isEditingRef = useRef(false);

  const [localOrganization, setLocalOrganization] = useState<Organization | null>(null);
  const [enableEmailTracking, setEnableEmailTracking] = useState(true);
  const [defaultPaymentTerms, setDefaultPaymentTerms] = useState('');
  const [errors, setErrors] = useState<Record<string, string>>({});

  const { data: organization } = useQuery({
    queryKey: ["organization", organizationId],
    queryFn: async () => {
      if (!organizationId) return null;
      const { data, error } = await supabase
        .from("organizations")
        .select("*")
        .eq("id", organizationId)
        .single();
      if (error) throw error;
      return data as Organization;
    },
    enabled: !!organizationId,
  });

  const { data: brandSettings } = useQuery<BrandSettings | null>({
    queryKey: ['brandSettings', organizationId],
    queryFn: async () => {
      if (!organizationId) return null;
      const { data, error } = await supabase
        .from('brand_settings')
        .select('*')
        .eq('organization_id', organizationId)
        .single();
      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
  });

  useEffect(() => {
    if (brandSettings) {
      setDefaultPaymentTerms(brandSettings.default_payment_terms || '');
    }
  }, [brandSettings]);

  const { data: logoUrl } = useLogoUrl(organizationId);

  const { handleLogoUpload, handleLogoRemove } = useLogoOperations(organizationId);

  useEffect(() => {
    if (organization && !isEditingRef.current) {
      setLocalOrganization(organization);
      setEnableEmailTracking(organization.enable_email_tracking);
    }
  }, [organization]);

  const validateOrganization = (orgData: z.infer<typeof organizationSchema>) => {
    try {
      organizationSchema.parse(orgData);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          const field = err.path[0];
          if (typeof field === 'string') {
            newErrors[field] = err.message;
          }
        });
        setErrors(newErrors);
      }
      return false;
    }
  };

  const updateOrganizationMutation = useMutation({
    mutationFn: async (updatedOrganization: OrganizationUpdate) => {
      if (!organizationId) throw new Error("No organization found");

      const { data: currentOrg, error: fetchError } = await supabase
        .from("organizations")
        .select("*")
        .eq("id", organizationId)
        .single();

      if (fetchError) throw fetchError;

      // Create update fields without updated_at
      const updateFields = { ...updatedOrganization };
      delete updateFields.updated_at;

      const orgData = {
        ...currentOrg,
        ...updateFields,
      };

      // Only validate if we're updating fields
      if (Object.keys(updateFields).length > 0 && !validateOrganization(orgData)) {
        throw new Error("Invalid organization data");
      }

      const { data, error } = await supabase
        .from("organizations")
        .update(updateFields)
        .eq("id", organizationId)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["organization", organizationId], (oldData: Organization | undefined) => {
        return oldData ? { ...oldData, ...data } : data;
      });
      queryClient.invalidateQueries({ queryKey: ["userOrganizations"] });
      if (!isEditingRef.current) {
        toast.success('Changes saved successfully');
      }
    },
    onError: (error) => {
      toast.error('Failed to save changes: ' + error.message);
    }
  });

  const debouncedUpdateOrganization = useRef(
    debounce(
      (changes: OrganizationUpdate, isEditing: boolean) => {
        const toastId = toast.loading('Saving changes...');
        updateOrganizationMutation.mutate(changes, {
          onSuccess: () => {
            toast.success('Changes saved', { id: toastId });
          },
          onError: (error) => {
            toast.error('Failed to save changes: ' + error.message, { id: toastId });
          },
          onSettled: () => {
            if (!isEditing) {
              isEditingRef.current = false;
            }
          },
        });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const handleInputChange = (field: keyof Organization, value: string | boolean) => {
    if (!localOrganization) return;
    
    isEditingRef.current = true;
    const updatedOrg = { ...localOrganization, [field]: value };
    
    // Validate before updating local state
    try {
      const validationResult = organizationSchema.safeParse(updatedOrg);
      if (!validationResult.success) {
        setErrors(Object.fromEntries(
          validationResult.error.errors.map(err => [err.path[0], err.message])
        ));
        return;
      }
      setErrors({});
    } catch (error) {
      console.error('Validation error:', error);
      return;
    }
    
    // Update local state immediately
    setLocalOrganization(updatedOrg);
    
    if (organizationId) {
      debouncedUpdateOrganization({ [field]: value }, isEditingRef.current);
    }
  };

  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [localOrganization]);

  const debouncedUpdatePaymentTerms = useRef(
    debounce(
      async (content: string) => {
        if (!organizationId) return;
        const toastId = toast.loading('Saving payment terms...');

        try {
          const { error } = await supabase
            .from('brand_settings')
            .upsert({
              organization_id: organizationId,
              default_payment_terms: content || null
            }, { onConflict: 'organization_id' });

          if (error) {
            console.error('Error updating brand settings payment terms:', error);
            toast.error('Failed to update default payment terms', { id: toastId });
          } else {
            queryClient.invalidateQueries({
              queryKey: ['brandSettings', organizationId]
            });
            toast.success('Payment terms saved', { id: toastId });
          }
        } catch (error) {
          console.error('Error in payment terms upsert:', error);
          toast.error('Failed to update default payment terms', { id: toastId });
        }
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const handlePaymentTermsUpdate = (content: string) => {
    setDefaultPaymentTerms(content);
    debouncedUpdatePaymentTerms(content);
  };

  if (!localOrganization) return null;

  return (
    <PageWrapper>
      <PageTitle>Organization Settings</PageTitle>
      
      <LogoContainer>
        <ImageUploader
          currentImage={logoUrl || null}
          onUpload={handleLogoUpload}
          onRemove={handleLogoRemove}
        />
      </LogoContainer>

      <InputFieldsContainer>
        <InputField hasError={!!errors.name}>
          <label htmlFor="organizationName">Organization Name</label>
          <input
            id="organizationName"
            type="text"
            value={localOrganization?.name || ""}
            onChange={(e) => handleInputChange("name", e.target.value)}
            placeholder="Organization Name"
          />
          {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
        </InputField>
        <InputField hasError={!!errors.business_name}>
          <label htmlFor="businessName">Business Name</label>
          <input
            id="businessName"
            type="text"
            value={localOrganization.business_name || ""}
            onChange={(e) => handleInputChange("business_name", e.target.value)}
            placeholder="Business Name"
          />
          {errors.business_name && <ErrorMessage>{errors.business_name}</ErrorMessage>}
        </InputField>
      </InputFieldsContainer>

      <PageTitle>Contact</PageTitle>
      <InputFieldsContainer>
        <InputField hasError={!!errors.business_email}>
          <label htmlFor="businessEmail">Business Email</label>
          <input
            id="businessEmail"
            type="email"
            value={localOrganization.business_email || ""}
            onChange={(e) => handleInputChange("business_email", e.target.value)}
            placeholder="Business Email"
          />
          {errors.business_email && <ErrorMessage>{errors.business_email}</ErrorMessage>}
          <HelpText>This email will be used as the reply-to address when sending invoices to clients.</HelpText>
        </InputField>
      </InputFieldsContainer>

      <PageTitle>Address</PageTitle>
      <InputFieldsContainer>
        <InputField hasError={!!errors.address_line1}>
          <label htmlFor="addressLine1">Address Line 1</label>
          <input
            id="addressLine1"
            type="text"
            value={localOrganization.address_line1 || ""}
            onChange={(e) => handleInputChange("address_line1", e.target.value)}
            placeholder="Address Line 1"
          />
          {errors.address_line1 && <ErrorMessage>{errors.address_line1}</ErrorMessage>}
        </InputField>
        <InputField hasError={!!errors.address_line2}>
          <label htmlFor="addressLine2">Address Line 2</label>
          <input
            id="addressLine2"
            type="text"
            value={localOrganization.address_line2 || ""}
            onChange={(e) => handleInputChange("address_line2", e.target.value)}
            placeholder="Address Line 2"
          />
          {errors.address_line2 && <ErrorMessage>{errors.address_line2}</ErrorMessage>}
        </InputField>
        <InputField hasError={!!errors.city}>
          <label htmlFor="city">City</label>
          <input
            id="city"
            type="text"
            value={localOrganization.city || ""}
            onChange={(e) => handleInputChange("city", e.target.value)}
            placeholder="City"
          />
          {errors.city && <ErrorMessage>{errors.city}</ErrorMessage>}
        </InputField>
        <InputField hasError={!!errors.state}>
          <label htmlFor="state">State/Province</label>
          <input
            id="state"
            type="text"
            value={localOrganization.state || ""}
            onChange={(e) => handleInputChange("state", e.target.value)}
            placeholder="State/Province"
          />
          {errors.state && <ErrorMessage>{errors.state}</ErrorMessage>}
        </InputField>
        <InputField hasError={!!errors.postal_code}>
          <label htmlFor="postalCode">Postal Code</label>
          <input
            id="postalCode"
            type="text"
            value={localOrganization.postal_code || ""}
            onChange={(e) => handleInputChange("postal_code", e.target.value)}
            placeholder="Postal Code"
          />
          {errors.postal_code && <ErrorMessage>{errors.postal_code}</ErrorMessage>}
        </InputField>
        <InputField hasError={!!errors.country}>
          <label htmlFor="country">Country</label>
          <input
            id="country"
            type="text"
            value={localOrganization.country || ""}
            onChange={(e) => handleInputChange("country", e.target.value)}
            placeholder="Country"
          />
          {errors.country && <ErrorMessage>{errors.country}</ErrorMessage>}
        </InputField>
      </InputFieldsContainer>

      <PageTitle>Preferences</PageTitle>
      <FormGroup>
        <Switch
          checked={enableEmailTracking}
          onChange={(checked) => {
            setEnableEmailTracking(checked);
            handleInputChange("enable_email_tracking", checked);
          }}
          label="Enable Email Tracking"
          labelPosition="left"
        />
      </FormGroup>

      <PaymentTermsContainer>
        <PaymentTermsLabel>Default Payment Terms</PaymentTermsLabel>
        <StyledPaymentTermsEditor>
          <PaymentTermsEditor
            initialContent={defaultPaymentTerms}
            onUpdate={handlePaymentTermsUpdate}
          />
        </StyledPaymentTermsEditor>
      </PaymentTermsContainer>

      {updateOrganizationMutation.isError && (
        <p>
          Error updating organization:{" "}
          {updateOrganizationMutation.error.message}
        </p>
      )}
    </PageWrapper>
  );
};

export default OrganizationDetailsPage;