import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { supabase } from '../supabaseClient';
import Compressor from 'compressorjs';
import { Close8 } from './Icon';
import Button from './Button';

const UploaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ImagePreviewContainer = styled.div`
  position: relative;
  margin-right: 16px;
`;

const ImagePreview = styled.img`
  max-width: 56px;
  max-height: 56px;
  object-fit: contain;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: rgba(0,0,0,0.8);
  border: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;

  svg path {
    fill: rgba(255,255,255,0.8);
  }

  &:hover {
    svg path {
      fill: rgba(255,255,255,1);
    }
  }
`;

const HoverContainer = styled.div`
  position: relative;
  display: inline-block;
  &:hover ${DeleteButton} {
    opacity: 1;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

interface ImageUploaderProps {
  currentImage: string | null;
  onUpload: (imageUrl: string) => void;
  onRemove: (fileName: string | null) => Promise<void>;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ currentImage, onUpload, onRemove }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(currentImage);

  const generateUniqueId = () => {
    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 8);
    return `${timestamp}-${randomString}`;
  };

  const compressImage = (file: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        maxWidth: 800,
        maxHeight: 800,
        convertSize: 1000000,
        success(result) {
          if (result instanceof Blob) {
            resolve(result);
          } else {
            reject(new Error('Compression failed to return a valid Blob'));
          }
        },
        error(err) {
          reject(err);
        },
      });
    });
  };

  const verifyImage = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = e.target?.result as string;
      };
      reader.onerror = () => resolve(false);
      reader.readAsDataURL(file);
    });
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        console.log('Original file:', {
          type: file.type,
          size: file.size,
          name: file.name
        });

        // Create a local preview immediately
        const localPreview = URL.createObjectURL(file);
        setImageUrl(localPreview);
        
        // Verify the image is valid before proceeding
        const isValid = await verifyImage(file);
        if (!isValid) {
          throw new Error('Invalid image file');
        }

        const compressedFile = await compressImage(file);
        const fileExt = file.name.split('.').pop()?.toLowerCase() || 'jpg';
        const fileName = `${generateUniqueId()}.${fileExt}`;

        if (!['jpg', 'jpeg', 'png', 'gif'].includes(fileExt)) {
          throw new Error('Unsupported file type. Please use JPG, PNG, or GIF.');
        }

        // Convert the compressed file to an array buffer
        const arrayBuffer = await compressedFile.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);

        console.log('Uploading file:', {
          name: fileName,
          type: `image/${fileExt === 'jpg' ? 'jpeg' : fileExt}`,
          size: uint8Array.length
        });

        const { data, error } = await supabase.storage
          .from('logos')
          .upload(fileName, uint8Array, {
            cacheControl: '3600',
            upsert: true,
            contentType: `image/${fileExt === 'jpg' ? 'jpeg' : fileExt}`
          });

        if (error) {
          console.error('Supabase upload error:', error);
          throw error;
        }

        console.log('Upload response:', data);

        // Get the public URL with a timestamp to prevent caching
        const timestamp = Date.now();
        const { data: { publicUrl } } = supabase.storage
          .from('logos')
          .getPublicUrl(`${data.path}?t=${timestamp}`);

        console.log('Generated public URL:', publicUrl);

        // Verify the uploaded file is accessible
        const response = await fetch(publicUrl);
        if (!response.ok) {
          throw new Error(`Failed to verify upload: ${response.status} ${response.statusText}`);
        }

        const contentLength = response.headers.get('content-length');
        console.log('Uploaded file size:', contentLength);

        // Clean up the local preview
        URL.revokeObjectURL(localPreview);
        
        // Update the image URL and notify parent
        setImageUrl(publicUrl);
        onUpload(publicUrl);
      } catch (error) {
        console.error('Error uploading logo:', error);
        throw error;
      }
    }
  };

  const handleRemove = async () => {
    if (imageUrl) {
      try {
        const fileName = imageUrl.split('/').pop();
        if (fileName) {
          await onRemove(fileName);
          setImageUrl(null);
        }
      } catch (error) {
        console.error('Error removing logo:', error);
      }
    } else {
      await onRemove(null);
    }
  };

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.error('Error loading image:', imageUrl);
    e.currentTarget.style.display = 'none';
  };

  return (
    <UploaderContainer>
      {imageUrl && (
        <ImagePreviewContainer>
          <HoverContainer>
            <ImagePreview 
              src={imageUrl} 
              alt="Uploaded logo"
              onError={handleImageError}
              crossOrigin="anonymous"
              loading="eager"
            />
            <DeleteButton onClick={handleRemove}>
              <Close8 />
            </DeleteButton>
          </HoverContainer>
        </ImagePreviewContainer>
      )}
      <Button 
        size="medium" 
        buttonType="outline" 
        onClick={handleButtonClick}
      >
        {imageUrl ? 'Replace' : 'Upload Logo'}
      </Button>
      <HiddenInput
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        accept="image/*"
      />
    </UploaderContainer>
  );
};

export default ImageUploader;