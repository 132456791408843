import React, { useState, useEffect, useCallback, useRef, useMemo, memo } from "react";
import { useParams, useNavigate, Link } from '@tanstack/react-router';
import { clientRoute } from './router';
import { supabase } from "./supabaseClient";
import { Client, Project, Expense, TimeEntry, InvoiceData, ExpenseInvoiceItem } from "./types";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { useCreateInvoiceMutation } from "./hooks/useCreateInvoiceMutation";
import NotesEditor from "./components/NotesEditor";
import Button from "./components/Button";
import { debounce } from "lodash";
import { useOrganization } from "./hooks/useOrganization";
import { formatCurrency } from './utils/formatCurrency';
import AddExpenseDrawer from './components/Expenses/AddExpenseDrawer';
import { useExpenseMutations } from './hooks/useExpenseMutations';
import Tabs from "./components/Tabs";
import InvoiceList from './components/InvoiceList';
import ExpenseList from './components/ExpenseList';
import { useTimeTracking } from './hooks/useTimeTracking';
import AddTimeEntryDrawer from "./components/Time/AddTimeEntryDrawer";
import EmptyStateCard from './components/EmptyStateCard';
import EntityPicker from "./components/EntityPicker";
import { formatDate } from './utils/dateUtils';
import InvoiceDrawer from './components/Invoices/InvoiceDrawer';
import InvoiceDetails from './components/Invoices/InvoiceDetails';
import { usePageContext } from './hooks/usePageContext';
import AddClientDrawer from './components/Clients/AddClientDrawer';
import SummaryCards from './components/SummaryCards';
import { formatDuration } from './utils/timeUtils';
import { SortingState, OnChangeFn, Row } from '@tanstack/react-table';
import { useEmailTrackingData } from './hooks/useEmailTrackingData';
import TimeEntryTable from './components/Time/TimeEntryTable';
import SelectedTimeEntriesOverlay from './components/Time/SelectedTimeEntriesOverlay';
import type { GroupedTimeEntry } from './types';
import { ColumnDef } from '@tanstack/react-table';
import { useOrganizationUsers } from './hooks/useOrganizationUsers';
import { More12, Time32 } from "./components/Icon";
import { Card, CardContent, CardTitle, CardItem, CardFooter } from './components/Card';
import SelectedExpensesOverlay from './components/Expenses/SelectedExpensesOverlay';
import { isThisYear, format } from 'date-fns';
import { exportExpensesToCSV, downloadCSV } from './utils/csvExport';
import { z } from 'zod';
import { clientSchema } from './schemas/client';
import SplitButton from './components/SplitButton';
import { useCreateProjectMutation } from "./hooks/useCreateProjectMutation";
import ConfirmationModal from './components/ConfirmationModal';

const ClientPageWrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 60px);
`;

const TabContent = styled.div`
  height: 100%;
`;

const EditableTitle = styled.div`
  font-size: inherit;
  line-height: inherit;
  font-family: 'PP Mori', sans-serif;
  font-weight: 600;
  border: none;
  background: transparent;
  padding: 0 8px;
  border-radius: 8px;
  white-space: pre;
  display: inline-block;
  min-width: 1px;
  color: rgba(0, 0, 0, 0.8);

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:empty:before {
    content: attr(data-placeholder);
    color: rgba(0, 0, 0, 0.5);
  }
`;

const EditableTitleWrapper = styled.div`
  display: inline-block;
  position: relative;
  min-width: 16px;
  max-width: 100%;
  margin-left: -8px;
`;

const PageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 32px 48px;
`;

const StatusWrapper = styled.div`
  margin-right: auto;
`;

// Wrap components that don't need frequent updates with React.memo
const MemoizedInvoiceList = React.memo(InvoiceList);
const MemoizedExpenseList = React.memo(ExpenseList);

const ClientDetailsContainer = styled.div`
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
  min-width: 400px;
  flex-shrink: 0;
`;

const NotesContainer = styled.div`
  flex: 1;
  min-width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const GridSection = styled.div`
  display: grid;
  row-gap: 16px;
  column-gap: 24px;
  padding: 32px 40px;
  grid-template-columns: max-content 1fr;
`;

const DetailLabel = styled.span`
  font-weight: 500;
  color: rgba(0,0,0,0.5);
  font-size: 14px;
`;

const DetailValue = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
`;

const AddressValue = styled(DetailValue)`
  white-space: pre-line;
`;

const ClientOverviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const ClientDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px 0 40px;
  width: 100%;
  box-sizing: border-box;
`;

const ClientDetailsTitle = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

const ClientDetails = memo(({ client, onEdit, errors }: { 
  client: Client; 
  onEdit: () => void; 
  errors: Record<string, string>;
}) => {
  const formatAddress = () => {
    const parts = [
      client.address_line1,
      client.address_line2,
      client.city && client.state
        ? `${client.city}, ${client.state}`
        : client.city || client.state,
      client.postal_code,
      client.country
    ].filter(Boolean);
    return parts.join('\n');
  };

  return (
    <ClientDetailsContainer>
      <ClientDetailsHeader>
        <ClientDetailsTitle>Details</ClientDetailsTitle>
        <Button 
          buttonType="secondary" 
          onClick={onEdit}
          size="small"
        >
          Edit
        </Button>
      </ClientDetailsHeader>
      <GridSection>
        <DetailLabel>Full Name</DetailLabel>
        <DetailValue>
          {client.full_name}
          {errors.full_name && <ErrorMessage>{errors.full_name}</ErrorMessage>}
        </DetailValue>

        <DetailLabel>Email</DetailLabel>
        <DetailValue>
          {client.email || 'N/A'}
          {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
        </DetailValue>

        <DetailLabel>Phone</DetailLabel>
        <DetailValue>
          {client.phone || 'N/A'}
          {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
        </DetailValue>

        <DetailLabel>Company</DetailLabel>
        <DetailValue>
          {client.company || 'N/A'}
          {errors.company && <ErrorMessage>{errors.company}</ErrorMessage>}
        </DetailValue>

        <DetailLabel>Address</DetailLabel>
        <AddressValue>
          {formatAddress() || 'N/A'}
          {errors.address_line1 && <ErrorMessage>{errors.address_line1}</ErrorMessage>}
        </AddressValue>

        <DetailLabel>Default Currency</DetailLabel>
        <DetailValue>
          {client.default_currency}
          {errors.default_currency && <ErrorMessage>{errors.default_currency}</ErrorMessage>}
        </DetailValue>

        <DetailLabel>Status</DetailLabel>
        <DetailValue>{client.is_active ? 'Active' : 'Inactive'}</DetailValue>
      </GridSection>
    </ClientDetailsContainer>
  );
});

const ClientPage: React.FC = () => {
  const { id } = useParams({ from: clientRoute.id });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [localClient, setLocalClient] = useState<Client | null>(null);
  const [localChanges, setLocalChanges] = useState<Partial<Client>>({});
  const createInvoiceMutation = useCreateInvoiceMutation();
  const { data: organizationId } = useOrganization();
  const [activeTab, setActiveTab] = useState("overview");
  const [isTitleFocused, setIsTitleFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isTimeEntryDrawerOpen, setIsTimeEntryDrawerOpen] = useState(false);
  const [selectedTimeEntryId, setSelectedTimeEntryId] = useState<string | null>(null);
  const { stopTimer, pauseTimer, resumeTimer, currentTimeEntryId } = useTimeTracking();
  const [isInvoiceDrawerOpen, setIsInvoiceDrawerOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceData | null>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const { setPageHeaderProps } = usePageContext();
  const isEditingRef = useRef(false);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [expenseSorting, setExpenseSorting] = useState<SortingState>([
    { id: 'date', desc: true }
  ]);
  const [invoiceSorting, setInvoiceSorting] = useState<SortingState>([
    { id: 'invoice_date', desc: true }
  ]);
  const { updateExpenseMutation, createExpenseMutation, deleteExpenseMutation } = useExpenseMutations();
  const [selectedExpenses, setSelectedExpenses] = useState<string[]>([]);
  const [isExpenseDrawerOpen, setIsExpenseDrawerOpen] = useState(false);
  const [editingExpense, setEditingExpense] = useState<Expense | null>(null);
  const isInitialMount = useRef(true);
  const initializedFromCache = useRef(false);
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'start_time', desc: true }
  ]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const createProjectMutation = useCreateProjectMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleSortingChange: OnChangeFn<SortingState> = useCallback((updaterOrValue) => {
    setSorting(updaterOrValue);
  }, []);

  const { data: timeEntries, isLoading: isLoadingTimeEntries } = useQuery<TimeEntry[]>({
    queryKey: ['clientTimeEntries', id, organizationId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('time_entries')
        .select(`
          *,
          project:projects(id, name),
          time_entry_invoice_items(
            id,
            invoice_item:invoice_items(
              id,
              invoice:invoices(id)
            )
          )
        `)
        .eq('client_id', id)
        .eq('organization_id', organizationId)
        .order('start_time', { ascending: !sorting[0]?.desc });
      if (error) throw error;
      return data;
    },
    enabled: !!id && !!organizationId,
  });

  const { data: expenses, isLoading: isLoadingExpenses } = useQuery<Expense[], Error>({
    queryKey: ["clientExpenses", id, organizationId],
    queryFn: async () => {
      if (!organizationId || !id) throw new Error("Organization or Client ID not found");
      
      const { data, error } = await supabase
        .from("expenses")
        .select(`
          *,
          client:clients(id, full_name),
          project:projects(id, name),
          expense_invoice_items(id, invoice_item_id, amount, invoice_item:invoice_items(invoice_id)),
          taxes(id, name, amount)
        `)
        .eq("client_id", id)
        .eq("organization_id", organizationId);
      if (error) throw error;
      return data as Expense[];
    },
    enabled: !!id && !!organizationId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });

  const [localExpenses, setLocalExpenses] = useState<Expense[]>(() => {
    const cachedExpenses = queryClient.getQueryData(['clientExpenses', id, organizationId]) as Expense[] | undefined;
    if (cachedExpenses) {
      initializedFromCache.current = true;
      return cachedExpenses;
    }
    return [];
  });

  useEffect(() => {
    if (expenses) {
      if (isInitialMount.current || !initializedFromCache.current) {
        setLocalExpenses(expenses);
        initializedFromCache.current = true;
      }
      isInitialMount.current = false;
    }
  }, [expenses]);

  // Calculate total expenses
  const totalExpenses = useMemo(() => 
    localExpenses?.reduce((sum, expense) => sum + expense.amount, 0) || 0,
    [localExpenses]
  );

  // Expense handlers
  const handleEditExpense = useCallback((expense: Expense) => {
    setEditingExpense(expense);
    setIsExpenseDrawerOpen(true);
  }, []);

  const handleCloseExpenseDrawer = useCallback(() => {
    setIsExpenseDrawerOpen(false);
    setEditingExpense(null);
  }, []);

  const handleOpenExpenseDrawer = useCallback(() => {
    setEditingExpense(null);
    setIsExpenseDrawerOpen(true);
  }, []);

  const handleCreateExpense = useCallback(async (expenseData: Partial<Expense>): Promise<Expense> => {
    if (!organizationId) {
      throw new Error("No organization selected. Please select an organization and try again.");
    }

    const newExpense = await createExpenseMutation.mutateAsync({
      ...expenseData,
      organization_id: organizationId,
      client_id: id,
    } as Omit<Expense, 'id'>);

    setLocalExpenses(prev => [...prev, newExpense]);
    return newExpense;
  }, [organizationId, createExpenseMutation, id]);

  const handleDeleteExpense = useCallback((expenseId: string) => {
    deleteExpenseMutation.mutate(expenseId, {
      onSuccess: () => {
        setLocalExpenses(prev => prev.filter(expense => expense.id !== expenseId));
        setIsExpenseDrawerOpen(false);
        setEditingExpense(null);
      },
      onError: (error) => {
        console.error('Failed to delete expense:', error);
        alert('Failed to delete expense. Please try again.');
      },
    });
  }, [deleteExpenseMutation]);

  const handleSaveExpense = useCallback((updatedExpense: Expense) => {
    if (!organizationId) {
      console.error('No organization ID found');
      return;
    }

    updateExpenseMutation.mutate(updatedExpense, {
      onSuccess: (savedExpense) => {
        setLocalExpenses(prev => prev.map(expense => 
          expense.id === savedExpense.id ? savedExpense : expense
        ));
      },
      onError: (error) => {
        console.error('Failed to save expense:', error);
        alert('Failed to save expense. Please try again.');
      },
    });
  }, [organizationId, updateExpenseMutation]);

  const updateLocalExpense = useCallback((updatedExpense: Partial<Expense>) => {
    setLocalExpenses(prev =>
      prev.map(expense =>
        expense.id === updatedExpense.id ? { ...expense, ...updatedExpense } : expense
      )
    );
  }, []);

  const handleBulkDeleteExpenses = useCallback(async () => {
    if (selectedExpenses.length === 0) return;

    if (window.confirm(`Are you sure you want to delete ${selectedExpenses.length} expense(s)?`)) {
      try {
        await Promise.all(selectedExpenses.map(id => handleDeleteExpense(id)));
        setSelectedExpenses([]);
        setLocalExpenses(prev => prev.filter(expense => !selectedExpenses.includes(expense.id)));
      } catch (error) {
        console.error('Failed to delete expenses:', error);
        alert('Failed to delete expenses. Please try again.');
      }
    }
  }, [selectedExpenses, handleDeleteExpense]);

  const handleExportExpenses = useCallback(() => {
    if (!selectedExpenses.length || !localExpenses) return;
    
    const selectedExpenseData = localExpenses.filter(expense => 
      selectedExpenses.includes(expense.id)
    );
    
    const csvContent = exportExpensesToCSV(selectedExpenseData);
    downloadCSV(csvContent, `expenses-${format(new Date(), 'yyyy-MM-dd')}.csv`);
  }, [selectedExpenses, localExpenses]);

  const handleAddNewTimeEntry = useCallback(() => {
    setSelectedTimeEntryId(null);
    setIsTimeEntryDrawerOpen(true);
  }, []);

  const handleSaveTimeEntry = useCallback(async () => {
    console.log('Saving time entry');
    await stopTimer();
    queryClient.invalidateQueries({ queryKey: ['clientTimeEntries', id, organizationId] });
    setIsTimeEntryDrawerOpen(false);
    setSelectedTimeEntryId(null);
  }, [queryClient, id, organizationId, stopTimer]);

  const handleDeleteTimeEntry = useCallback(async (timeEntryId: string) => {
    try {
      await supabase.from('time_entries').delete().eq('id', timeEntryId);
      queryClient.invalidateQueries({ queryKey: ['clientTimeEntries', id, organizationId] });
    } catch (error) {
      console.error('Error deleting time entry:', error);
      alert('Failed to delete time entry. Please try again.');
    }
  }, [queryClient, id, organizationId]);

  const handleCreateTimeEntry = useCallback(async (timeEntryData: Partial<TimeEntry>): Promise<TimeEntry> => {
    if (!organizationId) {
      throw new Error("No organization ID found");
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error("No user found. Please log in and try again.");
        throw new Error("No user found");
      }

      const { data, error } = await supabase
        .from('time_entries')
        .insert({
          ...timeEntryData,
          client_id: id,
          organization_id: organizationId,
          user_id: user.id
        })
        .select(`
          *,
          project:projects(id, name),
          time_entry_invoice_items(
            id,
            invoice_item:invoice_items(
              id,
              invoice:invoices(id)
            )
          )
        `)
        .single();

      if (error) throw error;

      // Update the local state with the new entry while maintaining sort order
      if (timeEntries) {
        const newEntries = [...timeEntries];
        const newEntryTime = new Date(data.start_time).getTime();
        
        const insertIndex = newEntries.findIndex(entry => {
          const entryTime = new Date(entry.start_time).getTime();
          if (sorting[0]?.desc) {
            return newEntryTime > entryTime;
          } else {
            return newEntryTime < entryTime;
          }
        });

        if (insertIndex === -1) {
          if (sorting[0]?.desc) {
            newEntries.unshift(data);
          } else {
            newEntries.push(data);
          }
        } else {
          newEntries.splice(insertIndex, 0, data);
        }

        queryClient.setQueryData(['clientTimeEntries', id, organizationId], newEntries);
      }

      return data;
    } catch (error) {
      console.error('Error creating time entry:', error);
      throw error;
    }
  }, [id, organizationId, queryClient, timeEntries, sorting]);

  const handleProjectStatusChange = useCallback(
    (projectId: string, newStatus: string) => {
      if (!id) return;
      supabase
        .from("projects")
        .update({ status: newStatus })
        .eq("id", projectId)
        .then(({ error }) => {
          if (error) {
            console.error('Error updating project status:', error);
          } else {
            queryClient.invalidateQueries({ queryKey: ["clientProjects", id] });
          }
        });
    },
    [id, queryClient]
  );
  

  const { data: client } = useQuery<Client, Error>({
    queryKey: ["client", id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("clients")
        .select("*")
        .eq("id", id)
        .single();
      if (error) throw error;
      return data as Client;
    },
    initialData: () => {
      const clients = queryClient.getQueryData<Client[]>(["clients"]);
      return clients?.find((c) => c.id === id);
    },
    staleTime: 0, // Set to 0 to always refetch on mount
  });

  // Add effect to check organization access and redirect if needed
  useEffect(() => {
    // If there's no organization ID, redirect to home
    if (!organizationId) {
      console.log('No organization ID found');
      navigate({ to: '/' });
      return;
    }

    // If client is loaded and doesn't belong to user's organization, redirect
    if (client && client.organization_id !== organizationId) {
      console.log('User does not have access to this client');
      navigate({ to: '/clients' });
      return;
    }
  }, [client, organizationId, navigate]);

  const { data: invoices, isLoading: isLoadingInvoices } = useQuery<
    InvoiceData[],
    Error
  >({
    queryKey: ["clientInvoices", id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("invoices")
        .select("*")
        .eq("client_id", id);
      if (error) throw error;
      return data as InvoiceData[];
    },
    staleTime: 1000,
  });

  const { data: projects } = useQuery<Project[], Error>({
    queryKey: ["clientProjects", id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("projects")
        .select("*")
        .eq("client_id", id);
      if (error) throw error;
      return data as Project[];
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });

  const updateClientMutation = useMutation({
    mutationFn: async (updatedClient: Partial<Client>) => {
      const { data, error } = await supabase
        .from("clients")
        .update(updatedClient)
        .eq("id", id)
        .select();
      if (error) {
        console.error('Supabase update error:', error);
        throw error;
      }
      return data[0] as Client;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(["client", id], (oldData: Client | undefined) => {
        return oldData ? { ...oldData, ...data } : data;
      });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
    onError: (error) => {
      console.error('Mutation error:', error);
    },
  });

  const deleteClientMutation = useMutation({
    mutationFn: async () => {
      const { error } = await supabase
        .from("clients")
        .delete()
        .eq("id", id);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      navigate({ to: "/clients" });
    },
  });

  const handleDeleteClient = useCallback(() => {
    if (!localClient) return;
    setIsDeleteModalOpen(true);
  }, [localClient]);

  const handleConfirmDelete = useCallback(() => {
    deleteClientMutation.mutate();
    setIsDeleteModalOpen(false);
  }, [deleteClientMutation]);

  const handleEditClient = useCallback(() => {
    setIsEditDrawerOpen(true);
  }, []);

  const moreOptions = useMemo(() => [
    { id: 'edit', name: 'Edit Client' },
    { id: 'delete', name: 'Delete Client' },
  ], []);

  const handleMoreOptionSelect = useCallback((optionId: string | null) => {
    if (optionId === 'delete') {
      handleDeleteClient();
    } else if (optionId === 'edit') {
      handleEditClient();
    }
  }, [handleDeleteClient, handleEditClient]);

  const debouncedUpdateClient = useRef(
    debounce(
      (id: string, changes: Partial<Client>, isEditing: boolean) => {
        updateClientMutation.mutate({ id, ...changes }, {
          onSettled: () => {
            if (!isEditing) {
              isEditingRef.current = false;
            }
          },
        });
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const validateClient = (clientData: Partial<Client>) => {
    try {
      clientSchema.parse(clientData);
      setErrors({});
      return true;
    } catch (error) {
      if (error instanceof z.ZodError) {
        const newErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          const field = err.path[0];
          if (typeof field === 'string') {
            newErrors[field] = err.message;
          }
        });
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleInputChange = (field: keyof Client, value: string | boolean) => {
    isEditingRef.current = true;
    const updatedClient = { ...localClient!, [field]: value };
    setLocalClient(updatedClient);

    // Validate the updated client data
    validateClient(updatedClient);

    if (localClient?.id) {
      debouncedUpdateClient(localClient.id, { [field]: value }, isEditingRef.current);
    }
  };

  const handleNotesUpdate = (content: string) => {
    handleInputChange("notes", content);
  };

  // Use useMemo for expensive computations
  const totalDue = useMemo(() => 
    invoices?.reduce((sum, invoice) => sum + invoice.amount_due, 0) || 0,
    [invoices]
  );

  // Use useCallback for function references
  const handleNavigateToProjects = useCallback(() => {
    if (!organizationId) {
      console.error('No organization ID found');
      return;
    }

    createProjectMutation.mutate({
      client_id: id,
      organization_id: organizationId,
    }, {
      onSuccess: (project) => {
        navigate({ to: '/project/$id', params: { id: project.id } });
      },
      onError: (error) => {
        console.error('Error creating project:', error);
      }
    });
  }, [createProjectMutation, id, organizationId, navigate]);

  const handleCreateInvoice = useCallback(async () => {
    if (!organizationId) {
      console.error('No organization ID found');
      return;
    }
    if (!id) {
      console.error('No client ID found');
      return;
    }
    try {
      const newInvoice = await createInvoiceMutation.mutateAsync({
        client_id: id,
        organization_id: organizationId,
      });
      console.log("New invoice created:", newInvoice);
      navigate({ to: "/invoice/$id", params: { id: newInvoice.id } });
    } catch (error) {
      console.error("Error creating invoice:", error);
    }
  }, [createInvoiceMutation, organizationId, id, navigate]);

  const handleInvoiceRowClick = useCallback((invoice: InvoiceData) => {
    setSelectedInvoice(invoice);
    setIsInvoiceDrawerOpen(true);
  }, []);

  const handleCloseInvoiceDrawer = useCallback(() => {
    if (!isAnyPopoverOpen) {
      setIsInvoiceDrawerOpen(false);
      setSelectedInvoice(null);
    }
  }, [isAnyPopoverOpen]);

  const handleOverlayClick = useCallback((event: React.MouseEvent) => {
    if (!isAnyPopoverOpen && (event.target as HTMLElement).classList.contains('drawer-overlay')) {
      handleCloseInvoiceDrawer();
    }
  }, [handleCloseInvoiceDrawer, isAnyPopoverOpen]);

  const handleTitleChange = useCallback(() => {
    if (titleRef.current && localClient?.id) {
      const newFullName = titleRef.current.textContent || '';
      setLocalClient(prev => prev ? { ...prev, full_name: newFullName } : null);
      debouncedUpdateClient(localClient.id, { full_name: newFullName }, isEditingRef.current);
    }
  }, [localClient, debouncedUpdateClient]);

  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (titleRef.current && localClient) {
      titleRef.current.textContent = localClient.full_name;
    }
  }, [localClient]);

  const handleClientUpdated = useCallback((updatedClient: Client) => {
    // Validate the updated client data
    if (!validateClient(updatedClient)) {
      return;
    }

    queryClient.setQueryData(["client", id], updatedClient);
    queryClient.invalidateQueries({ queryKey: ["clients"] });
    setIsEditDrawerOpen(false);
    setLocalClient(updatedClient);
    if (titleRef.current) {
      titleRef.current.textContent = updatedClient.full_name;
    }
    queryClient.refetchQueries({ queryKey: ["client", id] });
  }, [queryClient, id]);

  useEffect(() => {
    setPageHeaderProps({
      parentPath: "/clients",
      parentName: "Clients",
      title: (
        <EditableTitleWrapper>
          <EditableTitle
            ref={titleRef}
            contentEditable
            suppressContentEditableWarning
            onInput={handleTitleChange}
            onBlur={handleTitleChange}
            data-placeholder="Enter client name"
            autoFocus={isTitleFocused}
            onFocus={() => setIsTitleFocused(true)}
          />
        </EditableTitleWrapper>
      ),
      right: (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <SplitButton
            primaryOption={{
              id: 'new-invoice',
              name: 'New Invoice'
            }}
            options={[
              { id: 'new-time-entry', name: 'New Time Entry' },
              { id: 'new-expense', name: 'New Expense' }
            ]}
            onOptionSelect={(optionId) => {
              switch (optionId) {
                case 'new-invoice':
                  handleCreateInvoice();
                  break;
                case 'new-time-entry':
                  handleAddNewTimeEntry();
                  break;
                case 'new-expense':
                  handleOpenExpenseDrawer();
                  break;
              }
            }}
          />
          <EntityPicker
            selectedId={null}
            onChange={handleMoreOptionSelect}
            entities={moreOptions}
            label="More"
            icon={<More12 />}
            placement="bottom-end"
            iconOnly
            enableSearch={false}
          />
        </div>
      ),
    });
  }, [setPageHeaderProps, localClient, isTitleFocused, moreOptions]);

  useEffect(() => {
    if (client) {
      setLocalClient(prevLocal => ({
        ...client,
        ...prevLocal,
        ...localChanges
      }));
      setLocalChanges({});
    }
  }, [client]);

  useEffect(() => {
    if (client && client.full_name === "") {
      setIsTitleFocused(true);
    }
  }, [client]);

  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [localClient]);

  const updateLocalTimeEntry = useCallback((updatedEntry: Partial<TimeEntry>) => {
    console.log('Updating local time entry:', updatedEntry.id);
    if (timeEntries) {
      const updatedTimeEntries = timeEntries.map(entry =>
        entry.id === updatedEntry.id ? { ...entry, ...updatedEntry } : entry
      );
      queryClient.setQueryData(['clientTimeEntries', id, organizationId], updatedTimeEntries);
    }
  }, [timeEntries, queryClient, id, organizationId]);

  const projectStatuses = [
    { id: "not_started", name: "Not Started" },
    { id: "planning", name: "Planning" },
    { id: "in_progress", name: "In Progress" },
    { id: "on_hold", name: "On Hold" },
    { id: "completed", name: "Completed" },
  ];

  const renderSummaryCards = () => {
    const totalInvoiced = invoices?.reduce((sum, invoice) => sum + (invoice.total || 0), 0) || 0;
    const totalPaid = invoices?.reduce((sum, invoice) => sum + (invoice.total - invoice.amount_due), 0) || 0;
    const totalTime = timeEntries?.reduce((sum, entry) => sum + (entry.duration || 0), 0) || 0;

    const cards = [
      {
        title: 'Total Invoiced',
        amount: formatCurrency(totalInvoiced),
        onClick: () => setActiveTab(prevTab => prevTab === 'invoices' ? 'overview' : 'invoices'),
        isSelected: activeTab === 'invoices'
      },
      {
        title: 'Total Paid',
        amount: formatCurrency(totalPaid),
        onClick: () => setActiveTab(prevTab => prevTab === 'invoices' ? 'overview' : 'invoices'),
        isSelected: activeTab === 'invoices'
      },
      {
        title: 'Outstanding Balance',
        amount: formatCurrency(totalDue),
        onClick: () => setActiveTab(prevTab => prevTab === 'invoices' ? 'overview' : 'invoices'),
        isSelected: activeTab === 'invoices'
      },
      {
        title: 'Total Expenses',
        amount: formatCurrency(totalExpenses),
        onClick: () => setActiveTab(prevTab => prevTab === 'expenses' ? 'overview' : 'expenses'),
        isSelected: activeTab === 'expenses'
      },
      {
        title: 'Total Time',
        amount: formatDuration(totalTime),
        onClick: () => setActiveTab(prevTab => prevTab === 'time-entries' ? 'overview' : 'time-entries'),
        isSelected: activeTab === 'time-entries'
      }
    ];

    return <SummaryCards cards={cards} />;
  };

  const handleExpenseSortingChange: OnChangeFn<SortingState> = useCallback((updater) => {
    setExpenseSorting(old => (typeof updater === 'function' ? updater(old) : updater));
  }, []);

  const handleInvoiceSortingChange: OnChangeFn<SortingState> = useCallback((updater) => {
    setInvoiceSorting(old => (typeof updater === 'function' ? updater(old) : updater));
  }, []);

  // Add this hook to fetch email tracking data
  const { data: emailTrackingData } = useEmailTrackingData(selectedInvoice?.id ?? null);

  const timeEntryColumns = useMemo<ColumnDef<GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }>[]>(() => {
    console.log('Recalculating timeEntryColumns');
    return [
      {
        id: 'duration',
        accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
          if ('isGroupHeader' in row) {
            return row.totalDuration;
          }
          return row.duration;
        },
        header: 'Duration',
        cell: ({ row }: { row: Row<GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }> }) => {
          const value = row.getValue('duration') as number;
          return formatDuration(value);
        },
      },
      {
        id: 'description',
        accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
          if ('isGroupHeader' in row) {
            return null;
          }
          return row.description;
        },
        header: 'Description',
      },
      {
        id: 'project',
        accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
          if ('isGroupHeader' in row) {
            return null;
          }
          return row.project?.name || '';
        },
        header: 'Project',
      },
      {
        id: 'user',
        accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
          if ('isGroupHeader' in row) {
            return null;
          }
          return row.user_full_name || '';
        },
        header: 'User',
      },
      {
        id: 'invoice',
        accessorFn: (row: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number; }) => {
          if ('isGroupHeader' in row) {
            return null;
          }
          return row.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id || null;
        },
        header: 'Invoice',
        cell: ({ getValue }: { getValue: () => unknown }) => {
          const invoiceId = getValue() as string | null;
          if (!invoiceId) return 'Not Invoiced';
          return (
            <Link 
              to="/invoice/$id" 
              params={{ id: invoiceId }}
              search={{ from: '/clients' }}
            >
              View Invoice
            </Link>
          );
        },
      },
    ];
  }, []);

  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const [selectedEntriesCount, setSelectedEntriesCount] = useState(0);
  const [currentDurations, setCurrentDurations] = useState<{ [id: string]: number }>({});
  const timersRef = useRef<{ [id: string]: NodeJS.Timeout }>({});

  useEffect(() => {
    // Clear any existing timers
    Object.values(timersRef.current).forEach((timer) => clearInterval(timer));
    timersRef.current = {};

    // Initialize durations for running entries
    const initialDurations: { [id: string]: number } = {};
    timeEntries?.forEach(entry => {
      if (entry.start_time && !entry.end_time) {
        initialDurations[entry.id] = Math.floor((Date.now() - new Date(entry.start_time).getTime()) / 1000);
        
        timersRef.current[entry.id] = setInterval(() => {
          setCurrentDurations(prev => ({
            ...prev,
            [entry.id]: prev[entry.id] + 1
          }));
        }, 1000);
      }
    });

    if (Object.keys(initialDurations).length > 0) {
      setCurrentDurations(initialDurations);
    }

    return () => {
      Object.values(timersRef.current).forEach((timer) => clearInterval(timer));
      timersRef.current = {};
    };
  }, [timeEntries?.map(entry => entry.id).join(',')]);

  const handleBulkClientChange = useCallback((clientId: string | null) => {
    selectedEntries.forEach(id => {
      updateLocalTimeEntry({ id, client_id: clientId });
    });
  }, [selectedEntries, updateLocalTimeEntry]);

  const handleBulkProjectChange = useCallback((projectId: string | null) => {
    selectedEntries.forEach(id => {
      updateLocalTimeEntry({ id, project_id: projectId });
    });
  }, [selectedEntries, updateLocalTimeEntry]);

  const handleBulkDelete = useCallback(() => {
    if (selectedEntries.length === 0) return;

    if (window.confirm(`Are you sure you want to delete ${selectedEntries.length} time entry(ies)?`)) {
      selectedEntries.forEach(id => handleDeleteTimeEntry(id));
      setSelectedEntries([]);
      setSelectedEntriesCount(0);
    }
  }, [selectedEntries, handleDeleteTimeEntry]);

  const { data: organizationUsers } = useOrganizationUsers();

  const timeEntryEmptyState = useMemo(() => ({
    icon: <Time32 />,
    message: 'No time entries yet',
    subMessage: 'Start tracking time for this client',
    action: {
      label: 'Add Time Entry',
      onClick: () => setIsTimeEntryDrawerOpen(true)
    }
  }), []);

  const groupTimeEntries = useMemo(() => (entries: TimeEntry[]): (GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number })[] => {
    const grouped: { [key: string]: GroupedTimeEntry[] } = {};
    
    entries?.forEach(entry => {
      const groupDate = new Date(entry.start_time).toISOString().split('T')[0];
      if (!grouped[groupDate]) {
        grouped[groupDate] = [];
      }
      const user = organizationUsers?.find(u => u.id === entry.user_id);
      grouped[groupDate].push({
        ...entry,
        groupDate,
        user_full_name: user?.full_name || 'Unknown',
        invoiceId: entry.time_entry_invoice_items?.[0]?.invoice_item?.invoice?.id || null
      });
    });

    return Object.entries(grouped).flatMap(([date, entries]) => [
      { 
        id: date, 
        isGroupHeader: true, 
        totalDuration: entries.reduce((sum, entry) => sum + entry.duration, 0) 
      },
      ...entries
    ]);
  }, [organizationUsers]);

  const groupedTimeEntries = useMemo(() => {
    console.log('Grouping time entries');
    return timeEntries ? groupTimeEntries(timeEntries) : [];
  }, [timeEntries]);

  useEffect(() => {
    console.log('Tab changed:', activeTab);
  }, [activeTab]);

  useEffect(() => {
    console.log('TimeEntries changed:', timeEntries?.length);
  }, [timeEntries]);

  useEffect(() => {
    console.log('LocalClient changed:', localClient?.id);
  }, [localClient]);

  if (activeTab === "time-entries") {
    console.log('Rendering time entries tab');
  }

  const handleTimeEntryRowClick = useCallback((entry: TimeEntry) => {
    setSelectedTimeEntryId(entry.id);
    setIsTimeEntryDrawerOpen(true);
  }, []);

  const handleEntriesUpdate = useCallback((entries: TimeEntry[]) => {
    queryClient.setQueryData(['clientTimeEntries', id, organizationId], entries);
  }, [queryClient, id, organizationId]);

  const handleSelectionChange = useCallback((selectedIds: string[], count: number) => {
    setSelectedEntries(selectedIds);
    setSelectedEntriesCount(count);
  }, []);

  const handleInvoiceClick = useCallback((invoiceId: string) => {
    navigate({ to: '/invoice/$id', params: { id: invoiceId } });
  }, [navigate]);

  const expenseColumns: ColumnDef<Expense>[] = useMemo(() => [
    {
      accessorKey: 'date',
      header: 'Date',
      cell: ({ getValue }) => {
        const expenseDate = new Date(getValue() as string);
        return format(expenseDate, isThisYear(expenseDate) ? 'MMM d' : 'MMM d, yyyy');
      },
    },
    {
      accessorKey: 'merchant',
      header: 'Merchant',
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      cell: ({ getValue }) => formatCurrency(getValue() as number),
    },
    {
      accessorKey: 'project',
      header: 'Project',
      cell: ({ getValue }) => {
        const project = getValue() as { name: string } | null;
        return project ? project.name : '-';
      },
    },
    {
      accessorKey: 'expense_invoice_items',
      header: 'Invoice',
      cell: ({ getValue }) => {
        const items = getValue() as ExpenseInvoiceItem[] | null | undefined;
        if (items && items.length > 0 && items[0].invoice_item?.invoice_id) {
          return (
            <Link 
              to="/invoice/$id" 
              params={{ id: items[0].invoice_item.invoice_id }}
              search={{ from: '/clients' }}
            >
              View Invoice
            </Link>
          );
        }
        return 'Not Invoiced';
      },
    },
  ], []);

  return (
    <ClientPageWrapper ref={containerRef}>
      {localClient && (
        <>
          {renderSummaryCards()}

          <Tabs
            tabs={[
              { id: "overview", label: "Overview" },
              { id: "invoices", label: "Invoices" },
              { id: "projects", label: "Projects" },
              { id: "expenses", label: "Expenses" },
              { id: "time-entries", label: "Time" },
            ]}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />

          <TabContent>
            {activeTab === "overview" && (
              <ClientOverviewContainer>
                <ClientDetails 
                  client={localClient} 
                  onEdit={handleEditClient}
                  errors={errors}
                />
                <NotesContainer>
                  <NotesEditor
                    initialContent={localClient.notes || ""}
                    onUpdate={handleNotesUpdate}
                    minHeight="560px"
                  />
                </NotesContainer>
              </ClientOverviewContainer>
            )}

            {activeTab === "invoices" && (
              <MemoizedInvoiceList
                invoices={invoices || []}
                isLoading={isLoadingInvoices}
                onCreateInvoice={handleCreateInvoice}
                containerRef={containerRef}
                onSelectionChange={(selectedIds: string[]) => {
                  console.log('Selected invoice IDs in ClientPage:', selectedIds);
                }}
                onRowClick={handleInvoiceRowClick}
                selectedInvoiceId={selectedInvoice?.id || null}
                sorting={invoiceSorting}
                onSortingChange={handleInvoiceSortingChange}
              />
            )}

            {activeTab === "projects" && (
              <PageGrid>
                {projects && projects.length > 0 ? (
                  projects.map((project) => (
                    <Card
                      key={project.id}
                      onClick={() => navigate({ to: '/project/$id', params: { id: project.id } })}
                    >
                      <CardContent>
                        <CardTitle>{project.name || "Untitled Project"}</CardTitle>
                        {project.description && (
                          <CardItem>{project.description}</CardItem>
                        )}
                      </CardContent>
                      <CardFooter>
                        <StatusWrapper>
                          <EntityPicker
                            selectedId={project.status || 'planning'}
                            onChange={(statusId) => handleProjectStatusChange(project.id, statusId as string)}
                            entities={projectStatuses}
                            label="Status"
                            allowUnassigned={false}
                            placement="bottom-start"
                          />
                        </StatusWrapper>
                        {project.due_date && (
                          <CardItem>Due {formatDate(project.due_date)}</CardItem>
                        )}
                      </CardFooter>
                    </Card>
                  ))
                ) : (
                  <EmptyStateCard
                    title="Create a new project"
                    description="Add your first project for this client to get started"
                    actionText="Create New Project"
                    onClick={handleNavigateToProjects}
                  />
                )}
              </PageGrid>
            )}

            {activeTab === "expenses" && (
              <MemoizedExpenseList
                expenses={localExpenses}
                isLoading={isLoadingExpenses}
                onCreateExpense={handleOpenExpenseDrawer}
                onEditExpense={handleEditExpense}
                containerRef={containerRef}
                onSelectionChange={setSelectedExpenses}
                sorting={expenseSorting}
                onSortingChange={handleExpenseSortingChange}
                columns={expenseColumns}
              />
            )}

            {activeTab === "time-entries" && (
              <TimeEntryTable
                timeEntries={groupedTimeEntries}
                columns={timeEntryColumns}
                isLoading={isLoadingTimeEntries}
                isInitialLoading={isLoadingTimeEntries}
                onRowClick={handleTimeEntryRowClick}
                onImmediateUpdate={updateLocalTimeEntry}
                getRowKey={(entry) => entry.id}
                clients={[]}
                projects={projects || []}
                organizationUsers={organizationUsers || []}
                onEntriesUpdate={handleEntriesUpdate}
                containerRef={containerRef}
                emptyState={timeEntryEmptyState}
                onDeleteEntry={handleDeleteTimeEntry}
                onSelectionChange={handleSelectionChange}
                onInvoiceClick={handleInvoiceClick}
                hasNextPage={false}
                isFetchingNextPage={false}
                fetchNextPage={() => {}}
                timeFormat="decimal"
                currentDurations={currentDurations}
                sorting={sorting}
                onSortingChange={handleSortingChange}
              />
            )}
          </TabContent>
        </>
      )}
      <AddExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        setIsOpen={handleCloseExpenseDrawer}
        expense={editingExpense}
        onSave={handleSaveExpense}
        onDelete={handleDeleteExpense}
        onCreate={handleCreateExpense}
        organizationId={organizationId}
        updateLocalExpense={updateLocalExpense}
      />
      <AddTimeEntryDrawer
        isOpen={isTimeEntryDrawerOpen}
        setIsOpen={setIsTimeEntryDrawerOpen}
        timeEntry={timeEntries?.find(entry => entry.id === selectedTimeEntryId) || null}
        onSave={handleSaveTimeEntry}
        onDelete={() => selectedTimeEntryId && handleDeleteTimeEntry(selectedTimeEntryId)}
        onCreate={handleCreateTimeEntry}
        organizationId={organizationId}
        defaultClientId={id}
        onOverlayClick={() => setIsTimeEntryDrawerOpen(false)}
        stopTimer={stopTimer}
        updateLocalTimeEntry={updateLocalTimeEntry}
        currentTimeEntryId={currentTimeEntryId}
        pauseTimer={pauseTimer}
        resumeTimer={resumeTimer}
      />
      <InvoiceDrawer
        isOpen={isInvoiceDrawerOpen}
        setIsOpen={setIsInvoiceDrawerOpen}
        selectedInvoice={selectedInvoice}
        onOverlayClick={handleOverlayClick}
        onPopoverOpenChange={setIsAnyPopoverOpen}
        title="Invoice Details"
      >
        {selectedInvoice && (
          <InvoiceDetails
            key={selectedInvoice.id}
            invoice={selectedInvoice}
            clients={[]}
            navigate={navigate}
            queryClient={queryClient}
            payments={[]}
            emailTrackingData={emailTrackingData} // Add this line
          />
        )}
      </InvoiceDrawer>
      <AddClientDrawer
        isOpen={isEditDrawerOpen}
        setIsOpen={setIsEditDrawerOpen}
        onClientAdded={handleClientUpdated}
        editingClient={localClient}
        organizationId={organizationId || ''}
      />
      <SelectedTimeEntriesOverlay
        selectedCount={selectedEntriesCount}
        clients={[]}
        projects={projects || []}
        onClientChange={handleBulkClientChange}
        onProjectChange={handleBulkProjectChange}
        onDelete={handleBulkDelete}
        onClearSelection={() => {
          setSelectedEntries([]);
          setSelectedEntriesCount(0);
        }}
        onCreateInvoice={handleCreateInvoice}
      />
      <SelectedExpensesOverlay
        selectedCount={selectedExpenses.length}
        onDelete={handleBulkDeleteExpenses}
        onClearSelection={() => setSelectedExpenses([])}
        onExportCSV={handleExportExpenses}
      />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title="Delete Client"
        message={`Are you sure you want to delete ${localClient?.full_name || 'this client'}? This action cannot be undone.`}
        confirmLabel="Delete"
        onConfirm={handleConfirmDelete}
        onCancel={() => setIsDeleteModalOpen(false)}
        isDestructive
      />
    </ClientPageWrapper>
  );
};

export default ClientPage;