import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { InvoiceTemplatesProps } from './types';
import { ClientSelector } from '../components/Clients/ClientSelector';
import DatePicker from '../components/DatePicker';
import { addDays } from 'date-fns';
import { calculateInvoiceTotals } from '../utils/invoiceCalculations';
import { InvoiceData } from '../types';
import AnimatedAmount from '../components/AnimatedAmount';
import { Organization, Client } from '../types';
import { getContrastColor } from '../utils/contrast';

const FontWrapper = styled.div<{ $font: string }>`
  font-family: ${props => props.$font};
`;

const HeaderContainer = styled.div.attrs<{ $headerColor: string, $headerTextColor: string }>(props => ({
  style: {
    backgroundColor: props.$headerColor || '#f8f8f8',
  },
}))`
  display: grid;
  grid-template-columns: 80px 1fr 1fr 1fr 80px;
  grid-template-rows: 120px 1fr;
  color: ${props => props.$headerTextColor || '#000'};

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 24px;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: ${props => `${props.$headerTextColor}25`};
    }
  }
`;

const Input = styled.input<{ $headerColor?: string }>`
  border: none;
  font-family: inherit;
  background: transparent;
  outline: none;
  padding: 4px 8px;
  margin: 0;
  font-size: inherit;
  color: inherit;
  width: ${props => props.width || 'auto'};
  min-width: 60px;
  border-radius: 8px;
  &::placeholder {
    color: inherit;
  }
  &:hover {
    background: ${props => props.$headerColor ? getContrastColor(props.$headerColor) : 'rgba(0, 0, 0, 0.05)'};
  }

  @media (max-width: 768px) {
    width: auto;
    flex: 1;
  }
`;

const InputWrapper = styled.div<{ $headerColor: string; $isGenerating?: boolean }>`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  display: inline-block;
  
  ${props => {
    return props.$isGenerating && `
      //background: rgba(0, 0, 0, 0.05);
      
      &::after {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 1;
        background: linear-gradient(
          90deg,
          transparent 0%,
          ${props.$headerColor} 50%,
          transparent 100%
        );
        animation: shimmer 1.5s infinite linear;
        pointer-events: none;
      }

      @keyframes shimmer {
        from {
          transform: translateX(-200%);
        }
        to {
          transform: translateX(200%);
        }
      }
    `
  }}
`;

const HiddenInput = styled.span`
  position: absolute;
  visibility: hidden;
  white-space: pre;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 4px 8px;
`;

const Logo = styled.img`
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;

  @media (max-width: 768px) {
    max-width: 80px;
    max-height: 80px;
    margin: 16px 0;
  }
`;

const TopLeft = styled.div<{ $headerTextColor: string }>`
  border-right: 1px solid ${props => `${props.$headerTextColor}25`};
  border-bottom: 1px solid ${props => `${props.$headerTextColor}25`};
  font-size: 14px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TopRow = styled.div<{ $headerTextColor: string; $isEmpty?: boolean }>`
  border-right: 1px solid ${props => `${props.$headerTextColor}25`};
  border-bottom: 1px solid ${props => `${props.$headerTextColor}25`};
  display: flex;
  align-items: flex-end;
  padding: 4px;
  font-size: 14px;

  @media (max-width: 768px) {
    ${props => props.$isEmpty && `
      display: none;
    `}
    border-right: none;
    border-bottom: ${props => !props.$isEmpty ? `1px solid ${props.$headerTextColor}25` : 'none'};
    padding: 16px 16px 16px 48px;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
`;

const TopRight = styled.div<{ $headerTextColor: string; $isEmpty?: boolean }>`
  border-bottom: 1px solid ${props => `${props.$headerTextColor}25`};

  @media (max-width: 768px) {
    ${props => props.$isEmpty && `
      display: none;
    `}
    border: none;
  }
`;

const Row = styled.div<{ $headerTextColor: string; $isEmpty?: boolean }>`
  border-bottom: 1px solid ${props => `${props.$headerTextColor}25`};
  border-right: 1px solid ${props => `${props.$headerTextColor}25`};
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px;
  font-size: 14px;

  @media (max-width: 768px) {
    ${props => props.$isEmpty && `
      display: none;
    `}
    border-right: none;
    border-bottom: ${props => !props.$isEmpty ? `1px solid ${props.$headerTextColor}25` : 'none'};
    padding: 16px 16px 16px 48px;
    height: auto;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
`;

const SelectorRow = styled(Row)`
  align-items: flex-end;
  height: auto;
  padding: 48px 16px 8px 16px;

  @media (max-width: 768px) {
    ${props => props.$isEmpty && `
      display: none;
    `}
    padding: 16px 16px 16px 48px;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }
`;

const RowEnd = styled(Row)`
  border-right: none;

  @media (max-width: 768px) {
    ${props => props.$isEmpty && `
      display: none;
    `}
  }
`;

const SelectorRowEnd = styled(RowEnd)`
  align-items: center;
  height: auto;
  display: flex;
  align-items: flex-end;

  @media (max-width: 768px) {
    ${props => props.$isEmpty && `
      display: none;
    `}
    align-items: flex-start;
    padding: 16px;
  }
`;

const Label = styled.div`
  padding: 4px 8px;
  opacity: 0.5;

  @media (max-width: 768px) {
    padding: 4px 0;
  }
`;

const StyledAnimatedAmount = styled.div`
  padding: 4px 8px;

  @media (max-width: 768px) {
    padding: 4px 0;
  }
`;

const TopRowLogo = styled(TopRow)`
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;

  @media (max-width: 768px) {
    justify-content: flex-start;
    padding: 16px 16px 16px 48px;
  }
`;

const OrganizationAddress = styled.div<{ $headerColor?: string; $isReadOnly?: boolean }>`
  text-align: left;
  white-space: pre-line;
  padding: 4px 8px;
  font-size: 14px;
  cursor: ${props => props.$isReadOnly ? 'default' : 'pointer'};
  border-radius: 8px;
  margin-left: -8px;
  &:hover {
    background-color: ${props => !props.$isReadOnly && (props.$headerColor ? getContrastColor(props.$headerColor) : 'rgba(0, 0, 0, 0.05)')};
  }

  @media (max-width: 768px) {
    margin-left: -4px;
  }
`;
  
interface SplitHeaderProps extends Omit<InvoiceTemplatesProps, 'onCreateNewClient'> {
  organization: Organization | null;
  onOpenUpdateOrganizationDrawer: () => void;
  onCreateNewClient: (client?: Client) => void;
  isGeneratingNumber?: boolean;
}

export const SplitHeader: React.FC<SplitHeaderProps> = ({
  invoice,
  updateInvoice,
  isReadOnly = false,
  clients,
  ClientSelectorComponent = ClientSelector,
  onCreateNewClient,
  organization,
  showLogo,
  onOpenUpdateOrganizationDrawer,
  isGeneratingNumber = false,
}) => {
  const [localInvoiceNumber, setLocalInvoiceNumber] = useState(invoice.invoice_number || '');
  const [inputWidth, setInputWidth] = useState('auto');
  const hiddenInputRef = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (hiddenInputRef.current) {
      const width = hiddenInputRef.current.offsetWidth;
      setInputWidth(`${width}px`); // Add 16px for padding (8px on each side)
    }
  }, [localInvoiceNumber]);

  useEffect(() => {
    setLocalInvoiceNumber(invoice.invoice_number || '');
    updateInputWidth();
  }, [invoice.invoice_number]);

  useEffect(() => {
    updateInputWidth();
  }, [localInvoiceNumber]);

  const updateInputWidth = () => {
    if (hiddenInputRef.current) {
      const width = hiddenInputRef.current.offsetWidth;
      setInputWidth(`${width}px`); // Add 16px for padding (8px on each side)
    }
  };

  const handleInvoiceDateChange = (date: Date | null) => {
    updateInvoice('invoice_date', date ? date.toISOString() : '');
    if (date && invoice.due_days != null) {
      const newDueDate = addDays(date, invoice.due_days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDaysChange = (days: number) => {
    updateInvoice('due_days', days);
    if (invoice.invoice_date) {
      const newDueDate = addDays(new Date(invoice.invoice_date), days);
      updateInvoice('due_date', newDueDate.toISOString());
    }
  };

  const handleDueDateChange = (date: Date | null) => {
    if (date && invoice.invoice_date) {
      const invoiceDate = new Date(invoice.invoice_date);
      const diffDays = Math.round((date.getTime() - invoiceDate.getTime()) / (1000 * 60 * 60 * 24));
      handleDueDaysChange(diffDays);
      updateInvoice('due_date', date.toISOString());
    }
  };

  const handleInvoiceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalInvoiceNumber(newValue);
    updateInvoice('invoice_number', newValue);
  };

  const totals = calculateInvoiceTotals(invoice as InvoiceData);

  const getFormattedOrganizationAddress = (org: Organization) => {
    const addressParts = [
      org.business_name || org.name,
      org.address_line1,
      org.address_line2,
      [org.city, org.state, org.postal_code].filter(Boolean).join(', '),
      org.country
    ].filter(Boolean);
    return addressParts.join('\n');
  };

  const handleClientSelection = (client: Client | undefined) => {
    updateInvoice('client_id', client?.id || undefined);
  };

  return (
    <FontWrapper $font={invoice.font || 'Inter'}>
      <HeaderContainer $headerColor={invoice.header_color} $headerTextColor={invoice.header_text_color}>
        <TopLeft $headerTextColor={invoice.header_text_color}></TopLeft>
        <TopRow $headerTextColor={invoice.header_text_color}>
          <Label>Invoice</Label>
          <InputWrapper 
            $headerColor={invoice.header_color} 
            $isGenerating={isGeneratingNumber}
          >
            <Input
              type="text"
              value={localInvoiceNumber}
              onChange={handleInvoiceNumberChange}
              placeholder="Invoice #"
              readOnly={isReadOnly}
              style={{ width: inputWidth }}
              $headerColor={invoice.header_color}
            />
          </InputWrapper>
          <HiddenInput ref={hiddenInputRef}>
            {localInvoiceNumber || 'Invoice #'}
          </HiddenInput>
        </TopRow>
        <TopRow $headerTextColor={invoice.header_text_color} $isEmpty={true}></TopRow>
        <TopRowLogo $headerTextColor={invoice.header_text_color} $isEmpty={!showLogo || !invoice.logo_url}>
          {showLogo && invoice.logo_url && <Logo src={invoice.logo_url} alt="Company Logo" />}
        </TopRowLogo>
        <TopRight $headerTextColor={invoice.header_text_color} $isEmpty={true}></TopRight>
        <Row $headerTextColor={invoice.header_text_color} $isEmpty={true}></Row>
        <Row $headerTextColor={invoice.header_text_color}>
          <Label>Amount</Label>
          <StyledAnimatedAmount>
            <AnimatedAmount 
              value={totals.amountDue} 
              currency={invoice.currency || 'USD'}
            />
          </StyledAnimatedAmount>
        </Row>
        <Row $headerTextColor={invoice.header_text_color} $isEmpty={true}></Row>
        <Row $headerTextColor={invoice.header_text_color} $isEmpty={true}></Row>
        <RowEnd $headerTextColor={invoice.header_text_color} $isEmpty={true}></RowEnd>
        <Row $headerTextColor={invoice.header_text_color} $isEmpty={true}></Row>
        <Row $headerTextColor={invoice.header_text_color}>
          <Label>Due Date</Label>
          <DatePicker
            id="due-date"
            label="Due Date"
            selectedDate={invoice.due_date ? new Date(invoice.due_date) : null}
            onChange={handleDueDateChange}
            disabled={isReadOnly}
            onSetDueDate={handleDueDaysChange}
            dueDays={invoice.due_days ?? 0}
            invoiceDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
            customFontSize={true}
            backgroundColor={invoice.header_color}
            placement="bottom-start"
            isPublic={isReadOnly}
          />
        </Row>
        <Row $headerTextColor={invoice.header_text_color} $isEmpty={true}></Row>
        <Row $headerTextColor={invoice.header_text_color} $isEmpty={true}></Row>
        <RowEnd $headerTextColor={invoice.header_text_color} $isEmpty={true}></RowEnd>
        <Row $headerTextColor={invoice.header_text_color} $isEmpty={true}></Row>
        <Row $headerTextColor={invoice.header_text_color}>
          <Label>Invoice Date</Label>
          <DatePicker
            id="invoice-date"
            label="Invoice Date"
            selectedDate={invoice.invoice_date ? new Date(invoice.invoice_date) : null}
            onChange={handleInvoiceDateChange}
            disabled={isReadOnly}
            isInvoiceDate={true}
            customFontSize={true}
            backgroundColor={invoice.header_color}
            placement="bottom-start"
            isPublic={isReadOnly}
          />
        </Row>
        <Row $headerTextColor={invoice.header_text_color} $isEmpty={true}></Row>
        <Row $headerTextColor={invoice.header_text_color} $isEmpty={true}></Row>
        <RowEnd $headerTextColor={invoice.header_text_color} $isEmpty={true}></RowEnd>
        <SelectorRow $headerTextColor={invoice.header_text_color} $isEmpty={true}></SelectorRow>
        <SelectorRow $headerTextColor={invoice.header_text_color} $isEmpty={true}></SelectorRow>
        <SelectorRow $headerTextColor={invoice.header_text_color}>
          <ClientSelectorComponent
            clients={clients}
            selectedClientId={invoice.client_id || null}
            onSelectClient={handleClientSelection}
            disabled={isReadOnly}
            onCreateNewClient={onCreateNewClient}
            headerTextColor={invoice.header_text_color}
            backgroundColor={invoice.header_color}
          />
        </SelectorRow>
        <SelectorRow $headerTextColor={invoice.header_text_color} $isEmpty={!organization}>
          {organization && (
            <OrganizationAddress 
              onClick={onOpenUpdateOrganizationDrawer}
              $headerColor={invoice.header_color}
              $isReadOnly={isReadOnly}
            >
              {getFormattedOrganizationAddress(organization)}
            </OrganizationAddress>
          )}
        </SelectorRow>
        <SelectorRowEnd $headerTextColor={invoice.header_text_color} $isEmpty={true}></SelectorRowEnd>
      </HeaderContainer>
    </FontWrapper>
  );
};