import { useQuery } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { Expense } from '../types';
import { useOrganization } from './useOrganization';

export const useExpenses = (options?: {
  clientId?: string;
  projectId?: string;
}) => {
  const { data: organizationId } = useOrganization();

  return useQuery<Expense[], Error>({
    queryKey: ['expenses', organizationId, options?.clientId, options?.projectId],
    queryFn: async () => {
      if (!organizationId) return [];
      
      let query = supabase
        .from('expenses')
        .select(`
          *,
          client:clients(id, full_name),
          project:projects(id, name),
          expense_invoice_items(id, invoice_item_id, amount, invoice_item:invoice_items(invoice_id)),
          taxes(id, name, amount)
        `)
        .eq('organization_id', organizationId)
        .order('date', { ascending: false });

      if (options?.clientId) {
        query = query.eq('client_id', options.clientId);
      }

      if (options?.projectId) {
        query = query.eq('project_id', options.projectId);
      }

      const { data, error } = await query;

      if (error) throw error;
      return data;
    },
    enabled: !!organizationId,
    staleTime: 5 * 60 * 1000, // 5 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
  });
}; 