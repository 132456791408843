import { useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';
import { TaskStatus } from '../types';
import { useOrganization } from './useOrganization';

const DEFAULT_STATUSES = [
  { name: 'To Do', color: '#8E8B8A', order_number: 1, category: 'Todo' },
  { name: 'In Progress', color: '#FFA726', order_number: 2, category: 'In Progress' },
  { name: 'Done', color: '#305D50', order_number: 3, category: 'Complete' },
];

export const useTaskStatuses = () => {
  const { data: organizationId } = useOrganization();
  const queryClient = useQueryClient();

  const fetchTaskStatuses = async () => {
    if (!organizationId) {
      console.log('No organizationId available');
      return [];
    }
    console.log('Fetching task statuses for organization:', organizationId);
    try {
      let statuses;
      const { data, error } = await supabase
        .from('task_statuses')
        .select('*')
        .eq('organization_id', organizationId)
        .order('order_number', { ascending: true });

      if (error) {
        console.error('Error fetching task statuses:', error);
        throw error;
      }

      if (data.length === 0) {
        // Create default statuses if none exist
        const defaultStatuses = DEFAULT_STATUSES.map(status => ({
          ...status,
          organization_id: organizationId,
        }));

        const { data: createdStatuses, error: createError } = await supabase
          .from('task_statuses')
          .insert(defaultStatuses)
          .select();

        if (createError) {
          console.error('Error creating default task statuses:', createError);
          throw createError;
        }

        statuses = createdStatuses;
      } else {
        statuses = data;
        // Ensure there's at least one status in each category
        const categories = ['Todo', 'In Progress', 'Complete'];
        for (const category of categories) {
          if (!statuses.some(status => status.category === category)) {
            const newStatus = {
              name: category,
              color: '#000000',
              order_number: statuses.length + 1,
              organization_id: organizationId,
              category,
            };
            const { data: createdStatus, error: createError } = await supabase
              .from('task_statuses')
              .insert([newStatus])
              .select();

            if (createError) {
              console.error(`Error creating default ${category} status:`, createError);
              throw createError;
            }

            statuses.push(createdStatus[0]);
          }
        }
      }

      console.log('Fetched task statuses:', statuses);
      return statuses as TaskStatus[];
    } catch (error) {
      console.error('Error in fetchTaskStatuses:', error);
      throw error;
    }
  };

  const createTaskStatus = async (newStatus: Omit<TaskStatus, 'id'>) => {
    console.log('Attempting to create task status:', newStatus);
    const { data, error } = await supabase
      .from('task_statuses')
      .insert([newStatus])
      .select();
    
    if (error) {
      console.error('Error creating task status:', error);
      throw error;
    }
    console.log('Task status created successfully:', data);
    return data[0] as TaskStatus;
  };

  const updateTaskStatus = async (updatedStatus: TaskStatus) => {
    console.log('Updating task status:', updatedStatus);
    const { data, error } = await supabase
      .from('task_statuses')
      .update({ name: updatedStatus.name, color: updatedStatus.color, category: updatedStatus.category })
      .eq('id', updatedStatus.id)
      .select();
    
    if (error) {
      console.error('Error updating task status:', error);
      throw error;
    }
    console.log('Task status updated successfully:', data);
    return data[0] as TaskStatus;
  };

  const deleteTaskStatus = async (statusId: string) => {
    // Check if this is the last status in its category
    const { data: allStatuses } = await supabase
      .from('task_statuses')
      .select('*')
      .eq('organization_id', organizationId);

    const statusToDelete = allStatuses?.find(status => status.id === statusId);
    if (!statusToDelete) {
      throw new Error('Status not found');
    }

    const statusesInCategory = allStatuses?.filter(status => status.category === statusToDelete.category);
    if (statusesInCategory?.length === 1) {
      throw new Error(`Cannot delete the last status in the ${statusToDelete.category} category`);
    }

    const { error } = await supabase
      .from('task_statuses')
      .delete()
      .eq('id', statusId);
    if (error) throw error;
  };

  const { data: taskStatuses, isLoading, error } = useQuery({
    queryKey: ['taskStatuses', organizationId],
    queryFn: fetchTaskStatuses,
    enabled: !!organizationId,
    staleTime: 30000, // Cache for 30 seconds
    retry: 3, // Retry failed requests 3 times
  });

  const createMutation = useMutation({
    mutationFn: createTaskStatus,
    onSuccess: (newStatus) => {
      queryClient.setQueryData<TaskStatus[]>(['taskStatuses', organizationId], (old) => [...(old || []), newStatus]);
    },
  });

  const updateMutation = useMutation({
    mutationFn: updateTaskStatus,
    onSuccess: (updatedStatus) => {
      queryClient.setQueryData<TaskStatus[]>(['taskStatuses', organizationId], (old) =>
        old?.map((status) => (status.id === updatedStatus.id ? updatedStatus : status)) || []
      );
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteTaskStatus,
    onSuccess: (_, deletedId) => {
      queryClient.setQueryData<TaskStatus[]>(['taskStatuses', organizationId], (old) =>
        old?.filter((status) => status.id !== deletedId) || []
      );
    },
  });

  const getDefaultStatus = useCallback(() => {
    if (!taskStatuses || taskStatuses.length === 0) {
      console.warn('No task statuses available');
      return null;
    }

    // Find the first status in the 'Todo' category
    const todoStatus = taskStatuses.find(status => status.category === 'Todo');
    if (todoStatus) {
      return todoStatus;
    }

    // If no Todo status exists, find the status with the lowest order_number
    const sortedStatuses = [...taskStatuses].sort((a, b) => 
      (a.order_number || 0) - (b.order_number || 0)
    );
    
    return sortedStatuses[0];
  }, [taskStatuses]);

  return {
    taskStatuses: taskStatuses || [],
    isLoading,
    error,
    createTaskStatus: createMutation.mutate,
    updateTaskStatus: updateMutation.mutate,
    deleteTaskStatus: deleteMutation.mutate,
    getDefaultStatus,
  };
};
