import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Drawer } from 'vaul';
import styled from 'styled-components';
import { supabase } from "../../supabaseClient";
import { Expense, ExpenseCategory, EXPENSE_CATEGORIES } from "../../types";
import Button from "../Button";
import { useClients } from "../../hooks/useClients";
import { useProjects } from "../../hooks/useProjects";
import EntityPicker from "../EntityPicker";
import { Calendar12, Client12, Close12, Close8, Image12, Money12, Plus8, Project12 } from '../Icon';
import DatePicker from "../DatePicker";
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { formatCurrency } from '../../utils/formatters';
import { debounce } from 'lodash';
import ConfirmationModal from '../ConfirmationModal';
import { createPortal } from 'react-dom';


const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  outline: none;
  max-width: 640px;
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.2), 0 0 1px rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  background-color: #F8F7F6;
  height: 100%;
  overflow-y: scroll;
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const Form = styled.form`
  display: grid;
`;

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  font-feature-settings: 'tnum';
  color: ${props => props.value === '$ 0.00' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.8)'};

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const MerchantInput = styled(Input)`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
  font-weight: 500;
  border-radius: 8px;

  &:focus {
    outline: none;
  }
`;

const DrawerColumns = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 768px) {
    display: block;
    overflow-y: auto;
  }
`;

const DrawerLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0,0,0,0.05);
  padding: 20px;
  border-right: 1px solid rgba(0,0,0,0.05);
  max-width: 400px;
  min-width: 200px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 100%;
    min-height: 126px;
    justify-content: center;
    border-right: none;
    border-bottom: 1px solid rgba(0,0,0,0.05);
  }
`;

const DrawerRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const DrawerRightContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 10px;
`;

const ImageUploadArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  color: rgba(0,0,0,0.5);
  gap: 8px;
  font-size: 14px;

  svg path {
    fill: rgba(71, 53, 53, 0.5);
  }

  &:hover {
    color: rgba(0,0,0,0.8);
    svg path {
      fill: rgba(0,0,0,0.8);
    }
  }
`;

const EditorWrapper = styled.div`
  position: relative;
  .ProseMirror {
    min-height: 96px;
    outline: none;
    padding: 8px 40px;
    font-size: 16px;
    line-height: 20px;

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.5);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

const ExpenseDetails = styled.div`
  padding: 40px 32px 0 32px;
  display: flex;
  flex-direction: column;
`;

const AmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  align-items: flex-start;
`;

const ButtonFooter = styled.div`
  display: flex;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #F8F7F6;
  gap: 10px;
`;

const DeleteButton = styled(Button)`
  background-color: #ff4d4f;
  color: white;

  &:hover {
    background-color: #ff7875;
  }
`;

const InputLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 8px;
`;

const TaxInput = styled.input`
  font-feature-settings: 'tnum';
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  border: 0;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  text-align: right;
  color: ${props => props.value === '$ 0.00' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.8)'};

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:focus {
    outline: none;
  }

  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${InputLabel} {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
  }

  ${Input} {
    text-align: right;
    margin-left: 0;
    -moz-appearance: textfield;
    font-feature-settings: 'tnum';

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const TotalContainer = styled.div`
  padding: 16px 0;
  width: 100%;

`;

const AddTaxButton = styled(Button)`
  background: transparent;
  width: fit-content;
`;

const Divider = styled.div`
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 8px;
  box-sizing: border-box;
`;

interface AddExpenseDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  expense?: Expense | null;
  onSave: (expense: Expense) => void;
  onDelete: (expenseId: string) => void;
  organizationId?: string;
  defaultProjectId?: string;
  defaultClientId?: string;
  projectId?: string;
  onCreate: (expenseData: Partial<Expense>) => Promise<Expense>;
  updateLocalExpense: (updatedExpense: Partial<Expense>) => void;
}

const AddExpenseDrawer: React.FC<AddExpenseDrawerProps> = ({
  isOpen,
  setIsOpen,
  expense,
  onSave,
  onDelete,
  organizationId,
  onCreate,
  updateLocalExpense,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const isEditingRef = useRef(false);
  const [pendingExpense, setPendingExpense] = useState<Expense | null>(null);
  const isCreatingExpenseRef = useRef(false);
  const [cursorPositions, setCursorPositions] = useState<{ [key: string]: number }>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Add URL cache
  const urlCache = useRef<{
    [key: string]: {
      url: string;
      expiresAt: number;
    };
  }>({});

  // Add function to get cached URL
  const getCachedUrl = useCallback((path: string) => {
    const cached = urlCache.current[path];
    if (cached && cached.expiresAt > Date.now()) {
      return cached.url;
    }
    return null;
  }, []);

  // Add function to set cached URL
  const setCachedUrl = useCallback((path: string, url: string, expirySeconds: number) => {
    urlCache.current[path] = {
      url,
      expiresAt: Date.now() + (expirySeconds * 1000)
    };
  }, []);

  const [formData, setFormData] = useState<
    Omit<Expense, "id" | "created_at" | "updated_at" | "user_id" | "expense_invoice_items" | "merchant"> & {
      taxes: Array<{ name: string; amount: number; expense_id?: string }>;
      merchant: string;
    }
  >({
    date: new Date().toISOString().split("T")[0],
    amount: 0,
    description: "",
    client_id: null,
    project_id: null,
    invoice_id: null,
    category: null,
    receipt_image_path: null,
    organization_id: organizationId || null,
    merchant: "",
    taxes: [],
  });
  const formDataRef = useRef(formData);
  const [subtotal, setSubtotal] = useState<number>(0);

  // Create a properly debounced update function
  const debouncedUpdate = useRef(
    debounce(
      (id: string, changes: Partial<Expense>, isEditing: boolean) => {
        onSave({ id, ...changes } as Expense);
        if (!isEditing) {
          isEditingRef.current = false;
        }
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const createInitialExpense = useCallback(async () => {
    // Return if we're already creating an expense or if we have an expense
    if (!organizationId || pendingExpense || expense || isCreatingExpenseRef.current) return;

    try {
      isCreatingExpenseRef.current = true;

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error("No user found. Please log in and try again.");
        return;
      }

      const newExpense = await onCreate({
        ...formDataRef.current,
        user_id: user.id,
        organization_id: organizationId,
      });
      setPendingExpense(newExpense);
    } catch (error) {
      console.error("Error creating initial expense:", error);
    } finally {
      isCreatingExpenseRef.current = false;
    }
  }, [organizationId, pendingExpense, expense, onCreate]);

  // Update formDataRef when formData changes
  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);

  const handleLocalUpdate = useCallback((field: keyof Expense, value: unknown) => {
    if (expense) {
      const updateData: Partial<Expense> = {
        id: expense.id,
        organization_id: expense.organization_id,
        [field]: value
      };
      // Update local state immediately
      updateLocalExpense(updateData);
      // Debounce server update
      debouncedUpdate(expense.id, { [field]: value, organization_id: expense.organization_id }, isEditingRef.current);
    }
  }, [expense, updateLocalExpense, debouncedUpdate]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, selectionStart } = e.target;
    console.log('handleChange called with:', { name, value });

    if (name === 'amount') {
      const { value: safeNumericValue, cursorPos } = handleNumericInput(value, selectionStart);
      // Update cursor position using the shared mechanism
      setCursorPositions(prev => ({ ...prev, amount: cursorPos }));

      // Calculate subtotal by subtracting taxes from total
      const totalTaxAmount = formData.taxes.reduce((sum, tax) => sum + (tax.amount || 0), 0);
      const newSubtotal = Math.max(0, safeNumericValue - totalTaxAmount);
      setSubtotal(newSubtotal);

      // Update form data with the new amount
      const updatedFormData = { ...formData, amount: safeNumericValue };
      setFormData(updatedFormData);
      console.log('Form data updated:', updatedFormData);

      const currentExpense = expense || pendingExpense;
      if (currentExpense?.id) {
        console.log('Updating existing expense amount:', safeNumericValue);
        isEditingRef.current = true;
        const updateData: Partial<Expense> = {
          id: currentExpense.id,
          organization_id: currentExpense.organization_id,
          amount: safeNumericValue
        };
        // Update local state immediately
        updateLocalExpense(updateData);
        // Debounce server update
        debouncedUpdate(currentExpense.id, { amount: safeNumericValue, organization_id: currentExpense.organization_id }, isEditingRef.current);
      } else if (!isCreatingExpenseRef.current) {
        console.log('Creating initial expense with amount:', safeNumericValue);
        createInitialExpense();
      }
      return;
    }

    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    console.log('Form data updated:', updatedFormData);

    const currentExpense = expense || pendingExpense;
    if (currentExpense?.id) {
      isEditingRef.current = true;
      const updateData: Partial<Expense> = {
        id: currentExpense.id,
        organization_id: currentExpense.organization_id,
        [name]: value
      };
      // Update local state immediately
      updateLocalExpense(updateData);
      // Debounce server update
      debouncedUpdate(currentExpense.id, { [name]: value, organization_id: currentExpense.organization_id }, isEditingRef.current);
    } else if (!isCreatingExpenseRef.current) {
      console.log('Creating initial expense');
      createInitialExpense();
    }
  }, [expense, pendingExpense, createInitialExpense, formData, updateLocalExpense, debouncedUpdate]);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        // Limit the total content length to 500 characters
        history: {
          depth: 10,
          newGroupDelay: 100
        }
      }),
      Placeholder.configure({
        placeholder: 'Add optional description...',
      }),
    ],
    content: formData.description || '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      // Only update if within character limit
      if (html.length <= 500) {
        setFormData(prev => ({ ...prev, description: html }));
        
        const currentExpense = expense || pendingExpense;
        if (currentExpense?.id) {
          isEditingRef.current = true;
          debouncedUpdate(
            currentExpense.id,
            { description: html, organization_id: currentExpense.organization_id },
            isEditingRef.current
          );
        } else if (!isCreatingExpenseRef.current) {
          createInitialExpense();
        }
      }
    },
  });

  // Reset states when drawer is closed
  useEffect(() => {
    if (!isOpen) {
      setPendingExpense(null);
      isCreatingExpenseRef.current = false;
      setFormData({
        date: new Date().toISOString().split("T")[0],
        amount: 0,
        description: "",
        client_id: null,
        project_id: null,
        invoice_id: null,
        category: null,
        receipt_image_path: null,
        organization_id: organizationId || null,
        merchant: "",
        taxes: [],
      });
      setSubtotal(0);
      if (editor) {
        editor.commands.setContent("");
      }
      setPreviewUrl(null);
      setSelectedFile(null);
    }
  }, [isOpen, editor, organizationId]);

  const formatNumber = (value: number): string => {
    if (!value) {
      return '$ 0.00';
    }
    return '$ ' + new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const handleNumericInput = (value: string, selectionStart: number | null) => {
    // Store cursor position relative to the end of the string
    const distanceFromEnd = value.length - (selectionStart || 0);
    
    // Remove currency symbol, spaces, and commas before processing
    const cleanValue = value.replace(/[$,\s]/g, '');
    // Remove all non-numeric characters except decimal point
    const numericString = cleanValue.replace(/[^0-9.]/g, '');
    // Convert to number with 2 decimal places
    const numericValue = Math.round(parseFloat(numericString || '0') * 100) / 100;
    const safeNumericValue = isNaN(numericValue) ? 0 : Math.min(numericValue, 99999999.99);

    // Calculate new cursor position
    const formattedValue = formatNumber(safeNumericValue);
    const newCursorPos = Math.max(2, formattedValue.length - distanceFromEnd);

    return { value: safeNumericValue, cursorPos: newCursorPos };
  };

  // Add effect for handling cursor positions
  useEffect(() => {
    Object.entries(cursorPositions).forEach(([inputName, position]) => {
      const input = document.querySelector(`input[name="${inputName}"]`) as HTMLInputElement;
      if (input) {
        input.setSelectionRange(position, position);
      }
    });
  }, [cursorPositions]);

  const { data: clients } = useClients();
  const { data: projects } = useProjects();

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));

      // If we're editing an existing expense, upload the file immediately
      if (expense?.id) {
        const {
          data: { user },
        } = await supabase.auth.getUser();
        if (!user) {
          alert("No user found. Please log in and try again.");
          return;
        }

        const fileExt = file.name.split(".").pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${user.id}/${fileName}`;

        try {
          const { data: uploadData, error: uploadError } = await supabase.storage
            .from("expense_receipts")
            .upload(filePath, file, {
              upsert: true,
              contentType: file.type,
            });

          if (uploadError) throw uploadError;

          if (uploadData) {
            // Update the expense with the new receipt image path
            const filePath = uploadData.path;
            setFormData(prev => ({ ...prev, receipt_image_path: filePath }));
            debouncedUpdate(
              expense.id,
              { receipt_image_path: filePath, organization_id: expense.organization_id },
              isEditingRef.current
            );
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          alert("Failed to upload receipt image. Please try again.");
          setSelectedFile(null);
          setPreviewUrl(null);
        }
      }
    }
  };

  const taxInputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleAddTax = () => {
    setFormData(prev => {
      const currentTaxes = prev.taxes || [];
      const newTaxes = [...currentTaxes, {
        expense_id: expense?.id || '',
        name: '',
        amount: 0
      }];

      // Only autosave if we're editing an existing expense
      if (expense?.id) {
        isEditingRef.current = true;
        debouncedUpdate(
          expense.id,
          { taxes: newTaxes, organization_id: expense.organization_id },
          isEditingRef.current
        );
      }

      return { ...prev, taxes: newTaxes };
    });

    // Schedule focus for the next render cycle
    setTimeout(() => {
      const newIndex = formData.taxes?.length || 0;
      taxInputRefs.current[newIndex]?.focus();
    }, 0);
  };

  const handleRemoveTax = (index: number) => {
    setFormData(prev => {
      const currentTaxes = prev.taxes || [];
      const newTaxes = currentTaxes.filter((_, i) => i !== index);
      
      // Only autosave if we're editing an existing expense
      if (expense?.id) {
        isEditingRef.current = true;
        // Save both the taxes array and the total amount
        const totalTaxAmount = newTaxes.reduce((sum, tax) => sum + (parseFloat(String(tax.amount)) || 0), 0);
        const newAmount = subtotal + totalTaxAmount;
        const updateData: Partial<Expense> = {
          id: expense.id,
          organization_id: expense.organization_id,
          taxes: newTaxes.map(tax => ({
            name: tax.name,
            amount: parseFloat(String(tax.amount)) || 0
          })),
          amount: Number(newAmount)
        };
        debouncedUpdate(expense.id, { taxes: updateData.taxes, amount: updateData.amount }, isEditingRef.current);
      }
      
      return { ...prev, taxes: newTaxes };
    });
  };

  const handleTaxChange = (index: number, field: 'name' | 'amount', value: string | number) => {
    if (field === 'amount') {
      const input = document.querySelector(`input[name="tax_amount_${index}"]`) as HTMLInputElement;
      const { value: processedValue, cursorPos } = handleNumericInput(String(value), input?.selectionStart);

      setCursorPositions(prev => ({ ...prev, [`tax_amount_${index}`]: cursorPos }));

      setFormData(prev => {
        const currentTaxes = prev.taxes || [];
        const newTaxes = currentTaxes.map((tax, i: number) => 
          i === index ? { ...tax, amount: processedValue } : tax
        );
        
        const totalTaxAmount = newTaxes.reduce((sum: number, tax: { amount?: number }) => 
          sum + (tax.amount || 0), 0
        );
        const newSubtotal = Math.max(0, prev.amount - totalTaxAmount);
        setSubtotal(newSubtotal);
        
        if (expense?.id) {
          isEditingRef.current = true;
          const updateData: Partial<Expense> = {
            id: expense.id,
            organization_id: expense.organization_id,
            taxes: newTaxes.map(tax => ({
              name: tax.name,
              amount: tax.amount || 0
            }))
          };
          // Update local state immediately
          updateLocalExpense(updateData);
          // Debounce server update
          debouncedUpdate(expense.id, { taxes: updateData.taxes, organization_id: expense.organization_id }, isEditingRef.current);
        }
        
        return { ...prev, taxes: newTaxes };
      });
    } else {
      setFormData(prev => {
        const currentTaxes = prev.taxes || [];
        const newTaxes = currentTaxes.map((tax, i: number) => 
          i === index ? { ...tax, name: value as string } : tax
        );
        
        if (expense?.id) {
          isEditingRef.current = true;
          const updateData: Partial<Expense> = {
            id: expense.id,
            organization_id: expense.organization_id,
            taxes: newTaxes.map(tax => ({
              name: tax.name,
              amount: tax.amount || 0
            }))
          };
          // Update local state immediately
          updateLocalExpense(updateData);
          // Debounce server update
          debouncedUpdate(expense.id, { taxes: updateData.taxes, organization_id: expense.organization_id }, isEditingRef.current);
        }
        
        return { ...prev, taxes: newTaxes };
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submitting expense form with data:', formData);

    if (expense) {
      console.log('Expense exists, not creating new one');
      return;
    }

    if (!organizationId) {
      console.error("No organization selected");
      alert("No organization selected. Please select an organization and try again.");
      return;
    }

    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) {
      console.error("No user found");
      alert("No user found. Please log in and try again.");
      return;
    }

    let receipt_image_path = formData.receipt_image_path;

    if (selectedFile) {
      console.log('Uploading receipt file');
      const fileExt = selectedFile.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${user.id}/${fileName}`;

      try {
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from("expense_receipts")
          .upload(filePath, selectedFile, {
            upsert: true,
            contentType: selectedFile.type,
          });

        if (uploadError) throw uploadError;

        if (uploadData) {
          receipt_image_path = filePath;
          console.log('Receipt uploaded successfully:', filePath);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload receipt image. Please try again.");
        return;
      }
    }

    const totalAmount = subtotal + ((formData.taxes || []).reduce((sum, tax) => sum + (tax.amount || 0), 0));
    console.log('Calculated total amount:', totalAmount);

    const expenseData: Omit<Expense, "id" | "created_at" | "updated_at"> = {
      ...formData,
      user_id: user.id,
      receipt_image_path,
      organization_id: organizationId,
      client_id: formData.client_id || null,
      project_id: formData.project_id || null,
      invoice_id: formData.invoice_id || null,
      amount: totalAmount,
      taxes: formData.taxes?.map(tax => ({
        name: tax.name,
        amount: tax.amount || 0
      }))
    };

    console.log('Creating expense with data:', expenseData);

    try {
      const newExpense = await onCreate(expenseData);
      console.log('Expense created successfully:', newExpense);
      setIsOpen(false);
    } catch (error) {
      console.error("Error saving expense:", error);
      alert("Failed to save expense. Please try again.");
    }
  };

  const handleDelete = useCallback(() => {
    if (expense?.id) {
      setIsDeleteModalOpen(true);
    }
  }, [expense]);

  const handleConfirmDelete = useCallback(() => {
    if (expense?.id) {
      onDelete(expense.id);
      setIsOpen(false);
      setIsDeleteModalOpen(false);
    }
  }, [expense, onDelete, setIsOpen]);

  const handleDateChange = useCallback((date: Date | null) => {
    if (date) {
      const dateStr = date.toISOString().split('T')[0];
      setFormData(prev => ({ ...prev, date: dateStr }));

      const currentExpense = expense || pendingExpense;
      if (currentExpense?.id) {
        isEditingRef.current = true;
        handleLocalUpdate('date', dateStr);
      } else if (!isCreatingExpenseRef.current) {
        createInitialExpense();
      }
    }
  }, [expense, pendingExpense, createInitialExpense, handleLocalUpdate]);

  const handleCategoryChange = useCallback((categoryId: string | null) => {
    const newCategory = categoryId as ExpenseCategory | null;
    setFormData(prev => ({ ...prev, category: newCategory }));

    const currentExpense = expense || pendingExpense;
    if (currentExpense?.id) {
      isEditingRef.current = true;
      handleLocalUpdate('category', newCategory);
    } else if (!isCreatingExpenseRef.current) {
      createInitialExpense();
    }
  }, [expense, pendingExpense, createInitialExpense, handleLocalUpdate]);

  const handleClientChange = useCallback((clientId: string | null) => {
    setFormData(prev => ({ ...prev, client_id: clientId }));

    const currentExpense = expense || pendingExpense;
    if (currentExpense?.id) {
      isEditingRef.current = true;
      handleLocalUpdate('client_id', clientId);
    } else if (!isCreatingExpenseRef.current) {
      createInitialExpense();
    }
  }, [expense, pendingExpense, createInitialExpense, handleLocalUpdate]);

  const handleProjectChange = useCallback((projectId: string | null) => {
    setFormData(prev => ({ ...prev, project_id: projectId }));

    const currentExpense = expense || pendingExpense;
    if (currentExpense?.id) {
      isEditingRef.current = true;
      handleLocalUpdate('project_id', projectId);
    } else if (!isCreatingExpenseRef.current) {
      createInitialExpense();
    }
  }, [expense, pendingExpense, createInitialExpense, handleLocalUpdate]);

  const handleOpenChange = useCallback((open: boolean) => {
    if (!isAnyPopoverOpen && !isDeleteModalOpen) {
      setIsOpen(open);
    }
  }, [isAnyPopoverOpen, isDeleteModalOpen, setIsOpen]);

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen && !isDeleteModalOpen) {
      handleOpenChange(false);
    }
  }, [handleOpenChange, isAnyPopoverOpen, isDeleteModalOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen || isDatePickerOpen || isAnyPopoverOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);
      const isClickOnExpenseItem = (clickedElement as Element).closest('.expense-item') !== null;
      const isClickOnPopover = (clickedElement as Element).closest('.entity-picker-popover') !== null;

      // Don't close if clicking on an expense row or inside the drawer or on a popover
      if (!isClickInsideDrawer && !isClickOnExpenseItem && !isClickOnPopover) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isDatePickerOpen, isAnyPopoverOpen, handleOpenChange]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && !isAnyPopoverOpen) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleOpenChange, isAnyPopoverOpen]);

  const categoryEntities = Object.entries(EXPENSE_CATEGORIES).map(([category, subcategories]) => ({
    name: category,
    entities: subcategories.map(subcategory => ({
      id: `${category}:${subcategory}`,
      name: subcategory
    }))
  }));

  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [formData]);

  useEffect(() => {
    setPreviewUrl(null);
    setSelectedFile(null);

    if (expense) {
      // Calculate subtotal by subtracting taxes from total
      const taxTotal = (expense.taxes || []).reduce((sum, tax) => sum + (tax.amount || 0), 0);
      const calculatedSubtotal = expense.amount - taxTotal;

      setFormData({
        date: expense.date,
        amount: expense.amount,
        description: expense.description,
        client_id: expense.client_id,
        project_id: expense.project_id,
        invoice_id: expense.invoice_id,
        category: expense.category,
        receipt_image_path: expense.receipt_image_path,
        organization_id: expense.organization_id,
        merchant: expense.merchant || "",
        taxes: expense.taxes || [],
      });
      setSubtotal(calculatedSubtotal);
      
      if (editor) {
        editor.commands.setContent(expense.description || "");
      }
      
      // Handle receipt image URL
      if (expense.receipt_image_path) {
        // First check if we have a cached URL
        const cachedUrl = getCachedUrl(expense.receipt_image_path);
        if (cachedUrl) {
          setPreviewUrl(cachedUrl);
        } else {
          // If no cached URL, get a new one
          const { data: { publicUrl } } = supabase.storage
            .from("expense_receipts")
            .getPublicUrl(expense.receipt_image_path);
          
          // Add timestamp to prevent caching
          const timestamp = Date.now();
          const urlWithTimestamp = `${publicUrl}?t=${timestamp}`;
          
          // Cache the URL for future use
          setCachedUrl(expense.receipt_image_path, urlWithTimestamp, 3600); // Cache for 1 hour
          setPreviewUrl(urlWithTimestamp);
        }
      }
    } else if (isOpen && !pendingExpense) {
      setFormData({
        date: new Date().toISOString().split("T")[0],
        amount: 0,
        description: "",
        client_id: null,
        project_id: null,
        invoice_id: null,
        category: null,
        receipt_image_path: null,
        organization_id: organizationId || null,
        merchant: "",
        taxes: [],
      });
      setSubtotal(0);
      if (editor) {
        editor.commands.setContent("");
      }
    }
  }, [expense, isOpen, editor, getCachedUrl, setCachedUrl, pendingExpense, organizationId]);

  // Update image removal to clear cache
  const handleRemoveImage = useCallback(async () => {
    if (expense?.id && formData.receipt_image_path) {
      try {
        const { error } = await supabase.storage
          .from("expense_receipts")
          .remove([formData.receipt_image_path]);

        if (error) throw error;

        // Remove from cache
        delete urlCache.current[formData.receipt_image_path];

        const updateData: Partial<Expense> = {
          id: expense.id,
          organization_id: expense.organization_id,
          receipt_image_path: ""
        };
        // Update local state immediately
        updateLocalExpense(updateData);
        // Debounce server update
        debouncedUpdate(expense.id, { receipt_image_path: "" }, isEditingRef.current);
      } catch (error) {
        console.error("Error removing receipt image:", error);
        alert("Failed to remove receipt image. Please try again.");
        return;
      }
    }

    setPreviewUrl(null);
    setSelectedFile(null);
    setFormData((prev) => ({ ...prev, receipt_image_path: "" }));
  }, [expense, formData.receipt_image_path, updateLocalExpense, debouncedUpdate]);

  return (
    <>
      <Drawer.Root 
        open={isOpen} 
        onOpenChange={handleOpenChange}
        direction="right"
        modal={false}
        shouldScaleBackground={false}
      >
        <Drawer.Portal>
          <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
          <DrawerContent className="drawer-content">
            <DrawerInnerContent ref={drawerRef}>
              <DrawerColumns>
                <DrawerLeft>
                  {previewUrl ? (
                    <>
                      <ImagePreview src={previewUrl} alt="Receipt" />
                      <Button
                        type="button"
                        onClick={handleRemoveImage}
                      >
                        Remove Image
                      </Button>
                    </>
                  ) : (
                    <ImageUploadArea onClick={() => document.getElementById('file-input')?.click()}>
                      <Image12 />
                      Upload an image
                      <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        onChange={handleFileSelect}
                        style={{ display: 'none' }}
                      />
                    </ImageUploadArea>
                  )}
                </DrawerLeft>
                <DrawerRight>
                  <DrawerRightContent>
                    <DrawerHeader>
                      <CloseButton onClick={handleClose}><Close12 /></CloseButton>
                    </DrawerHeader>
                    <Form onSubmit={handleSubmit}>
                      <ExpenseDetails>
                        <MerchantInput
                          type="text"
                          name="merchant"
                          value={formData.merchant}
                          onChange={handleChange}
                          placeholder="Enter merchant name"
                        />
                        <DatePicker
                          selectedDate={formData.date ? new Date(formData.date) : null}
                          onChange={handleDateChange}
                          label="Date"
                          icon={<Calendar12 />}
                          id="expense-date"
                          placeholder="Select date"
                          placement='left-start'
                          onOpenChange={setIsDatePickerOpen}
                        />
                        <EntityPicker
                          selectedId={formData.category}
                          onChange={handleCategoryChange}
                          entities={categoryEntities}
                          label="Category"
                          icon={<Money12 />}
                          grouped={true}
                          onPopoverOpenChange={setIsAnyPopoverOpen}
                          allowUnassigned={true}
                        />
                        <EntityPicker
                          selectedId={formData.client_id}
                          onChange={handleClientChange}
                          entities={clients?.map(client => ({ id: client.id, name: client.full_name })) || []}
                          label="Client"
                          icon={<Client12 />}
                          allowUnassigned={true}
                          onPopoverOpenChange={setIsAnyPopoverOpen}
                        />
                        <EntityPicker
                          selectedId={formData.project_id}
                          onChange={handleProjectChange}
                          entities={projects?.map(project => ({ id: project.id, name: project.name || 'Untitled Project' })) || []}
                          icon={<Project12 />}
                          label="Project"
                          allowUnassigned={true}
                          onPopoverOpenChange={setIsAnyPopoverOpen}
                        />
                      </ExpenseDetails>
                      <EditorWrapper>
                        <EditorContent editor={editor} />
                      </EditorWrapper>
                      <AmountWrapper>
                        {formData.taxes.map((tax, index) => (
                          <div key={index} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                            <TaxInput
                              type="text"
                              name={`tax_name_${index}`}
                              value={tax.name}
                              onChange={(e) => handleTaxChange(index, 'name', e.target.value)}
                              placeholder="Tax Name"
                              style={{ textAlign: 'left', fontWeight: '500' }}
                              ref={el => taxInputRefs.current[index] = el}
                            />
                            <TaxInput
                              type="text"
                              name={`tax_amount_${index}`}
                              value={formatNumber(tax.amount)}
                              onChange={(e) => handleTaxChange(index, 'amount', e.target.value)}
                              placeholder="Tax Amount"
                              onKeyDown={(e) => {
                                // Allow arrow keys for navigation
                                if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                                  const input = e.target as HTMLInputElement;
                                  const newPos = e.key === 'ArrowLeft' ? 
                                    Math.max(0, input.selectionStart || 0 - 1) : 
                                    Math.min(input.value.length, (input.selectionStart || 0) + 1);
                                  setCursorPositions(prev => ({ ...prev, [`tax_amount_${index}`]: newPos }));
                                }
                              }}
                            />
                            <Button buttonType='icon' type="button" onClick={() => handleRemoveTax(index)}>
                              <Close8 />
                            </Button>
                          </div>
                        ))}
                        <AddTaxButton type="button" onClick={handleAddTax}>
                          <Plus8 />Add Tax
                        </AddTaxButton>
                        <TotalContainer>
                          <TotalWrapper>
                            <InputLabel>Subtotal</InputLabel>
                            <Input
                              type="text"
                              value={formatCurrency(subtotal)}
                              readOnly
                            />
                          </TotalWrapper>
                          <Divider />
                          <TotalWrapper>
                            <InputLabel>
                              Total
                            </InputLabel>
                            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                              <TaxInput
                                type="text"
                                name="amount"
                                value={formatNumber(formData.amount)}
                                onChange={(e) => handleChange({ 
                                  target: { 
                                    name: 'amount', 
                                    value: e.target.value,
                                    selectionStart: e.target.selectionStart
                                  } 
                                } as React.ChangeEvent<HTMLInputElement>)}
                                style={{ textAlign: 'right', fontWeight: '500', fontSize: '24px', lineHeight: '32px' }}
                                onKeyDown={(e) => {
                                  // Allow arrow keys for navigation
                                  if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
                                    const input = e.target as HTMLInputElement;
                                    const newPos = e.key === 'ArrowLeft' ? 
                                      Math.max(0, input.selectionStart || 0 - 1) : 
                                      Math.min(input.value.length, (input.selectionStart || 0) + 1);
                                    setCursorPositions(prev => ({ ...prev, amount: newPos }));
                                  }
                                }}
                              />
                            </div>
                          </TotalWrapper>
                        </TotalContainer>
                      </AmountWrapper>
                    </Form>
                  </DrawerRightContent>
                  {(expense || pendingExpense) && (
                    <ButtonFooter>
                      <DeleteButton type="button" onClick={handleDelete}>
                        Delete Expense
                      </DeleteButton>
                    </ButtonFooter>
                  )}
                </DrawerRight>
              </DrawerColumns>
            </DrawerInnerContent>
          </DrawerContent>
        </Drawer.Portal>
      </Drawer.Root>
      {createPortal(
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          title="Delete Expense"
          message="Are you sure you want to delete this expense? This action cannot be undone."
          confirmLabel="Delete"
          onConfirm={handleConfirmDelete}
          onCancel={() => setIsDeleteModalOpen(false)}
          isDestructive
        />,
        document.body
      )}
    </>
  );
};

export default AddExpenseDrawer;