import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer } from 'vaul';
import '../../RightDrawer.css';
import { InvoiceData, EmailTracking } from '../../types';
import { Close12 } from '../Icon';
import Button from '../Button';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import { supabase } from '../../supabaseClient';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
  max-width: 560px;
  outline: none;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const DrawerInnerContent = styled.div<{ $status: string }>`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.5), inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  background-color: rgba(248, 247, 246, 1);
  height: 100%;
  overflow-y: scroll;
  position: relative;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
  ${({ $status }) => {
    switch ($status) {
      case 'unpaid':
        return `
          background: linear-gradient(135deg, #fff4e5 0%, rgba(255,255,255,0) 30%);
        `;
      case 'paid':
        return `
          background: linear-gradient(135deg, #e6f4ea 0%, rgba(255,255,255,0) 30%);
        `;
      case 'overdue':
        return `
          background: linear-gradient(135deg, #fde7e9 0%, rgba(255,255,255,0) 30%);
        `;
      case 'draft':
        return `
          background: linear-gradient(135deg, #f1f3f5 0%, rgba(255,255,255,0) 30%);
        `;
      default:
        return `
          background: linear-gradient(135deg, #e9ecef 0%, rgba(255,255,255,0) 30%);
        `;
    }
  }}
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

interface InvoiceDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  title: string;
  selectedInvoice: InvoiceData | null;
  onOverlayClick: (event: React.MouseEvent) => void;
  onPopoverOpenChange: (isOpen: boolean) => void;
  modal?: boolean;
}

const InvoiceDrawer: React.FC<InvoiceDrawerProps> = ({
  isOpen,
  setIsOpen,
  children,
  onOverlayClick,
  selectedInvoice,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleOpenChange = useCallback((open: boolean) => {
    if (!isAnyPopoverOpen) {
      setIsOpen(open);
    }
  }, [isAnyPopoverOpen, setIsOpen]);

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen) {
      handleOpenChange(false);
    }
  }, [handleOpenChange, isAnyPopoverOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen || isAnyPopoverOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);
      const isClickOnInvoiceRow = (clickedElement as Element).closest('.invoice-row') !== null;
      const isClickOnPaymentRow = (clickedElement as Element).closest('.payment-row') !== null;
      const isClickOnPopover = (clickedElement as Element).closest('.entity-picker-popover') !== null;

      if (!isClickInsideDrawer && !isClickOnInvoiceRow && !isClickOnPaymentRow && !isClickOnPopover) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isAnyPopoverOpen, handleOpenChange]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && !isAnyPopoverOpen) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleOpenChange, isAnyPopoverOpen]);

  const prefetchInvoice = useCallback(() => {
    if (selectedInvoice) {
      queryClient.prefetchQuery({
        queryKey: ['invoice', selectedInvoice.id],
        queryFn: async () => {
          try {
            // Fetch main invoice data, items, and payments in parallel
            const [
              invoiceResponse,
              itemsResponse,
              paymentsResponse
            ] = await Promise.all([
              supabase
                .from('invoices')
                .select('*')
                .eq('id', selectedInvoice.id)
                .single(),
              supabase
                .from('invoice_items')
                .select('*')
                .eq('invoice_id', selectedInvoice.id)
                .order('order'),
              supabase
                .from('payments')
                .select('*')
                .eq('invoice_id', selectedInvoice.id)
            ]);

            const { data: invoiceData, error: invoiceError } = invoiceResponse;
            const { data: itemsData, error: itemsError } = itemsResponse;
            const { data: paymentsData, error: paymentsError } = paymentsResponse;

            if (invoiceError) throw invoiceError;
            if (itemsError) throw itemsError;
            if (paymentsError) throw paymentsError;

            // Attempt to fetch email tracking data separately with error handling
            let emailTrackingData: EmailTracking[] = [];
            try {
              const { data: trackingData, error: trackingError } = await supabase
                .from('email_tracking')
                .select(`
                  id,
                  tracking_id,
                  invoice_id,
                  recipient_email,
                  sent_at,
                  opened,
                  opened_at,
                  open_count,
                  open_history
                `)
                .eq('invoice_id', selectedInvoice.id)
                .order('sent_at', { ascending: false });

              if (!trackingError && trackingData) {
                emailTrackingData = trackingData;
              } else {
                console.warn('Failed to fetch email tracking data:', trackingError);
              }
            } catch (error) {
              console.warn('Error fetching email tracking data:', error);
            }

            return {
              ...invoiceData,
              items: itemsData || [],
              payments: paymentsData || [],
              email_tracking: emailTrackingData,
              logo_url: selectedInvoice.logo_url,
            };
          } catch (error) {
            console.error('Error prefetching invoice data:', error);
            throw error;
          }
        },
        staleTime: 30000, // Match the staleTime from useInvoice
      });
    }
  }, [queryClient, selectedInvoice]);

  const getInvoiceStatus = useCallback((invoice: InvoiceData) => {
    if (invoice.status === 'paid') return 'paid';
    if (invoice.status === 'draft') return 'draft';
    
    const dueDate = new Date(invoice.due_date);
    const today = new Date();
    
    dueDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    
    if (dueDate.getTime() < today.getTime()) {
      return 'overdue';
    }
    
    return 'unpaid';
  }, []);

  const invoiceStatus = selectedInvoice ? getInvoiceStatus(selectedInvoice) : 'default';

  return (
    <Drawer.Root 
      open={isOpen} 
      onOpenChange={handleOpenChange}
      direction="right"
      modal={false}
    >
      <Drawer.Portal>
        <InteractiveOverlay className="drawer-overlay" onClick={onOverlayClick} />
        <DrawerContent className="drawer-content">
          <DrawerInnerContent ref={drawerRef} $status={invoiceStatus}>
            <DrawerHeader>
              <CloseButton onClick={handleClose}><Close12 /></CloseButton>
            </DrawerHeader>
            <div className="invoice-details drawer-text-content">
              {React.Children.map(children, child => 
                React.isValidElement(child) 
                  ? React.cloneElement(child as React.ReactElement<{ 
                      onPopoverOpenChange: (isOpen: boolean) => void,
                      autoFocus: boolean,
                      prefetchInvoice: () => void
                    }>, { 
                      onPopoverOpenChange: setIsAnyPopoverOpen,
                      autoFocus: isOpen,
                      prefetchInvoice: prefetchInvoice
                    })
                  : child
              )}
            </div>
          </DrawerInnerContent>
        </DrawerContent>
      </Drawer.Portal>
    </Drawer.Root>
  );
};

export default InvoiceDrawer;
