import { createRouter, createRoute, createRootRoute } from "@tanstack/react-router";
import Layout from "./Layout";
import AuthWrapper from "./AuthWrapper";
import PublicInvoicePage from "./PublicInvoicePage";
import ClientPage from "./ClientPage";
import InvoiceList from "./InvoiceList";
import InvoicePage from "./InvoicePage";
import ClientList from "./ClientList";
import ProjectList from "./ProjectList";
import ProjectPage from "./ProjectPage";
import ExpensesPage from "./ExpensePage";
import TasksPage from "./TasksPage";
import ServicesList from "./ServicesList";
import PaymentsPage from "./PaymentsPage";
import Brand from "./Brand";
import CreateOrganizationPage from "./CreateOrganizationPage";
import SettingsPage from "./SettingsPage";
import TimeEntriesPage from "./TimeEntriesPage";
import UserProfilePage from './UserProfilePage';
import StripeConnectSuccess from './StripeConnectSuccess';
import Dashboard from './StripeDashboard'; // Import your Dashboard component
import AcceptInvitePage from './AcceptInvitePage'; // Add this import
import PendingInvitationsPage from './PendingInvitationsPage';
import OnboardingPage from './OnboardingPage';
import OrganizationDetailsPage from './OrganizationDetailsPage';
import OrganizationUsersTab from './components/Organization/OrganizationUsersTab';
import TaskStatusManager from './components/Tasks/TaskStatusManager';
import SubscriptionPage from './SubscriptionPage';

const rootRoute = createRootRoute({
  component: Layout,
});

const authRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'auth',
  component: AuthWrapper,
});

// Move publicInvoiceRoute outside of authRoute
const publicInvoiceRoute = createRoute({
  getParentRoute: () => rootRoute, // Change this to rootRoute
  path: "/share/$public_id",
  component: PublicInvoicePage,
});

const indexRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "/",
  component: InvoiceList,
});

const clientsRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "clients",
  component: ClientList,
});

export const clientRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "client/$id",
  component: ClientPage,
});

export const invoiceRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "invoice/$id",
  component: InvoicePage,
});

export const projectRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "project/$id",
  component: ProjectPage,
});

const projectsRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "projects",
  component: ProjectList,
});

const expensesRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "expenses",
  component: ExpensesPage,
});

const tasksRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "tasks",
  component: TasksPage,
});

const servicesRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "services",
  component: ServicesList,
});

const settingsServicesRoute = createRoute({
  getParentRoute: () => settingsRoute,
  path: "services",
  component: ServicesList,
});

const paymentsRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "payments",
  component: PaymentsPage,
});

const brandRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "brand",
  component: Brand,
});

const createOrganizationRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "create-organization",
  component: CreateOrganizationPage,
});

const settingsRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "settings",
  component: SettingsPage,
});

const timeEntriesRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "time",
  component: TimeEntriesPage,
});

const userProfileRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "profile",
  component: UserProfilePage,
});

const stripeConnectSuccessRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "stripe-connect-success",
  component: StripeConnectSuccess,
});

const dashboardRoute = createRoute({
  getParentRoute: () => authRoute,
  path: "dashboard",
  component: Dashboard,
});

export const acceptInviteRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/accept-invite",
  component: AcceptInvitePage,
  validateSearch: (search: Record<string, unknown>) => ({
    email: search.email as string | undefined,
    organizationId: search.organizationId as string | undefined,
  }),
});

export const pendingInvitationsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/pending-invitations",
  component: PendingInvitationsPage,
});

const onboardingRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/onboarding",
  component: OnboardingPage,
});

const settingsProfileRoute = createRoute({
  getParentRoute: () => settingsRoute,
  path: "profile",
  component: UserProfilePage,
});

const settingsOrganizationRoute = createRoute({
  getParentRoute: () => settingsRoute,
  path: "organization",
  component: OrganizationDetailsPage,
});

const settingsUsersRoute = createRoute({
  getParentRoute: () => settingsRoute,
  path: "users",
  component: OrganizationUsersTab,
});

const settingsPaymentsRoute = createRoute({
  getParentRoute: () => settingsRoute,
  path: "payments",
  component: Dashboard,
});

const settingsTaskStatusesRoute = createRoute({
  getParentRoute: () => settingsRoute,
  path: "task-statuses",
  component: TaskStatusManager,
});

const settingsIndexRoute = createRoute({
  getParentRoute: () => settingsRoute,
  path: "/",
  component: UserProfilePage,
});

export const settingsSubscriptionRoute = createRoute({
  getParentRoute: () => settingsRoute,
  path: "subscription",
  component: SubscriptionPage,
});

const routeTree = rootRoute.addChildren([
  authRoute.addChildren([
    indexRoute,
    clientsRoute,
    clientRoute,
    invoiceRoute,
    projectRoute,
    projectsRoute,
    expensesRoute,
    tasksRoute,
    servicesRoute,
    paymentsRoute,
    brandRoute,
    createOrganizationRoute,
    settingsRoute.addChildren([
      settingsIndexRoute,
      settingsProfileRoute,
      settingsOrganizationRoute,
      settingsUsersRoute,
      settingsServicesRoute,
      settingsPaymentsRoute,
      settingsTaskStatusesRoute,
      settingsSubscriptionRoute,
    ]),
    timeEntriesRoute,
    userProfileRoute,
    stripeConnectSuccessRoute,
    dashboardRoute,
  ]),
  publicInvoiceRoute, // Add this line to include the public route
  acceptInviteRoute,
  pendingInvitationsRoute,
  onboardingRoute,
]);

export const router = createRouter({ 
  routeTree,
  defaultPreload: 'intent',
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
