import React, { useState, useEffect } from 'react';
import NumberFlow from '@number-flow/react';
import styled from 'styled-components';

const StyledNumberFlow = styled(NumberFlow)`
  display: inline-flex;
  font-variant-numeric: tabular-nums;
`;

interface AnimatedNumberProps {
  value: number;
  startFrom?: number;
  duration?: number;
}

const AnimatedNumber: React.FC<AnimatedNumberProps> = ({ value, startFrom, duration = 1000 }) => {
  const [displayValue, setDisplayValue] = useState(startFrom ?? value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplayValue(value);
    }, 100);

    return () => clearTimeout(timer);
  }, [value]);

  return (
    <StyledNumberFlow
      value={displayValue}
      format={{
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }}
      locales="en-US"
      transformTiming={{ duration, easing: 'ease-out' }}
    />
  );
};

export default AnimatedNumber; 