import React from 'react';
import styled from 'styled-components';
import { Close8, Status12, Client12, Project12, Profile12, Calendar12 } from './Icon';
import { Filter } from '../types';
import MultipleEntityPicker from './MultipleEntityPicker';
import EntityPicker from './EntityPicker';
import DateRangePicker from './DateRangePicker';

const FilterBoxContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
`;

const FilterInput = styled.input`
  border: none;
  background: transparent;
  font-size: 12px;
  width: 80px;
  padding: 8px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

interface FilterBoxProps {
  filter: Filter;
  onRemove: () => void;
  onChange: (value: string | string[] | { startDate: Date | null; endDate: Date | null }) => void;
  options?: string[];
  entities?: { id: string; name: string }[];
}

// Add utility function for title case conversion
const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const FilterBox: React.FC<FilterBoxProps> = ({ filter, onRemove, onChange, options, entities }) => {
  const handleChange = (value: string | string[] | { startDate: Date | null; endDate: Date | null }) => {
    onChange(value);
  };

  const getIconForFilter = (filterId: string) => {
    switch (filterId) {
      case 'client':
        return <Client12 />;
      case 'project':
        return <Project12 />;
      case 'user':
        return <Profile12 />;
      case 'date':
        return <Calendar12 />;
      case 'category':
        return <Status12 />;
      default:
        return <Status12 />;
    }
  };

  const renderFilterInput = () => {
    if (filter.id === 'date') {
      return (
        <DateRangePicker
          selectedRange={filter.value as { startDate: Date | null; endDate: Date | null }}
          onChange={handleChange}
          label={toTitleCase(filter.label)}
          id={`date-range-${filter.id}`}
          variant="preview"
          icon={getIconForFilter(filter.id)}
          placement="bottom-start"
          noBorderRadius={true}
        />
      );
    } else if (entities) {
      return (
        <MultipleEntityPicker
          selectedIds={Array.isArray(filter.value) ? filter.value : []}
          onChange={handleChange}
          entities={entities}
          label={toTitleCase(filter.label)}
          icon={getIconForFilter(filter.id)}
          placement="bottom-start"
          noBorderRadius={true}
        />
      );
    } else if (options) {
      return (
        <EntityPicker
          selectedId={filter.value && (filter.value as string).length > 0 ? filter.value as string : null}
          onChange={(value) => handleChange(value || '')}
          entities={options.map(option => ({ id: option, name: toTitleCase(option) }))}
          label={toTitleCase(filter.label)}
          allowUnassigned={false}
          icon={getIconForFilter(filter.id)}
          noBorderRadius={true}
        />
      );
    } else {
      return (
        <FilterInput
          type="text"
          value={filter.value as string}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={toTitleCase(filter.label)}
        />
      );
    }
  };

  return (
    <FilterBoxContainer>
      {renderFilterInput()}
      <CloseButton onClick={onRemove}>
        <Close8 />
      </CloseButton>
    </FilterBoxContainer>
  );
};

export default FilterBox;