import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer } from 'vaul';
import { Task, Client, Project, TaskStatus } from '../../types';
import styled from 'styled-components';
import Button from '../Button';
import { Close12, Client12, Project12, Calendar12, Todo12, InProgress12, Done12, More12, Person12 } from '../Icon';
import { taskSchema } from '../../schemas/task';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import DatePicker from '../DatePicker';
import EntityPicker from '../EntityPicker';
import { debounce } from 'lodash';
import ConfirmationModal from '../ConfirmationModal';
import { createPortal } from 'react-dom';

const DrawerContent = styled(Drawer.Content)`
  pointer-events: auto;
`;

const DrawerInnerContent = styled.div`
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.5), inset 0 1px 0 rgba(255, 255, 255, 0.75);
  border-radius: 6px;
  background-color: rgba(248, 247, 246, 1);
  height: 100%;
  position: relative;
  backdrop-filter: blur(32px);
  -webkit-backdrop-filter: blur(32px);
`;

const InteractiveOverlay = styled(Drawer.Overlay)`
  cursor: pointer;
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: transparent;

  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    svg path {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const TaskDetailsWrapper = styled.div`
  padding: 0;
  height: 100%;
  overflow-y: auto;
`;

const TaskTitle = styled.input`
  width: 100%;
  padding: 48px 48px 24px 48px;
  border: 0;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  box-sizing: border-box;
  background: transparent;
`;

const PropertiesList = styled.div`
  padding: 0 40px 40px 40px;
`;

const EditorWrapper = styled.div`
  .ProseMirror {
    min-height: 100px;
    outline: none;
    padding: 24px 48px 200px 48px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    outline: none;

    p.is-editor-empty:first-child::before {
      color: rgba(0, 0, 0, 0.5);
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
`;

const StatusIconWrapper = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;

  svg path {
    fill: ${props => props.$color};
  }
`;

const StatusIcon = styled.svg`
  width: 12px;
  height: 12px;
  flex-shrink: 0;
`;

const MoreButtonWrapper = styled.div`
  position: absolute;
  top: 18px;
  right: 56px;
  z-index: 1;
`;

const ErrorMessage = styled.div`
  color: #ff4d4f;
  font-size: 12px;
  margin-left: 48px;
  margin-top: 4px;
`;

const ButtonFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`;

const DeleteButton = styled(Button)`
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ff1f1f;
  }
`;

interface TaskDrawerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedTask: Task | null;
  updateTask: (updatedTask: Partial<Task>, isEditing: boolean) => void;
  updateLocalTask: (updatedTask: Partial<Task>) => void;
  clients: Client[];
  projects: Project[];
  organizationUsers: { id: string; full_name: string | null; email: string }[];
  taskStatuses: TaskStatus[];
  onDeleteTask: (taskId: string) => void;
  autoFocus?: boolean;
}

type MoreMenuItem = {
  id: string;
  name: string;
  action: () => void;
};

const TaskDrawer: React.FC<TaskDrawerProps> = ({
  isOpen,
  setIsOpen,
  selectedTask,
  updateTask,
  updateLocalTask,
  clients,
  projects,
  organizationUsers,
  taskStatuses,
  onDeleteTask,
  autoFocus,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isAnyPopoverOpen, setIsAnyPopoverOpen] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [localTask, setLocalTask] = useState<Task | null>(selectedTask);
  const isEditingRef = useRef(false);
  const titleInputRef = useRef<HTMLInputElement>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (selectedTask && !isEditingRef.current) {
      setLocalTask(selectedTask);
    }
  }, [selectedTask, isEditingRef]);

  const debouncedUpdateTask = useRef(
    debounce(
      (id: string, changes: Partial<Task>, isEditing: boolean) => {
        updateTask({ id, ...changes }, isEditing);
      },
      500,
      { maxWait: 2000 }
    )
  ).current;

  const handleInputChange = (field: keyof Task, value: string | number | boolean | null) => {
    console.log('handleInputChange called:', { field, value });
    console.log('Current localTask:', localTask);
    
    isEditingRef.current = true;
    const updatedTask = { ...localTask!, [field]: value };
    
    console.log('Attempting to validate task:', updatedTask);
    // Validate before updating
    try {
      const validationResult = taskSchema.safeParse(updatedTask);
      console.log('Validation result:', validationResult);
      
      if (!validationResult.success) {
        console.log('Validation errors:', validationResult.error);
        setErrors(Object.fromEntries(
          validationResult.error.errors.map(err => [err.path[0], err.message])
        ));
        return;
      }
      setErrors({});
    } catch (error) {
      console.error('Validation error:', error);
      return;
    }
    
    // Immediately update local state
    setLocalTask(updatedTask);
    updateLocalTask(updatedTask);

    // Debounce only the server update
    if (localTask?.id) {
      console.log('Calling debouncedUpdateTask');
      debouncedUpdateTask(localTask.id, { [field]: value }, isEditingRef.current);
    }
  };

  const handleOpenChange = useCallback((open: boolean) => {
    if (!open && isDeleteModalOpen) {
      return;
    }
    if (!isAnyPopoverOpen) {
      setIsOpen(open);
    }
  }, [isAnyPopoverOpen, isDeleteModalOpen, setIsOpen]);

  const handleClose = useCallback(() => {
    if (!isAnyPopoverOpen && !isDeleteModalOpen) {
      handleOpenChange(false);
    }
  }, [handleOpenChange, isAnyPopoverOpen, isDeleteModalOpen]);

  useEffect(() => {
    if (isEditingRef.current) {
      const timer = setTimeout(() => {
        isEditingRef.current = false;
      }, 2500);
      return () => clearTimeout(timer);
    }
  }, [localTask]);

  useEffect(() => {
    if (autoFocus && isOpen) {
      const timer = setTimeout(() => {
        if (titleInputRef.current) {
          titleInputRef.current.focus();
          const length = titleInputRef.current.value.length;
          titleInputRef.current.setSelectionRange(length, length);
        }
      }, 50);
      return () => clearTimeout(timer);
    }
  }, [autoFocus, isOpen]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!isOpen || isDatePickerOpen || isAnyPopoverOpen || isDeleteModalOpen) return;

      const clickedElement = event.target as Node;
      const isClickInsideDrawer = drawerRef.current?.contains(clickedElement);
      const isClickOnTaskItem = (clickedElement as Element).closest('.task-item') !== null;
      const isClickOnPopover = (clickedElement as Element).closest('.entity-picker-popover') !== null;

      if (!isClickInsideDrawer && !isClickOnTaskItem && !isClickOnPopover) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, isDatePickerOpen, isAnyPopoverOpen, isDeleteModalOpen, handleOpenChange]);

  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen && !isAnyPopoverOpen && !isDeleteModalOpen) {
        handleOpenChange(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, handleOpenChange, isAnyPopoverOpen, isDeleteModalOpen]);

  const handleLocalUpdate = (field: keyof Task, value: unknown) => {
    if (localTask) {
      const updatedTask = { ...localTask, [field]: value };
      setLocalTask(updatedTask);
      updateLocalTask(updatedTask);
      updateTask({ id: localTask.id, [field]: value }, isEditingRef.current);
    }
  };

  const getCategoryIcon = (category: 'Todo' | 'In Progress' | 'Complete') => {
    switch (category) {
      case 'Todo':
        return Todo12;
      case 'In Progress':
        return InProgress12;
      case 'Complete':
        return Done12;
    }
  };

  const currentStatus = localTask ? taskStatuses.find(status => status.id === localTask.status_id) : null;
  const StatusIconComponent = currentStatus ? getCategoryIcon(currentStatus.category as 'Todo' | 'In Progress' | 'Complete') : Todo12;

  const handleDeleteTask = useCallback(() => {
    setIsDeleteModalOpen(true);
  }, []);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Add notes here...',
      }),
    ],
    content: localTask?.notes || '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      handleInputChange('notes', html);
    },
  });

  useEffect(() => {
    if (editor && !isEditingRef.current) {
      if (localTask?.notes !== editor.getHTML()) {
        editor.commands.setContent(localTask?.notes || '');
      }
    }
  }, [editor, localTask?.notes]);

  const moreMenuItems: MoreMenuItem[] = [
    { 
      id: 'delete', 
      name: 'Delete Task', 
      action: handleDeleteTask 
    },
  ];

  const handleConfirmDelete = useCallback(() => {
    if (selectedTask?.id) {
      onDeleteTask(selectedTask.id);
      setIsOpen(false);
    }
  }, [selectedTask, onDeleteTask, setIsOpen]);

  useEffect(() => {
    if (!isOpen) {
      // Reset delete modal state when drawer is fully closed
      setIsDeleteModalOpen(false);
    }
  }, [isOpen]);

  if (!localTask) {
    return null;
  }

  return (
    <>
      <Drawer.Root
        open={isOpen}
        onOpenChange={handleOpenChange}
        direction="right"
        modal={false}
        shouldScaleBackground={false}
      >
        <Drawer.Portal>
          <InteractiveOverlay className="drawer-overlay" onClick={handleClose} />
          <DrawerContent className="drawer-content">
            <DrawerInnerContent ref={drawerRef}>
              <DrawerHeader>
                <CloseButton onClick={handleClose}><Close12 /></CloseButton>
              </DrawerHeader>
              <TaskDetailsWrapper>
                <TaskTitle
                  ref={titleInputRef}
                  type="text"
                  name="description"
                  value={localTask.description || ""}
                  onChange={(e) => handleInputChange('description', e.target.value)}
                  placeholder="Task description"
                  style={errors.description ? { borderColor: '#ff4d4f' } : undefined}
                />
                {errors.description && (
                  <ErrorMessage>
                    {errors.description}
                  </ErrorMessage>
                )}
                <MoreButtonWrapper>
                  <EntityPicker
                    selectedId={null}
                    onChange={(id) => {
                      const selectedItem = moreMenuItems.find(item => item.id === id);
                      if (selectedItem) {
                        selectedItem.action();
                      }
                    }}
                    entities={moreMenuItems}
                    label="More"
                    icon={<More12 />}
                    iconOnly
                    placement="bottom-end"
                    enableSearch={false}
                    onPopoverOpenChange={setIsAnyPopoverOpen}
                  />
                </MoreButtonWrapper>
                <PropertiesList>
                  <DatePicker
                    selectedDate={localTask.due_date ? new Date(localTask.due_date) : null}
                    onChange={(date) => handleLocalUpdate('due_date', date ? date.toISOString().split('T')[0] : null)}
                    label="Due Date"
                    id="task-due-date"
                    onOpenChange={setIsDatePickerOpen}
                    icon={<Calendar12 />}
                    placement="left-start"
                    showClearDate={true}
                  />
                  <EntityPicker
                    selectedId={localTask.status_id || null}
                    onChange={(id) => handleLocalUpdate('status_id', id)}
                    entities={taskStatuses.map((status) => {
                      const IconComponent = getCategoryIcon(status.category as 'Todo' | 'In Progress' | 'Complete');
                      return {
                        id: status.id,
                        name: status.name,
                        color: status.color,
                        icon: <IconComponent />
                      };
                    })}
                    label="Status"
                    allowUnassigned={false}
                    icon={
                      <StatusIconWrapper $color={currentStatus?.color || '#000000'}>
                        <StatusIcon as={StatusIconComponent} />
                      </StatusIconWrapper>
                    }
                    onPopoverOpenChange={setIsAnyPopoverOpen}
                  />
                  <EntityPicker
                    selectedId={localTask.client_id || null}
                    onChange={(id) => handleLocalUpdate('client_id', id)}
                    entities={clients?.map((client: Client) => ({
                      id: client.id,
                      name: client.full_name
                    })) || []}
                    label="Add a Client"
                    allowUnassigned={true}
                    icon={<Client12 />}
                    onPopoverOpenChange={setIsAnyPopoverOpen}
                  />
                  <EntityPicker
                    selectedId={localTask.project_id || null}
                    onChange={(id) => handleLocalUpdate('project_id', id)}
                    entities={projects?.map((project: Project) => ({
                      id: project.id,
                      name: project.name || 'Untitled Project'
                    })) || []}
                    label="Add to Project"
                    allowUnassigned={true}
                    icon={<Project12 />}
                    onPopoverOpenChange={setIsAnyPopoverOpen}
                    placement="left-start"
                  />
                  <EntityPicker
                    selectedId={localTask.user_id || null}
                    onChange={(id) => handleLocalUpdate('user_id', id)}
                    entities={organizationUsers.map((user) => ({
                      id: user.id,
                      name: user.full_name || user.email || 'Unknown User'
                    }))}
                    label="Assign"
                    allowUnassigned={true}
                    icon={<Person12 />}
                    onPopoverOpenChange={setIsAnyPopoverOpen}
                    placement="left-start"
                  />
                </PropertiesList>
                <EditorWrapper>
                  <EditorContent editor={editor} />
                </EditorWrapper>
              </TaskDetailsWrapper>
              {localTask && (
                <ButtonFooter>
                  <DeleteButton type="button" onClick={handleDeleteTask}>
                    Delete Task
                  </DeleteButton>
                </ButtonFooter>
              )}
            </DrawerInnerContent>
          </DrawerContent>
        </Drawer.Portal>
      </Drawer.Root>
      {createPortal(
        <ConfirmationModal
          isOpen={isDeleteModalOpen}
          title="Delete Task"
          message="Are you sure you want to delete this task? This action cannot be undone."
          confirmLabel="Delete"
          onConfirm={handleConfirmDelete}
          onCancel={() => setIsDeleteModalOpen(false)}
          isDestructive
        />,
        document.body
      )}
    </>
  );
};

export default TaskDrawer;