import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from '@tanstack/react-router';
import TimeTracker from './Time/TimeTracker';

const PageHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px 24px 0 40px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: linear-gradient(to bottom, #F8F7F6 0%, rgba(255, 255, 255, 0) 100%);
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 44px;
`;

const BetaLabel = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: rgba(239, 70, 17, 1);
  background-color: rgba(239, 70, 17, 0.1);
  padding: 4px 8px;
  border-radius: 16px;
  margin: 4px 0 0 8px;
  user-select: none;
`;

const Title = styled.h1`
  margin: 12px 0 4px;
  padding: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  font-family: 'PP Mori', sans-serif;
  user-select: none;
  min-height: 28px;
`;

const BreadcrumbWrapper = styled.div`
  font-family: 'PP Mori', sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 12px 0 4px;
  min-height: 28px;
`;

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.8);
  }
`;

const LoadingText = styled.span`
  font-family: 'PP Mori', sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0;
  min-height: 28px;
  display: inline-block;
`;

export interface PageHeaderProps {
  left?: ReactNode;
  right?: ReactNode;
  title?: string | ReactNode;
  parentPath?: string;
  parentName?: string;
  actionButtons?: Array<{
    label: string;
    onClick: () => void;
    type: string;
  }>;
  isLoading?: boolean;
  showBeta?: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({ left, right, title, parentPath, parentName, isLoading = false, showBeta = false }) => {
  return (
    <PageHeaderWrapper>
      <LeftSection>
        {parentPath && parentName ? (
          <BreadcrumbWrapper>
            <BreadcrumbLink to={parentPath}>{parentName}</BreadcrumbLink>
            /
            {isLoading ? (
              <LoadingText>Loading...</LoadingText>
            ) : (
              typeof title === 'string' ? <Title>{title}</Title> : title
            )}
            {showBeta && <BetaLabel>Beta</BetaLabel>}
          </BreadcrumbWrapper>
        ) : (
          title && (
            <>
              {isLoading ? (
                <LoadingText>Loading...</LoadingText>
              ) : (
                typeof title === 'string' ? <Title>{title}</Title> : title
              )}
              {showBeta && <BetaLabel>Beta</BetaLabel>}
            </>
          )
        )}
        {left}
      </LeftSection>
      <RightSection>{right}<TimeTracker /></RightSection>
    </PageHeaderWrapper>
  );
};

export default PageHeader;