console.log('Loading Stripe config with:', {
    monthlyPriceId: import.meta.env.VITE_STRIPE_MONTHLY_PRICE_ID,
    annualPriceId: import.meta.env.VITE_STRIPE_ANNUAL_PRICE_ID,
    publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    allEnvVars: import.meta.env, // Log all environment variables
});

// Validate required environment variables
if (!import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY) {
    console.error('Missing VITE_STRIPE_PUBLISHABLE_KEY environment variable');
}

export const STRIPE_CONFIG = {
    MONTHLY_PRICE_ID: import.meta.env.VITE_STRIPE_MONTHLY_PRICE_ID || '',
    ANNUAL_PRICE_ID: import.meta.env.VITE_STRIPE_ANNUAL_PRICE_ID || '',
    PUBLIC_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '',
    SUCCESS_URL: `${window.location.origin}/subscription/success`,
    CANCEL_URL: `${window.location.origin}/subscription/cancel`,
    TRIAL_PERIOD_DAYS: 30,
    MONTHLY_PRICE: 12,
    ANNUAL_PRICE: 120, // 10/month when paid annually
} as const; 