import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: calc(100% - 200px);
  min-height: 400px;
`;

const IconWrapper = styled.div`
  margin-bottom: 16px;
`;

const Message = styled.div`
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 500;
`;

const SubMessage = styled.p`
  margin: 0 0 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
`;

interface EmptyStateProps {
  icon: React.ReactNode;
  message: string;
  subMessage: string;
  action?: {
    label: string;
    onClick: () => void;
  };
}

const EmptyState: React.FC<EmptyStateProps> = ({ icon, message, subMessage, action }) => (
  <EmptyStateContainer>
    <IconWrapper>{icon}</IconWrapper>
    <Message>{message}</Message>
    <SubMessage>{subMessage}</SubMessage>
    {action && <Button onClick={action.onClick}>{action.label}</Button>}
  </EmptyStateContainer>
);

export default EmptyState;