import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { supabase } from '../supabaseClient';

interface UserMetadata {
  full_name?: string;
  avatar_url?: string;
  [key: string]: string | undefined;
}

export interface UserData {
  id: string;
  email: string;
  full_name: string | null;
  avatar_url: string | null;
  updated_at: string | null;
  user_metadata: UserMetadata | null;
}

type UserQueryOptions = Omit<UseQueryOptions<UserData | null, Error>, 'queryKey' | 'queryFn'>;

export const useUser = (options?: UserQueryOptions) => {
  return useQuery<UserData | null, Error>({
    queryKey: ['user'],
    queryFn: async () => {
      console.log('Fetching user data...');

      const { data: { user: authUser } } = await supabase.auth.getUser();
      if (!authUser) return null;

      const { data: profile, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', authUser.id)
        .single();

      if (error) throw error;

      return {
        id: authUser.id,
        email: authUser.email ?? '',
        full_name: profile?.full_name ?? null,
        avatar_url: profile?.avatar_url ?? null,
        updated_at: profile?.updated_at ?? null,
        user_metadata: profile?.user_metadata ?? null,
      };
    },
    staleTime: 5 * 60 * 1000,
    gcTime: 10 * 60 * 1000,
    ...options,
  });
};
