import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { 
  useReactTable, 
  getCoreRowModel, 
  getFilteredRowModel,
  getSortedRowModel,
  ColumnDef, 
  RowSelectionState,
  SortingState,
  OnChangeFn,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { GroupedTimeEntry, Client, Project, OrganizationUser } from '../../types';
import TimeEntryComponent from './TimeEntryComponent';
import EmptyState from '../EmptyState';
import { formatDuration } from '../../utils/timeUtils';
import { format, parseISO, isToday, isYesterday } from 'date-fns';
import { Checkmark8 } from '../Icon';

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const GroupHeaderRow = styled.div`
  padding: 12px 40px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  box-shadow: 0 1px 0 rgba(0,0,0,0.05);
  display: flex;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
  position: relative;
  background-color: rgba(0,0,0,0.025);

  &:hover {
    .group-header-checkbox {
      opacity: 1;
    }
  }
`;

const GroupHeaderText = styled.span``;

const EntryCount = styled.span`
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
  font-feature-settings: 'tnum';
  font-size: 14px;
  font-weight: 500;
`;

const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 400px;
`;

const GroupHeaderCheckbox = styled.div<{ $isSelected: boolean }>`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  opacity: ${props => props.$isSelected ? 1 : 0};
  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const StyledCheckbox = styled.div<{ $isSelected: boolean }>`
  width: 12px;
  height: 12px;
  border: 1px solid ${props => props.$isSelected ? '#000' : 'rgba(0, 0, 0, 0.2)'};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.$isSelected ? '#000' : 'transparent'};

  svg {
    display: ${props => props.$isSelected ? 'block' : 'none'};
    path {
      fill: #fff;
    }
  }
`;

interface TimeEntryTableProps {
  timeEntries: (GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number })[];
  columns: ColumnDef<GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number }>[];
  isLoading: boolean;
  onRowClick: (entry: GroupedTimeEntry) => void;
  onImmediateUpdate: (updatedEntry: Partial<GroupedTimeEntry>, isEditing?: boolean) => void;
  getRowKey: (entry: GroupedTimeEntry) => string;
  clients: Client[];
  projects: Project[];
  organizationUsers: OrganizationUser[];
  onEntriesUpdate: (updatedEntries: GroupedTimeEntry[]) => void;
  containerRef: React.RefObject<HTMLDivElement>;
  emptyState: {
    icon: React.ReactNode;
    message: string;
    subMessage: string;
    action: {
      label: string;
      onClick: () => void;
    };
  };
  onDeleteEntry: (entryId: string) => Promise<void>;
  onSelectionChange: (selectedEntries: string[], selectedCount: number) => void;
  onInvoiceClick: (invoiceId: string) => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  timeFormat: 'decimal' | 'hourMinute';
  isInitialLoading: boolean;
  currentDurations: { [id: string]: number }; // New prop to track durations
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
}

const TimeEntryTable: React.FC<TimeEntryTableProps> = ({ 
  timeEntries, 
  columns, 
  isLoading, 
  onRowClick, 
  onImmediateUpdate,
  getRowKey,
  clients,
  projects,
  organizationUsers,
  containerRef,
  emptyState,
  onSelectionChange,
  onInvoiceClick,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  timeFormat = 'hourMinute',
  isInitialLoading,
  currentDurations,
  sorting = [],
  onSortingChange,
}) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [showEmptyState, setShowEmptyState] = useState(false);

  const table = useReactTable({
    data: timeEntries,
    columns: columns as ColumnDef<GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number }>[],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
      sorting,
    },
    onSortingChange: onSortingChange,
    getRowId: (row) => 'isGroupHeader' in row ? row.id : getRowKey(row as GroupedTimeEntry),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length + (hasNextPage ? 1 : 0),
    getScrollElement: () => containerRef.current,
    estimateSize: useCallback((index) => {
      const row = rows[index];
      if (!row) return 44;
      return 'isGroupHeader' in row.original ? 44 : 44;
    }, [rows]),
    overscan: 10,
  });

  useEffect(() => {
    const lastItem = rowVirtualizer.getVirtualItems().at(-1);
    if (lastItem && lastItem.index >= rows.length - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [rowVirtualizer.getVirtualItems(), rows.length, hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (!isInitialLoading && !isLoading && timeEntries.length === 0) {
      setTimeout(() => setShowEmptyState(true), 0);
    } else {
      setShowEmptyState(false);
    }
  }, [isInitialLoading, isLoading, timeEntries.length]);

  const handleGroupSelection = useCallback((groupId: string, isSelected: boolean) => {
    const newRowSelection = { ...rowSelection };
    const groupEntries = timeEntries.filter(entry => !('isGroupHeader' in entry) && (entry as GroupedTimeEntry).groupDate === groupId);
    groupEntries.forEach(entry => {
      newRowSelection[getRowKey(entry as GroupedTimeEntry)] = isSelected;
    });
    setRowSelection(newRowSelection);
  }, [rowSelection, timeEntries, getRowKey]);

  const isGroupSelected = useCallback((groupId: string) => {
    const groupEntries = timeEntries.filter(entry => !('isGroupHeader' in entry) && (entry as GroupedTimeEntry).groupDate === groupId);
    return groupEntries.every(entry => rowSelection[getRowKey(entry as GroupedTimeEntry)]);
  }, [rowSelection, timeEntries, getRowKey]);

  useEffect(() => {
    const selectedEntries = Object.keys(rowSelection).filter(id => rowSelection[id]);
    onSelectionChange(selectedEntries, selectedEntries.length);
  }, [rowSelection, onSelectionChange]);

  const renderRow = useCallback(
    (entry: GroupedTimeEntry | { id: string; isGroupHeader: boolean; totalDuration: number }) => {
      if ('isGroupHeader' in entry) {
        const date = parseISO(entry.id);
        let formattedDate;
        if (isToday(date)) {
          formattedDate = `Today (${format(date, 'MMM d')})`;
        } else if (isYesterday(date)) {
          formattedDate = `Yesterday (${format(date, 'MMM d')})`;
        } else {
          formattedDate = format(date, 'MMM d');
        }
        const isSelected = isGroupSelected(entry.id);
        return (
          <GroupHeaderRow>
            <GroupHeaderCheckbox
              className="group-header-checkbox"
              $isSelected={isSelected}
              onClick={(e) => {
                e.stopPropagation();
                handleGroupSelection(entry.id, !isSelected);
              }}
            >
              <StyledCheckbox $isSelected={isSelected}>
                <Checkmark8 />
              </StyledCheckbox>
            </GroupHeaderCheckbox>
            <GroupHeaderText>{formattedDate}</GroupHeaderText>
            <EntryCount>{formatDuration(entry.totalDuration)}</EntryCount>
          </GroupHeaderRow>
        );
      }
      return (
        <TimeEntryComponent
          key={getRowKey(entry as GroupedTimeEntry)}
          entry={entry as GroupedTimeEntry}
          onImmediateUpdate={onImmediateUpdate}
          isSelected={!!rowSelection[getRowKey(entry as GroupedTimeEntry)]}
          clients={clients}
          projects={projects}
          organizationUsers={organizationUsers}
          onClick={() => onRowClick(entry as GroupedTimeEntry)}
          onInvoiceClick={onInvoiceClick}
          timeFormat={timeFormat}
          onSelect={(isSelected) => {
            const newSelection = { ...rowSelection };
            newSelection[getRowKey(entry as GroupedTimeEntry)] = isSelected;
            setRowSelection(newSelection);
          }}
          currentDuration={currentDurations[getRowKey(entry as GroupedTimeEntry)] || entry.duration} // Pass duration prop
        />
      );
    },
    [
      getRowKey,
      onImmediateUpdate,
      clients,
      projects,
      organizationUsers,
      onRowClick,
      onInvoiceClick,
      isGroupSelected,
      handleGroupSelection,
      rowSelection,
      setRowSelection,
      timeFormat,
      currentDurations, // Add to dependencies
    ]
  );

  const renderContent = () => {

    if (timeEntries.length === 0 && showEmptyState) {
      return (
        <EmptyStateWrapper>
          <EmptyState
            icon={emptyState.icon}
            message={emptyState.message}
            subMessage={emptyState.subMessage}
            action={emptyState.action}
          />
        </EmptyStateWrapper>
      );
    }

    return (
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const row = rows[virtualRow.index];
          if (!row) {
            return (
              <div
                key="loading"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '44px',
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                {/* Remove the "Loading more..." text */}
              </div>
            );
          }

          return (
            <div
              key={row.id}
              data-index={virtualRow.index}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: 'isGroupHeader' in row.original ? '52px' : '44px',
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {renderRow(row.original)}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <TableContainer>
      {renderContent()}
    </TableContainer>
  );
};

export default React.memo(TimeEntryTable);
