import { z } from 'zod';

export const paymentSchema = z.object({
  amount: z.number()
    .positive('Amount must be greater than 0')
    .transform(amount => Number(amount.toFixed(2))), // Round to 2 decimal places
  payment_date: z.string()
    .regex(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format')
    .refine(
      (date) => !isNaN(Date.parse(date)),
      'Invalid date'
    ),
  notes: z.string().optional().or(z.literal('')),
  invoice_id: z.string().optional().or(z.literal('')),
  organization_id: z.string().min(1, 'Organization is required'),
  user_id: z.string().min(1, 'User is required')
}).refine(
  (data) => {
    const paymentDate = new Date(data.payment_date);
    return paymentDate <= new Date();
  },
  {
    message: "Payment date cannot be in the future",
    path: ["payment_date"]
  }
);

export type PaymentSchema = z.infer<typeof paymentSchema>; 