import { z } from 'zod';

const postalCodeSchema = z.string()
  .min(1, 'Postal code is required')
  .max(10, 'Postal code is too long')
  .nullable()
  .optional();

export const organizationSchema = z.object({
  id: z.string(),
  name: z.string().min(1, 'Organization name is required').optional(),
  business_name: z.string().nullable().optional(),
  business_email: z.string().email('Invalid email address').nullable().optional(),
  logo_url: z.string().nullable().optional(),
  address_line1: z.string().nullable().optional(),
  address_line2: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  postal_code: postalCodeSchema,
  country: z.string().nullable().optional(),
  enable_email_tracking: z.boolean().default(true).optional(),
  created_at: z.string().nullable().optional(),
  updated_at: z.string().nullable().optional(),
  default_payment_terms: z.string().nullable().optional(),
  stripe_account_id: z.string().nullable().optional(),
  stripe_account_verified: z.boolean().optional(),
}); 