import { z } from 'zod';

export const profileSchema = z.object({
  id: z.string(),
  full_name: z.string().min(1, 'Full name is required'),
  avatar_url: z.string().nullable(),
  updated_at: z.string().nullable().optional(),
  user_metadata: z.object({
    full_name: z.string().optional(),
    avatar_url: z.string().nullish()
  }).optional()
}); 