import Button from '../Button';
import { useInviteUser } from '../../hooks/useInviteUser';
import styled from 'styled-components';
import { useState } from 'react';
import { PendingInvitation } from '../../types';
import { z } from 'zod';

const emailSchema = z.string().email('Please include an @ in the email address.');

const Form = styled.form`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
`;

const InputField = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0,0,0,0.8);
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  input {
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 6px;
    border: ${props => props.hasError ? '1px solid #ff4d4f' : '0'};
    background-color: rgba(0,0,0,0.05);

    &:focus {
      outline: none;
      box-shadow: 0 0 0 1px ${props => props.hasError ? '#ff4d4f' : 'rgba(0,0,0,0.5)'};
    }
  }

  .error-message {
    color: #ff4d4f;
    font-size: 12px;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 22px; /* This accounts for the label height */
`;

const StyledButton = styled(Button)`
  padding: 8px 16px;
  border-radius: 6px;
  height: 36px;
`;

interface InviteUserFormProps {
  existingEmails: string[];
  onInviteSent: (newInvitation: PendingInvitation) => void;
}

const InviteUserForm: React.FC<InviteUserFormProps> = ({ existingEmails, onInviteSent }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const inviteUser = useInviteUser();

  const validateEmail = (email: string) => {
    try {
      emailSchema.parse(email);
      if (existingEmails.includes(email.toLowerCase())) {
        setError('This user is already a member or has a pending invitation.');
        return false;
      }
      setError(null);
      return true;
    } catch (err) {
      if (err instanceof z.ZodError) {
        setError(err.errors[0].message);
      }
      return false;
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateEmail(email)) return;

    inviteUser.mutate(email, {
      onSuccess: (data) => {
        setEmail('');
        setError(null);
        onInviteSent(data);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputField hasError={!!error}>
        <label htmlFor="email">Invite User</label>
        <div className="input-wrapper">
          <input
            id="email"
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError(null);
            }}
            placeholder="Enter email to invite"
            required
          />
          {error && <span className="error-message">{error}</span>}
        </div>
      </InputField>
      <ButtonWrapper>
        <StyledButton 
          type="submit"
          disabled={inviteUser.isPending}
          buttonType={email ? 'primary' : undefined}
        >
          {inviteUser.isPending ? 'Sending...' : 'Send Invite'}
        </StyledButton>
      </ButtonWrapper>
    </Form>
  );
};

export default InviteUserForm;