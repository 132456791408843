import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  Placement,
} from "@floating-ui/react";
import { useClients } from "../../hooks/useClients";
import { useOrganization } from "../../hooks/useOrganization";
import { Client } from "../../types";
import { Checkmark12, Search12, Plus8 } from "../Icon";
import Fuse from 'fuse.js';
import NewClientDrawer from './AddClientDrawer';
import { useQueryClient } from "@tanstack/react-query";

interface ContrastCache {
  __contrastCache: Map<string, string>;
}

declare global {
  interface Window extends ContrastCache {}
}

const memoizedGetContrastColor = (backgroundColor: string): string => {
  const key = `contrast-${backgroundColor}`;
  if (!window.__contrastCache) {
    window.__contrastCache = new Map<string, string>();
  }
  if (!window.__contrastCache.has(key)) {
    const hex = backgroundColor.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    window.__contrastCache.set(key, luminance > 0.5 ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.15)');
  }
  return window.__contrastCache.get(key) ?? 'rgba(0, 0, 0, 0.1)';
};

const PickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

const InputPreview = styled.div<{ 
  $noClientSelected: boolean; 
  $headerTextColor?: string; 
  $isPreview: boolean;
  $disabled: boolean;
  $backgroundColor?: string;
}>`
  padding: 4px 8px;
  display: inline-flex;
  border-radius: 8px;
  margin-left: -8px;
  align-items: flex-start;
  position: relative;
  width: fit-content;
  cursor: ${props => (props.$isPreview || props.$disabled) ? 'default' : 'pointer'};
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
  user-select: none;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  justify-content: space-between;
  color: ${(props) => props.$headerTextColor || (props.$noClientSelected ? 'rgba(0,0,0,0.5)' : 'rgba(0, 0, 0, 0.8)')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;

  &:hover {
    background-color: ${props => (props.$isPreview || props.$disabled) 
      ? 'transparent' 
      : props.$backgroundColor 
        ? memoizedGetContrastColor(props.$backgroundColor)
        : 'rgba(0, 0, 0, 0.05)'};
  }

  &:active {
    transform: ${props => (props.$isPreview || props.$disabled) ? 'none' : 'scale(0.99)'};
  }
`;

const PopoverContainer = styled.div`
  z-index: 2000;
  pointer-events: auto;
`;

const PopoverContent = styled.div<{ $transformOrigin: string; $showBorder: boolean }>`
  transition: transform 0.1s ease, opacity 0.1s ease;
  transform-origin: ${(props) => props.$transformOrigin};
  z-index: 2000;
  border-radius: 12px;
  box-shadow: 
    0 2px 2px rgba(0, 0, 0, 0.05),
    0 4px 4px rgba(0, 0, 0, 0.05),
    0 8px 8px rgba(0, 0, 0, 0.05),
    0 16px 16px rgba(0, 0, 0, 0.05),
    0 32px 32px rgba(0, 0, 0, 0.05),
    0 0 0 0.5px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  pointer-events: auto;
  ${props => props.$showBorder ? '' : 'border: none;'}
`;

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
`;

const ClientOption = styled.div<{ $isSelected: boolean, $isHighlighted?: boolean }>`
  padding: 8px 12px;
  cursor: pointer;
  pointer-events: auto;
  position: relative;
  font-size: 14px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.$isSelected ? "rgba(0, 0, 0, 0.05)" : (props.$isHighlighted ? "rgba(0, 0, 0, 0.1)" : "transparent")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.$isSelected ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.05)"};
  }

  &:active {
    transform: scale(0.99);
  }
`;

const ClientName = styled.span`
  font-weight: inherit;
`;

const ClientAddress = styled.span`
  white-space: pre-line;
`;

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 16px 10px 36px;
  margin: 0;
  border: none;
  box-shadow: inset 0 1px 0 rgba(255,255,255,0.5);
  border-radius: 12px 12px 0 0;
  background: transparent;
  font-size: 14px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  position: sticky;
  top: 0;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  
  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const OptionsContainer = styled.div<{ $showBorder: boolean }>`
  overflow-y: auto;
  max-height: 288px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 4px;
  ${props => props.$showBorder ? 'border-top: 1px solid rgba(0, 0, 0, 0.1);' : ''}
`;

const EditText = styled.span`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  padding: 4px 8px;
  font-weight: 500;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 4px;

  &:hover {
    color: rgba(0, 0, 0, 0.8);
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export interface ClientSelectorProps {
  clients: Client[];
  selectedClientId: string | null;
  onSelectClient: (client: Client | undefined) => void;
  disabled?: boolean;
  className?: string;
  onCreateNewClient: (client?: Client) => void;
  onEditClient?: (client: Client) => void;
  headerTextColor?: string;
  isPreview?: boolean;
  backgroundColor?: string;
}

export const ClientSelector: React.FC<ClientSelectorProps> = ({
  clients: propClients,
  selectedClientId,
  onSelectClient,
  className,
  onCreateNewClient,
  onEditClient,
  headerTextColor,
  isPreview = false,
  disabled = false,
  backgroundColor,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const { data: organizationId } = useOrganization();
  const { data: fetchedClients, isLoading } = useClients();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [editingClient, setEditingClient] = useState<Client | null>(null);
  const [hoveredClientId, setHoveredClientId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const filteredClients = React.useMemo(() => {
    if (isPreview) {
      return propClients;
    }
    const orgClients = fetchedClients?.filter(
      (client: Client) => client.organization_id === organizationId
    ) || [];

    if (!searchTerm) return orgClients;

    const fuse = new Fuse(orgClients, {
      keys: ['full_name', 'company'],
      threshold: 0.3,
    });

    return fuse.search(searchTerm).map(result => result.item);
  }, [isPreview, propClients, fetchedClients, organizationId, searchTerm]);

  const hasClients = filteredClients.length > 0;

  const selectedClient = filteredClients.find(
    (client: Client) => client.id === selectedClientId
  );

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    middleware: [offset(4), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const { styles } = useTransitionStyles(context, {
    initial: { transform: "scale(0.9)", opacity: 0 },
    open: { transform: "scale(1)", opacity: 1 },
    close: { transform: "scale(0.9)", opacity: 0 },
    duration: 100,
  });

  const getTransformOrigin = (placement: Placement) => {
    switch (placement) {
      case "top":
        return "bottom center";
      case "top-start":
        return "bottom left";
      case "top-end":
        return "bottom right";
      case "bottom":
        return "top center";
      case "bottom-start":
        return "top left";
      case "bottom-end":
        return "top right";
      case "left":
        return "right center";
      case "left-start":
        return "right top";
      case "left-end":
        return "right bottom";
      case "right":
        return "left center";
      case "right-start":
        return "left top";
      case "right-end":
        return "left bottom";
      default:
        return "top left";
    }
  };

  const transformOrigin = getTransformOrigin(context.placement);

  const click = useClick(context, { toggle: true });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleSelectClient = (clientId: string) => {
    const selectedClient = filteredClients.find(client => client.id === clientId);
    onSelectClient(selectedClient);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    } else {
      const timeout = setTimeout(() => setIsMounted(false), 150);
      return () => clearTimeout(timeout);
    }
  }, [isOpen]);

  const getFormattedAddress = (client: Client) => {
    const addressParts = [
      client.company,
      client.address_line1,
      client.address_line2,
      `${client.city}${client.state ? `, ${client.state}` : ''} ${client.postal_code}`,
      client.country
    ].filter(Boolean);
    return addressParts.join('\n');
  };

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isOpen) return;

    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        event.preventDefault();
        setHighlightedIndex((prevIndex) => {
          const direction = event.key === 'ArrowDown' ? 1 : -1;
          const nextIndex = prevIndex + direction;
          return Math.max(-1, Math.min(nextIndex, filteredClients.length));
        });
        break;
      case 'Enter':
        event.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < filteredClients.length) {
          handleSelectClient(filteredClients[highlightedIndex].id);
        } else if (highlightedIndex === -1) {
          onCreateNewClient();
        }
        break;
      case 'Escape':
        event.preventDefault();
        setIsOpen(false);
        break;
    }
  }, [isOpen, filteredClients, highlightedIndex, handleSelectClient, onCreateNewClient]);

  useEffect(() => {
    setHighlightedIndex(-1);
  }, [searchTerm, isOpen]);

  useEffect(() => {
    if (isOpen) {
      const container = document.querySelector('.client-selector-popover .options-container');
      const highlightedElement = container?.querySelector(`[data-index="${highlightedIndex}"]`);
      if (highlightedElement) {
        highlightedElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [highlightedIndex, isOpen]);

  const handleEditClick = (client: Client) => {
    if (onEditClient) {
      onEditClient(client);
    }
  };

  const handleClientUpdated = (updatedClient: Client) => {
    // Update the client in the React Query cache
    queryClient.setQueryData(['clients', organizationId], (oldData: Client[] | undefined) => {
      if (!oldData) return [updatedClient];
      return oldData.map(client => client.id === updatedClient.id ? updatedClient : client);
    });

    setIsEditDrawerOpen(false);
    setEditingClient(null);
    
    // If the updated client is the selected client, update the selection
    if (updatedClient.id === selectedClientId) {
      onSelectClient(updatedClient);
    }
  };

  return (
    <PickerWrapper>
      <InputPreview
        ref={isPreview || disabled ? undefined : refs.setReference}
        {...(isPreview || disabled ? {} : getReferenceProps())}
        $noClientSelected={!selectedClientId}
        $headerTextColor={headerTextColor}
        $isPreview={isPreview}
        $disabled={disabled}
        $backgroundColor={backgroundColor}
        className={className}
        onKeyDown={handleKeyDown}
      >
        {selectedClient ? (
          <>
            <ClientName>{selectedClient.full_name}</ClientName>
            <ClientAddress>{getFormattedAddress(selectedClient)}</ClientAddress>
          </>
        ) : (
          <span>{isLoading ? "Loading..." : "Select Client"}</span>
        )}
      </InputPreview>
      {!isPreview && !disabled && (
        <FloatingPortal>
          {isMounted && (
            <PopoverContainer
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className="client-selector-popover"
              onKeyDown={handleKeyDown}
            >
              <PopoverContent 
                style={styles} 
                $transformOrigin={transformOrigin}
                $showBorder={hasClients}
              >
                <SearchInputWrapper>
                  <SearchIconWrapper>
                    <Search12 />
                  </SearchIconWrapper>
                  <SearchInput
                    ref={inputRef}
                    type="text"
                    placeholder="Search clients..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      handleKeyDown(e as React.KeyboardEvent<HTMLDivElement>);
                    }}
                  />
                </SearchInputWrapper>
                <OptionsContainer className="options-container" $showBorder={true}>
                  {filteredClients.map((client: Client, index: number) => (
                    <ClientOption
                      key={client.id}
                      onClick={() => handleSelectClient(client.id)}
                      $isSelected={client.id === selectedClientId}
                      $isHighlighted={index === highlightedIndex}
                      data-index={index}
                      onMouseEnter={() => setHoveredClientId(client.id)}
                      onMouseLeave={() => setHoveredClientId(null)}
                    >
                      <span>{client.full_name}</span>
                      {client.id === selectedClientId && (
                        hoveredClientId === client.id ? (
                          <EditText onClick={(e) => {
                            e.stopPropagation();
                            handleEditClick(client);
                          }}>
                            Edit
                          </EditText>
                        ) : (
                          <CheckIconWrapper>
                            <Checkmark12 />
                          </CheckIconWrapper>
                        )
                      )}
                    </ClientOption>
                  ))}
                  <ClientOption
                    key="create-new"
                    onClick={() => onCreateNewClient()}
                    $isSelected={false}
                    $isHighlighted={highlightedIndex === filteredClients.length}
                    data-index={filteredClients.length}
                  >
                    <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <CheckIconWrapper>
                        <Plus8 />
                      </CheckIconWrapper>
                      New Client
                    </span>
                  </ClientOption>
                </OptionsContainer>
              </PopoverContent>
            </PopoverContainer>
          )}
        </FloatingPortal>
      )}
      <NewClientDrawer
        isOpen={isEditDrawerOpen}
        setIsOpen={setIsEditDrawerOpen}
        onClientAdded={handleClientUpdated}
        editingClient={editingClient}
        organizationId={organizationId}
      />
    </PickerWrapper>
  );
};