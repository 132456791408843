import React from 'react';
import styled, { keyframes } from 'styled-components';
import SidebarRight from '../SidebarRight';

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  position: relative;
`;

const InvoiceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
`;

const InvoiceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  box-sizing: border-box;
`;

const MainContent = styled.div`
  flex: 1;
  max-width: 960px;
  transition: max-width 0.3s ease-in-out;
  background-color: transparent;
  color: #000000;
  font-family: Inter, sans-serif;
  aspect-ratio: 8.5 / 11;
  width: 100%;
  box-shadow: 
    0 8px 8px rgba(0, 0, 0, 0.05),
    0 24px 32px rgba(0, 0, 0, 0.05),
    0 40px 64px rgba(0, 0, 0, 0.05),
    0 64px 80px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0) 100%);
    background-size: 1000px 100%;
    animation: ${shimmer} 2s infinite linear;
  }
`;

const InvoicePageSkeleton: React.FC = () => {
  // Placeholder values for SidebarRight props
  const placeholderProps = {
    invoiceTemplate: 'minimalist' as const,
    headerColor: '#ffffff',
    headerTextColor: '#000000',
    backgroundColor: '#ffffff',
    bodyTextColor: '#000000',
    taxRate: 0,
    font: 'Inter',
    currency: 'USD',
    isVisible: true,
    logoUrl: '',
    updateInvoice: () => {},
    resetToDefaultSettings: () => {},
    organizationId: '',
    projects: [],
    selectedProjectId: null,
    onSelectProject: () => {},
    clients: [],
    selectedClientId: null,
    onSelectClient: () => {},
    invoiceDate: null,
    dueDate: null,
    dueDays: null,
    invoiceNumber: '',
    onInvoiceNumberChange: () => {},
    showLogo: true,
    onToggleShowLogo: () => {},
    collectStripePayments: false,
    onToggleCollectStripePayments: () => {},
    isStripeConnected: true,
    onImmediateColorChange: () => {},
    autoColorText: false,
    onToggleAutoColor: () => {},
    onInvoiceDateChange: () => {},
    onDueDateChange: () => {},
    onDueDaysChange: () => {},
    onGenerateInvoiceNumber: async () => {},
    isGeneratingNumber: false,
    onLogoUpload: async () => {},
    onLogoRemove: async () => {},
  };

  return (
    <PageWrapper>
      <InvoiceContainer>
        <InvoiceWrapper>
          <MainContent>
            {/* Skeleton content for the invoice */}
          </MainContent>
        </InvoiceWrapper>
        <SidebarRight {...placeholderProps} />
      </InvoiceContainer>
    </PageWrapper>
  );
};

export default InvoicePageSkeleton;
