import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '../Button';
import { Close8, Trash12, Document12 } from '../Icon';

const OverlayWrapper = styled.div`
  position: fixed;
  bottom: 32px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
`;

const OverlayContainer = styled(motion.div)`
  max-width: calc(100vw - 40px);
  padding: 8px 8px 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 12px;
  user-select: none;
  background: #FBFAF8;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);
`;

const OverlayContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectedCount = styled.div`
  font-weight: 500;
  margin-right: 4px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
`;

const SelectedCountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CloseButton = styled(Button)`
  background-color: transparent;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  svg path {
    fill: rgba(0, 0, 0, 0.5);
  }
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const DeleteButton = styled(ActionButton)`
  color: #ff4d4f;
  svg path {
    fill: #ff4d4f;
  }
  &:hover {
    color: #ff4d4f;
    background-color: rgba(255, 77, 79, 0.1);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

interface SelectedExpensesOverlayProps {
  selectedCount: number;
  onDelete: () => void;
  onClearSelection: () => void;
  onExportCSV: () => void;
}

const SelectedExpensesOverlay: React.FC<SelectedExpensesOverlayProps> = ({
  selectedCount,
  onDelete,
  onClearSelection,
  onExportCSV,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (selectedCount > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [selectedCount]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClearSelection, 100);
  };

  return (
    <AnimatePresence>
      {(isVisible || selectedCount > 0) && (
        <OverlayWrapper
          as={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{
            duration: 0.1,
            ease: "easeInOut"
          }}
        >
          <OverlayContainer>
            <OverlayContent>
              <SelectedCountWrapper>
                <SelectedCount>{selectedCount} expense{selectedCount !== 1 ? 's' : ''} selected</SelectedCount>
                <CloseButton onClick={handleClose}>
                  <Close8 />
                </CloseButton>
              </SelectedCountWrapper>
              <ButtonGroup>
                <ActionButton onClick={onExportCSV}>
                  <Document12 />
                  Export CSV
                </ActionButton>
                <DeleteButton onClick={onDelete}>
                  <Trash12 />
                  Delete
                </DeleteButton>
              </ButtonGroup>
            </OverlayContent>
          </OverlayContainer>
        </OverlayWrapper>
      )}
    </AnimatePresence>
  );
};

export default SelectedExpensesOverlay; 