import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import EntityPicker from './EntityPicker';
import { motion } from 'framer-motion';

interface TabsContainerProps {
  $compact?: boolean;
}

const TabsContainer = styled.div<TabsContainerProps>`
  display: flex;
  border-bottom: ${props => props.$compact ? '0' : '1px solid rgba(0,0,0,0.05)'};
  padding: ${props => props.$compact ? '0' : '12px 32px 20px 40px'};
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #F8F7F6;

  @media (max-width: 768px) {
    padding: ${props => props.$compact ? '0' : '12px 40px 16px'};
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`;

const Tab = styled.button<{ $active: boolean }>`
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.$active ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)'};
  font-family: inherit;
  transition: color 0.1s ease-in-out;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  z-index: 1;
  border: 1px solid rgba(0,0,0,0.05);

  &:hover {
    color: rgba(0,0,0,0.8);
  }

  &:active {
    transform: scale(0.99);
  }
`;

const TabCount = styled.span<{ $active: boolean }>`
  margin-left: 4px;
  font-size: 12px;
  color: ${props => props.$active ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)'};
  font-weight: 400;
  font-feature-settings: 'tnum';

  ${Tab}:hover & {
    color: rgba(0,0,0,0.8);
  }
`;

const TabLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const EntityPickerWrapper = styled.div`
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
`;

const SkeletonTab = styled.div`
  width: 64px;
  height: 17px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;

  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;

const SkeletonTabsWrapper = styled(TabsWrapper)`
  padding: 16px 0px;
  margin-top: 0px;
`;

const SkeletonTabWithCount = styled.div`
  display: flex;
  align-items: center;
`;

const SkeletonBorder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.5px;
  background-color: rgba(0, 0, 0, 0.1);
`;

interface AnimatedBackgroundProps {
  $compact?: boolean;
}

const AnimatedBackground = styled(motion.div)<AnimatedBackgroundProps>`
  margin: ${props => props.$compact ? '0' : '12px 40px'};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 8px;
  background: rgba(0,0,0,0.05);
  z-index: -1;
`;

const SkeletonTabs: React.FC = () => {
  return (
    <TabsContainer>
      <SkeletonTabsWrapper>
        {[1, 2].map((_, index) => (
          <SkeletonTabWithCount key={index}>
            <SkeletonTab />
          </SkeletonTabWithCount>
        ))}
      </SkeletonTabsWrapper>
      <SkeletonBorder />
    </TabsContainer>
  );
};

interface TabsProps {
  tabs: { id: string; label: string; count?: number; icon?: string }[];
  activeTab: string;
  onTabChange: (tabId: string) => void;
  actionButtons?: React.ReactNode;
  isLoading?: boolean;
  compact?: boolean; // New prop for compact variant
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, onTabChange, actionButtons, isLoading, compact = false }) => {
  const tabRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [activeTabDimensions, setActiveTabDimensions] = useState({ width: 0, left: 0, height: 0 });
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useLayoutEffect(() => {
    const activeTabIndex = tabs.findIndex(tab => tab.id === activeTab);
    const activeTabElement = tabRefs.current[activeTabIndex];
    if (activeTabElement) {
      const { width, left, height } = activeTabElement.getBoundingClientRect();
      const containerLeft = tabRefs.current[0]?.getBoundingClientRect().left || 0;
      setActiveTabDimensions({ width, left: left - containerLeft, height });
    }
  }, [tabs, activeTab]);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  }, [isFirstRender]);

  if (isLoading) {
    return <SkeletonTabs />;
  }

  if (isMobile) {
    return (
      <TabsContainer $compact={compact}>
        <EntityPickerWrapper>
          <EntityPicker
            selectedId={activeTab}
            onChange={(id) => id && onTabChange(id)}
            entities={tabs.map(tab => ({ id: tab.id, name: tab.label, icon: tab.icon }))}
            label="Tab"
            placement="bottom-start"
            enableSearch={false}
          />
        </EntityPickerWrapper>
        {actionButtons && <ActionButtons>{actionButtons}</ActionButtons>}
      </TabsContainer>
    );
  }

  return (
    <TabsContainer $compact={compact}>
      <TabsWrapper>
        <AnimatedBackground
          $compact={compact}
          initial={false}
          animate={{
            width: activeTabDimensions.width,
            height: activeTabDimensions.height,
            x: activeTabDimensions.left,
          }}
          transition={isFirstRender ? { duration: 0 } : { 
            type: "spring", 
            stiffness: 500, 
            damping: 30, 
            mass: 0.5,
            duration: 0.1
          }}
        />
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={tab.id}
              $active={activeTab === tab.id}
              onClick={() => onTabChange(tab.id)}
              ref={el => tabRefs.current[index] = el}
            >
              <TabLabel>
                {tab.label}
                {tab.id !== 'all' && tab.count !== undefined && (
                  <TabCount $active={activeTab === tab.id}>{tab.count}</TabCount>
                )}
              </TabLabel>
            </Tab>
          );
        })}
      </TabsWrapper>
      {actionButtons && <ActionButtons>{actionButtons}</ActionButtons>}
    </TabsContainer>
  );
};

export default Tabs;