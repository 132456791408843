import React, { useState, useMemo, useRef, useCallback } from "react";
import styled, { css } from "styled-components";
import { InvoiceItem, Service, InvoiceData } from "../../types";
import ServiceSelector from "../Services/ServiceSelector";
import { getContrastColor } from '../../utils/contrast';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { Close12, Move12, Checkmark8, Checkmark12} from "../Icon";
import { debounce } from 'lodash';
import Button from "../Button";

function safeNumber(value: number | null | undefined): number {
  if (value === null || value === undefined) return 0;
  return isNaN(value) ? 0 : value;
}

const InvoiceItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InvoiceItemHeader = styled.div<{ $showTaxColumn: boolean }>`
  display: grid;
  grid-template-columns: ${props => props.$showTaxColumn 
    ? 'minmax(300px, 7fr) minmax(50px, 0.5fr) minmax(80px, 1fr) 24px minmax(100px, 1.5fr)'
    : 'minmax(300px, 7fr) minmax(50px, 0.5fr) minmax(80px, 1fr) minmax(100px, 1.5fr)'};
  gap: 16px;
  padding: 0 12px;
  align-items: center;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    grid-template-columns: ${props => props.$showTaxColumn 
      ? '1fr 60px 80px 24px 80px'
      : '1fr 60px 80px 80px'};
    gap: 8px;
    padding: 0 16px;
    font-size: 11px;
  }
`;

const HeaderCell = styled.div<{ $align?: string }>`
  text-align: ${(props) => props.$align || "left"};
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;

const DraggableInvoiceItemBox = styled.div<{ $isDragging: boolean; $backgroundColor: string; $showTaxColumn: boolean }>`
  display: grid;
  grid-template-columns: ${props => props.$showTaxColumn 
    ? 'minmax(300px, 7fr) minmax(50px, 0.5fr) minmax(80px, 1fr) 24px minmax(100px, 1.5fr)'
    : 'minmax(300px, 7fr) minmax(50px, 0.5fr) minmax(80px, 1fr) minmax(100px, 1.5fr)'};
  gap: 16px;
  align-items: center;
  background-color: ${props => props.$isDragging ? props.$backgroundColor : 'transparent'};
  padding: 8px 12px 8px 4px;
  border-radius: 8px;
  position: relative;
  font-variant-numeric: tabular-nums;

  @media (max-width: 768px) {
    grid-template-columns: ${props => props.$showTaxColumn 
      ? '1fr 60px 80px 24px 80px'
      : '1fr 60px 80px 80px'};
    gap: 8px;
    padding: 8px 12px;
    font-size: 14px;
  }

  ${({ $isDragging, $backgroundColor }) =>
    $isDragging &&
    css`
      z-index: 1000;
      position: relative;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 0 1px ${getContrastColor($backgroundColor)};
    `}

  &:hover {
    .hover-button {
      opacity: 1;
    }
  }
`;

const DragHandle = styled.div<{ $backgroundColor: string }>`
  cursor: move;
  display: flex;
  align-items: center;
  width: 28px;
  height: 28px;
  justify-content: center;
  position: absolute;
  left: -24px;
  opacity: 0;

  @media (max-width: 768px) {
    width: 24px;
    height: 24px;
    left: -20px;
  }

  @media (max-width: 480px) {
    width: 20px;
    height: 20px;
    left: -16px;
  }

  svg {
    path {
      fill: ${props => {
        const contrast = getContrastColor(props.$backgroundColor);
        return contrast.includes('255, 255, 255') ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
      }};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${props => {
          const contrast = getContrastColor(props.$backgroundColor);
          return contrast.includes('255, 255, 255') ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)';
        }};
      }
    }
  }
`;

const InvoiceItemField = styled.input<{ $backgroundColor: string; $isPublic?: boolean }>`
  width: 100%;
  padding: 4px 8px;
  margin-left: -8px;
  border: 0;
  box-sizing: border-box;
  outline: none;
  font-size: 16px;
  background: transparent;
  font-family: inherit;
  color: inherit;
  font-variant-numeric: inherit;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 4px;
    margin-left: -4px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 2px 4px;
    font-size: 13px;
  }

  &:focus {
    background: ${props => getContrastColor(props.$backgroundColor)};
    box-shadow: inset 0 0 0 1px ${props => getContrastColor(props.$backgroundColor)};
  }

  &:hover {
    background: ${props => !props.$isPublic ? getContrastColor(props.$backgroundColor) : 'transparent'};
  }

  &::placeholder {
    color: ${props => {
      const contrast = getContrastColor(props.$backgroundColor);
      return contrast.includes('255, 255, 255') ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
    }};
  }
`;

const InvoiceItemButton = styled.button<{ $backgroundColor: string }>`
  width: auto;
  position: absolute;
  right: -24px;
  opacity: 0;
  height: 28px;
  width: 28px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  outline: none;

  &:focus {
    box-shadow: 0 0 0 1px ${props => getContrastColor(props.$backgroundColor)};
    background: ${props => getContrastColor(props.$backgroundColor)};
  }

  @media (max-width: 768px) {
    right: -20px;
    height: 24px;
    width: 24px;
  }

  @media (max-width: 480px) {
    right: -16px;
    height: 20px;
    width: 20px;
  }

  svg {
    path {
      fill: ${props => {
        const contrast = getContrastColor(props.$backgroundColor);
        return contrast.includes('255, 255, 255') ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
      }};
    }
  }

  &:hover {
    background: ${props => getContrastColor(props.$backgroundColor)};

    svg {
      path {
        fill: ${props => {
          const contrast = getContrastColor(props.$backgroundColor);
          return contrast.includes('255, 255, 255') ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)';
        }};
      }
    }
  }
`;

const CustomCheckbox = styled.div<{ $checked: boolean; $backgroundColor: string }>`
  width: 14px;
  height: 14px;
  border: 1px solid ${props => {
    const contrastColor = getContrastColor(props.$backgroundColor);
    return contrastColor === 'rgba(0, 0, 0, 0.05)' ? 'rgba(0, 0, 0, 0.15)' : 'rgba(255, 255, 255, 0.5)';
  }};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 12px;
    height: 12px;
  }

  @media (max-width: 480px) {
    width: 10px;
    height: 10px;
  }

  svg {
    path {
      fill: ${props => props.$checked 
        ? (getContrastColor(props.$backgroundColor) === 'rgba(0, 0, 0, 0.05)' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)') 
        : 'transparent'};
    }
  }

  &:hover {
    border: 1px solid ${props => {
      const contrastColor = getContrastColor(props.$backgroundColor);
      return contrastColor === 'rgba(0, 0, 0, 0.05)' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)';
    }};
  }
`;

const AmountCell = styled.div`
  text-align: right;
  white-space: nowrap;
  min-width: 0;
  overflow: visible;
`;

const AddItemRow = styled.div`
  padding: 12px;
  display: flex;
  justify-content: flex-start;
`;

const AddItemButton = styled(Button)<{ $backgroundColor: string }>`
  color: inherit;
  background-color: ${props => getContrastColor(props.$backgroundColor)};
`;

interface InvoiceItemsProps {
  invoice: InvoiceData;
  services: Service[];
  handleServiceSelect: (service: Service, itemId: string) => void;
  handleDescriptionChange: (value: string, itemId: string) => void;
  updateItem: (
    itemId: string,
    field: keyof InvoiceItem,
    value: string | number | boolean
  ) => void;
  deleteItem: (itemId: string) => void;
  reorderItems?: (items: InvoiceItem[]) => void;
  isReadOnly?: boolean;
  onCreateNewService: () => void;
  backgroundColor: string;
  addItem: () => void;
  isPublic?: boolean;
}

interface SortableItemProps {
  item: InvoiceItem;
  services: Service[];
  handleServiceSelect: (service: Service, itemId: string) => void;
  handleDescriptionChange: (value: string, itemId: string) => void;
  updateItem: (
    itemId: string,
    field: keyof InvoiceItem,
    value: string | number | boolean
  ) => void;
  deleteItem: (itemId: string) => void;
  isReadOnly?: boolean;
  onCreateNewService: () => void;
  backgroundColor: string;
  isPublic?: boolean;
  showTaxColumn: boolean;
}

const SortableItem: React.FC<SortableItemProps> = ({
  item,
  services,
  handleServiceSelect,
  handleDescriptionChange,
  updateItem,
  deleteItem,
  isReadOnly,
  onCreateNewService,
  backgroundColor,
  isPublic,
  showTaxColumn,
}) => {
  const [localItem, setLocalItem] = useState(item);

  const debouncedDescriptionChange = useRef(
    debounce((value: string, itemId: string) => {
      handleDescriptionChange(value, itemId);
    }, 300)
  ).current;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id! });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleLocalUpdate = (
    field: keyof InvoiceItem,
    value: string | number | boolean
  ) => {
    if (isReadOnly) return;
    let safeValue: string | number | boolean;
    
    if (field === 'quantity' || field === 'price') {
      safeValue = value === '' ? 0 : safeNumber(value as number | null | undefined);
    } else {
      safeValue = value;
    }
    
    setLocalItem((prev) => ({ ...prev, [field]: safeValue }));
    updateItem(item.id!, field, safeValue);
  };

  const handleLocalServiceSelect = (service: Service) => {
    if (isReadOnly) return;
    const updatedItem = {
      ...localItem,
      description: service.name,
      price: service.price ?? 0,
      taxable: service.taxable ?? false,
    };
    setLocalItem(updatedItem);
    handleServiceSelect(service, item.id!);
    // Update all changed fields
    Object.entries(updatedItem).forEach(([key, value]) => {
      if (key !== 'id' && value !== item[key as keyof InvoiceItem]) {
        // Check if the value is of the correct type before updating
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
          updateItem(item.id!, key as keyof InvoiceItem, value);
        }
      }
    });
  };

  const handleLocalDescriptionChange = useCallback((value: string) => {
    if (isReadOnly) return;
    setLocalItem((prev) => ({ ...prev, description: value }));
    debouncedDescriptionChange(value, item.id!);
  }, [isReadOnly, item.id, debouncedDescriptionChange]);

  const itemTotal = (safeNumber(localItem.quantity) ?? 0) * (safeNumber(localItem.price) ?? 0);

  return (
    <DraggableInvoiceItemBox
      ref={setNodeRef}
      style={style}
      $isDragging={isDragging}
      $backgroundColor={backgroundColor}
      $showTaxColumn={showTaxColumn}
    >
      {!isReadOnly && (
        <DragHandle className="hover-button" {...attributes} {...listeners} $backgroundColor={backgroundColor}>
          <span>
            <Move12 />
          </span>
        </DragHandle>
      )}
      <ServiceSelector
        services={services}
        onSelect={handleLocalServiceSelect}
        onInputChange={handleLocalDescriptionChange}
        initialValue={localItem.description || ""}
        onCreateNewService={onCreateNewService}
        disabled={isReadOnly}
        backgroundColor={backgroundColor}
        isPublic={isPublic}
      />
      <InvoiceItemField
        type="text"
        value={localItem.quantity === 0 ? '' : localItem.quantity}
        onChange={(e) =>
          handleLocalUpdate("quantity", e.target.value)
        }
        placeholder="0"
        readOnly={isReadOnly}
        $backgroundColor={backgroundColor}
        $isPublic={isPublic}
      />
      <InvoiceItemField
        type="text"
        value={localItem.price === 0 ? '' : localItem.price}
        onChange={(e) => handleLocalUpdate("price", e.target.value)}
        placeholder="0"
        readOnly={isReadOnly}
        $backgroundColor={backgroundColor}
        $isPublic={isPublic}
      />
      {showTaxColumn && (
        <div style={{ textAlign: "center" }}>
          {isReadOnly ? (
            <span>{localItem.taxable ? <Checkmark12 /> : ' '}</span>
          ) : (
            <CustomCheckbox
              $checked={localItem.taxable ?? false}
              $backgroundColor={backgroundColor}
              onClick={() => handleLocalUpdate("taxable", !localItem.taxable)}
            >
              <Checkmark8 />
            </CustomCheckbox>
          )}
        </div>
      )}
      <AmountCell>
        ${itemTotal.toFixed(2)}
      </AmountCell>
      {!isDragging && !isReadOnly && (
        <InvoiceItemButton
          className="hover-button"
          onClick={() => item.id && deleteItem(item.id)}
          $backgroundColor={backgroundColor}
        >
          <Close12 />
        </InvoiceItemButton>
      )}
    </DraggableInvoiceItemBox>
  );
};

const InvoiceItems: React.FC<InvoiceItemsProps> = ({
  invoice,
  services,
  handleServiceSelect,
  handleDescriptionChange,
  updateItem,
  deleteItem,
  reorderItems,
  isReadOnly,
  onCreateNewService,
  backgroundColor,
  addItem,
  isPublic,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor)
  );

  const hasTaxableItems = useMemo(() => {
    return invoice.items.some(item => item.taxable);
  }, [invoice.items]);

  const showTaxColumn = !isReadOnly || hasTaxableItems;

  const handleDragEnd = (event: DragEndEvent) => {
    if (!reorderItems || isReadOnly) return;

    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = invoice.items.findIndex((item) => item.id === active.id);
      const newIndex = invoice.items.findIndex((item) => item.id === over?.id);

      const newItems = [...invoice.items];
      const [reorderedItem] = newItems.splice(oldIndex, 1);
      newItems.splice(newIndex, 0, reorderedItem);

      // Reassign order values
      const updatedItems = newItems.map((item, index) => ({
        ...item,
        order: index,
      }));

      reorderItems(updatedItems);
    }
  };

  const sortedItems = useMemo(() => {
    return [...invoice.items].sort((a, b) => a.order - b.order);
  }, [invoice.items]);

  const content = (
    <InvoiceItemsContainer>
      <InvoiceItemHeader $showTaxColumn={showTaxColumn}>
        <HeaderCell>Description</HeaderCell>
        <HeaderCell>Quantity</HeaderCell>
        <HeaderCell>Unit Price</HeaderCell>
        {showTaxColumn && <HeaderCell style={{ textAlign: "left" }}>Tax</HeaderCell>}
        <HeaderCell $align="right">Amount</HeaderCell>
      </InvoiceItemHeader>
      {sortedItems.map((item) => (
        <SortableItem
          key={item.id}
          item={item}
          services={services}
          handleServiceSelect={handleServiceSelect}
          handleDescriptionChange={handleDescriptionChange}
          updateItem={updateItem}
          deleteItem={deleteItem}
          isReadOnly={isReadOnly}
          onCreateNewService={onCreateNewService}
          backgroundColor={backgroundColor}
          isPublic={isPublic}
          showTaxColumn={showTaxColumn}
        />
      ))}
    </InvoiceItemsContainer>
  );

  return (
    <>
      {reorderItems && !isReadOnly ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext
            items={invoice.items.map((item) => item.id!)}
            strategy={verticalListSortingStrategy}
          >
            {content}
          </SortableContext>
        </DndContext>
      ) : (
        content
      )}
      {!isReadOnly && (
        <AddItemRow>
          <AddItemButton 
            onClick={() => addItem()} 
            $backgroundColor={backgroundColor}
          >
            Add Item
          </AddItemButton>
        </AddItemRow>
      )}
    </>
  );
};

export default InvoiceItems;
