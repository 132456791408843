import { createClient } from '@supabase/supabase-js';

const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL;
const SUPABASE_KEY = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!SUPABASE_URL || !SUPABASE_KEY) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(SUPABASE_URL, SUPABASE_KEY, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  },
  db: {
    schema: 'public'
  },
  global: {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }
});

const MAX_RETRIES = 5;
const RETRY_DELAY = 2000; // 2 seconds

let realtimeChannel: ReturnType<typeof supabase.channel> | null = null;
let retryCount = 0;
let isReconnecting = false;

export const setupRealtimeSubscription = () => {
  if (realtimeChannel) {
    console.log('Realtime subscription already exists. Skipping setup.');
    return;
  }

  if (isReconnecting) {
    console.log('Already attempting to reconnect. Skipping setup.');
    return;
  }

  console.log('Setting up realtime subscription...');
  realtimeChannel = supabase.channel('global_updates');

  realtimeChannel
    .on('postgres_changes', { event: '*', schema: 'public', table: 'tasks' }, (payload) => {
      console.log('Task change:', payload);
      // Emit a custom event with the payload
      window.dispatchEvent(new CustomEvent('TASK_UPDATED', { detail: payload }));
    })
    .subscribe((status, error) => {
      console.log(`Subscription status: ${status}`);
      if (error) {
        console.error('Subscription error:', error);
        retryConnection();
        return;
      }
      if (status === 'SUBSCRIBED') {
        console.log('Realtime subscription established');
        retryCount = 0; // Reset retry count on successful subscription
        isReconnecting = false;
      } else if (status === 'CLOSED') {
        console.log('Realtime CLOSED, attempting to reconnect...');
        retryConnection();
      }
    });
};

export const teardownRealtimeSubscription = () => {
  if (realtimeChannel) {
    console.log('Tearing down realtime subscription...');
    realtimeChannel.unsubscribe().then(() => {
      realtimeChannel = null;
      retryCount = 0; // Reset retry count
      isReconnecting = false;
      console.log('Realtime subscription torn down');
    }).catch((error) => {
      console.error('Error unsubscribing:', error);
    });
  }
};

const retryConnection = () => {
  if (isReconnecting) {
    console.log('Already attempting to reconnect. Skipping retry.');
    return;
  }

  if (retryCount >= MAX_RETRIES) {
    console.error('Max retries reached. Unable to establish a stable connection.');
    teardownRealtimeSubscription(); // Clean up the subscription
    return;
  }

  isReconnecting = true;
  retryCount++;
  console.log(`Retry attempt ${retryCount} of ${MAX_RETRIES}`);
  setTimeout(() => {
    console.log('Reconnecting...');
    teardownRealtimeSubscription();
    setupRealtimeSubscription();
  }, RETRY_DELAY);
};

// // Function to check Supabase connection
// const checkSupabaseConnection = async () => {
//   try {
//     const { error } = await supabase.from('tasks').select('id').limit(1);
//     return !error;
//   } catch (error) {
//     console.error('Supabase connection error:', error);
//     return false;
//   }
// };

// // Check initial connection
// checkSupabaseConnection().then((isConnected) => {
//   if (isConnected) {
//     console.log('Supabase connection established');
//   } else {
//     console.error('Failed to connect to Supabase. Please check your configuration.');
//   }
// });

// Log current Realtime connection status periodically
type ConnectionState = 'CONNECTING' | 'OPEN' | 'CLOSING' | 'CLOSED';

const connectionStateMap: Record<ConnectionState, string> = {
  'CONNECTING': 'Connecting',
  'OPEN': 'Open',
  'CLOSING': 'Closing',
  'CLOSED': 'Closed'
};

setInterval(() => {
  const state = supabase.realtime.connectionState() as unknown as ConnectionState;
  console.log('Current Realtime connection status:', connectionStateMap[state] || state);
}, 10000);