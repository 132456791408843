import { z } from 'zod';

const dateSchema = z.string().refine(
  (date) => {
    if (!date) return true;
    const parsed = new Date(date);
    return !isNaN(parsed.getTime());
  },
  {
    message: "Invalid date format"
  }
);

export const projectStatusSchema = z.enum([
  'planning',
  'in_progress',
  'on_hold',
  'completed'
]);

export const projectSchema = z.object({
  id: z.string().optional(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  client_id: z.string().nullable(),
  created_at: dateSchema,
  is_active: z.boolean(),
  user_id: z.string(),
  status: z.string().nullable(),
  due_date: dateSchema.nullable(),
  organization_id: z.string().min(1, 'Organization is required'),
}).refine(
  (data) => {
    if (data.due_date) {
      const dueDate = new Date(data.due_date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return dueDate >= today;
    }
    return true;
  },
  {
    message: "Due date cannot be in the past",
    path: ["due_date"]
  }
); 