import { z } from 'zod';

// Enhanced phone regex that accepts formats like:
// +1 (555) 555-5555
// 555-555-5555
// (555) 555-5555
// 5555555555
// +1 555-555-5555
// +1 555.555.5555
const phoneRegex = /^(\+?\d{1,3}[-.\s]?)?\(?([0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/;

const formatPhoneNumber = (phone: string): string => {
  // Remove all non-digit characters
  const digits = phone.replace(/\D/g, '');
  
  // Handle different formats based on length
  if (digits.length === 10) {
    // Format: (555) 555-5555
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
  } else if (digits.length === 11 && digits.startsWith('1')) {
    // Format: +1 (555) 555-5555
    return `+1 (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(7)}`;
  } else if (digits.length === 11) {
    // Format: +X (555) 555-5555
    return `+${digits.slice(0, 1)} (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(7)}`;
  }
  return phone; // Return original if no format matches
};

// Enhanced email validation
const emailSchema = z.string()
  .refine((email) => {
    if (!email) return true; // Allow empty string
    // Additional email validation rules:
    // - Must contain @ and .
    // - No consecutive dots
    // - Valid TLD (2+ chars)
    // - No spaces
    // - Basic length check
    return (
      email.includes('@') &&
      email.includes('.') &&
      !email.includes('..') &&
      email.split('@')[1]?.includes('.') &&
      !email.includes(' ') &&
      email.length >= 5 &&
      email.length <= 254 &&
      /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email)
    );
  }, 'Please enter a valid email address')
  .nullable()
  .refine((email) => {
    if (!email || email === '') return true; // Allow empty string or null
    if (!email.includes('@')) return false;
    
    // Common disposable email domains
    const disposableDomains = [
      'tempmail.com',
      'throwawaymail.com',
      'mailinator.com',
      'temp-mail.org',
      'guerrillamail.com'
    ];
    
    const domain = email.split('@')[1];
    if (!domain) return false;
    
    return !disposableDomains.includes(domain.toLowerCase());
  }, 'Please use a valid business email address')
  .optional();

// Country definitions with their details
export const countries = {
  US: { name: 'United States', code: 'US', pattern: /^\d{5}(-\d{4})?$/, label: 'ZIP Code', example: '12345 or 12345-6789' },
  CA: { name: 'Canada', code: 'CA', pattern: /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i, label: 'Postal Code', example: 'A1A 1A1' },
  UK: { name: 'United Kingdom', code: 'UK', pattern: /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i, label: 'Post Code', example: 'SW1A 1AA' },
  AU: { name: 'Australia', code: 'AU', pattern: /^\d{4}$/, label: 'Post Code', example: '2000' },
  NZ: { name: 'New Zealand', code: 'NZ', pattern: /^\d{4}$/, label: 'Post Code', example: '6011' },
  FR: { name: 'France', code: 'FR', pattern: /^\d{5}$/, label: 'Code Postal', example: '75001' },
  DE: { name: 'Germany', code: 'DE', pattern: /^\d{5}$/, label: 'Postleitzahl', example: '10115' },
  IT: { name: 'Italy', code: 'IT', pattern: /^\d{5}$/, label: 'Codice Postale', example: '00100' },
  ES: { name: 'Spain', code: 'ES', pattern: /^\d{5}$/, label: 'Código Postal', example: '28001' },
  PT: { name: 'Portugal', code: 'PT', pattern: /^\d{4}-\d{3}$/, label: 'Código Postal', example: '1000-205' },
  NL: { name: 'Netherlands', code: 'NL', pattern: /^\d{4}\s?[A-Z]{2}$/, label: 'Postcode', example: '1234 AB' },
  BE: { name: 'Belgium', code: 'BE', pattern: /^\d{4}$/, label: 'Code Postal', example: '1000' },
  CH: { name: 'Switzerland', code: 'CH', pattern: /^\d{4}$/, label: 'Postal Code', example: '1200' },
  AT: { name: 'Austria', code: 'AT', pattern: /^\d{4}$/, label: 'Postleitzahl', example: '1010' },
  SE: { name: 'Sweden', code: 'SE', pattern: /^\d{3}\s?\d{2}$/, label: 'Postnummer', example: '123 45' },
  NO: { name: 'Norway', code: 'NO', pattern: /^\d{4}$/, label: 'Postnummer', example: '0001' },
  DK: { name: 'Denmark', code: 'DK', pattern: /^\d{4}$/, label: 'Postnummer', example: '1000' },
  FI: { name: 'Finland', code: 'FI', pattern: /^\d{5}$/, label: 'Postinumero', example: '00100' },
  IE: { name: 'Ireland', code: 'IE', pattern: /^[A-Z]\d{2}\s?[A-Z\d]{4}$/, label: 'Eircode', example: 'D02 AF30' },
  JP: { name: 'Japan', code: 'JP', pattern: /^\d{3}-\d{4}$/, label: '郵便番号', example: '123-4567' },
  KR: { name: 'South Korea', code: 'KR', pattern: /^\d{5}$/, label: '우편번호', example: '12345' },
  SG: { name: 'Singapore', code: 'SG', pattern: /^\d{6}$/, label: 'Postal Code', example: '238823' },
  // Add more countries as needed
} as const;

// Create a type for country codes
export type CountryCode = keyof typeof countries;

// Create a Zod enum for country codes
export const countryEnum = z.enum([...Object.keys(countries)] as [CountryCode, ...CountryCode[]]);

// Helper function to get postal code validation details
const getPostalCodeValidation = (country: CountryCode) => {
  const countryDetails = countries[country];
  return {
    pattern: countryDetails.pattern,
    message: `${countryDetails.name} ${countryDetails.label} must be in format: ${countryDetails.example}`,
    label: countryDetails.label
  };
};

// Update the base schema to use the country enum
const baseSchema = z.object({
  id: z.string().optional(),
  organization_id: z.string().optional(),
  full_name: z.string().optional(),
  email: z.string().nullable().optional(),
  phone: z.string()
    .refine((phone) => {
      if (!phone) return true; // Allow empty string
      return phoneRegex.test(phone);
    }, 'Please enter a valid phone number (e.g., (555) 555-5555)')
    .transform((phone) => phone ? formatPhoneNumber(phone) : null)
    .nullable()
    .optional(),
  company: z.string().nullable().optional(),
  address_line1: z.string().nullable().optional(),
  address_line2: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  country: countryEnum.nullable().optional(),
  postal_code: z.string().nullable().optional(),
  notes: z.string().optional(),
  default_currency: z.string().optional(),
  is_active: z.boolean().default(true).optional(),
  created_at: z.coerce.date().optional(),
  updated_at: z.coerce.date().optional()
});

export const clientSchema = baseSchema.superRefine((data, ctx) => {
  // Validate postal code based on country
  if (data.postal_code && data.country) {
    const validation = getPostalCodeValidation(data.country);
    
    if (!validation.pattern.test(data.postal_code)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: validation.message,
        path: ['postal_code']
      });
    }
  }

  // Only validate email and phone if they are both provided and not empty
  if (data.email && data.phone && data.email !== '' && data.phone !== '') {
    const emailResult = emailSchema.safeParse(data.email);
    const phoneResult = phoneRegex.test(data.phone);
    
    if (!emailResult.success || !phoneResult) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Both email and phone must be valid if provided",
        path: ["email"]
      });
    }
  }
});

// Export helper function to get postal code label based on country
export const getPostalCodeLabel = (country: string | null): string => {
  if (!country || !(country in countries)) return 'Postal Code';
  return countries[country as CountryCode].label;
};

// Export helper function to get country options for select input
export const getCountryOptions = () => {
  return Object.entries(countries).map(([code, details]) => ({
    value: code,
    label: details.name
  })).sort((a, b) => a.label.localeCompare(b.label));
};

export type ClientSchema = z.infer<typeof clientSchema>; 