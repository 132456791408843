import React, { useMemo } from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';

const TimeWrapper = styled.div<{ $isInvoiced: boolean }>`
  padding: 4px 8px;
  text-align: right;
  font-feature-settings: 'tnum';
  opacity: ${props => props.$isInvoiced ? '0.5' : '1'};
  transition: opacity 0.1s ease-in-out;
`;

interface TimeDisplayProps {
  startTime: string;
  endTime?: string;
  isInvoiced: boolean;
}

const TimeDisplay: React.FC<TimeDisplayProps> = React.memo(({ startTime, endTime, isInvoiced }) => {
  const formatTime = useMemo(() => (time: string) => format(parseISO(time), 'h:mma').toLowerCase(), []);

  return (
    <TimeWrapper $isInvoiced={isInvoiced}>
      {formatTime(startTime)} - {endTime ? formatTime(endTime) : 'Running'}
    </TimeWrapper>
  );
});

export default TimeDisplay;