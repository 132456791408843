import { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import Button from "./components/Button";
import { Expense, ExpenseInvoiceItem } from "./types";
import AddExpenseDrawer from "./components/Expenses/AddExpenseDrawer";
import { useNavigate, Link } from '@tanstack/react-router';
import { useOrganization } from "./hooks/useOrganization";
import DataTable from "./components/DataTable";
import { ColumnDef, SortingState, OnChangeFn } from '@tanstack/react-table';
import DateRangePicker from "./components/DateRangePicker";
import { Calendar12, Expense32 } from './components/Icon';
import { isWithinInterval, startOfDay, endOfDay, subDays, isAfter, format, isThisYear } from 'date-fns';
import Tabs from "./components/Tabs";
import FilterDrawer from "./components/FilterDrawer";
import FilterBox from "./components/FilterBox";
import FilterPicker from "./components/FilterPicker";
import { Filter, FilterOption, FilterId } from './types';
import { useMediaQuery } from './hooks/useMediaQuery';
import MultipleEntityPicker from './components/MultipleEntityPicker';
import { Client12 } from './components/Icon';
import { getDateRange } from './utils/dateUtils';
import { usePageContext } from './hooks/usePageContext';
import SummaryCards from "./components/SummaryCards";
import { EXPENSE_CATEGORIES } from './types';
import { Status12 } from './components/Icon';
import { useExpenses } from "./hooks/useExpenses";
import { useExpenseMutations } from "./hooks/useExpenseMutations";
import SelectedExpensesOverlay from './components/Expenses/SelectedExpensesOverlay';
import { exportExpensesToCSV, downloadCSV } from './utils/csvExport';
import { formatCurrency } from './utils/formatters';
import ConfirmationModal from './components/ConfirmationModal';
import { useCreateInvoiceMutation } from './hooks/useCreateInvoiceMutation';

const PageContainer = styled.div`
  padding: 0px;
  height: calc(100vh - 60px);
  overflow-y: auto;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
`;

const FilterBoxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const ExpensesPage: React.FC = () => {
  const queryClient = useQueryClient();
  const [selectedExpenses, setSelectedExpenses] = useState<string[]>([]);
  const navigate = useNavigate();
  const { data: organizationId } = useOrganization();
  const [editingExpense, setEditingExpense] = useState<Expense | null>(null);
  const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
    startDate: null,
    endDate: null,
  });
  const [activeTab, setActiveTab] = useState("all");
  const containerRef = useRef<HTMLDivElement>(null);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState<Filter[]>([]);
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const { setPageHeaderProps } = usePageContext();
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'date', desc: true }
  ]);
  const [isLast30DaysSelected, setIsLast30DaysSelected] = useState(false);
  const [isExpenseDrawerOpen, setIsExpenseDrawerOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // Add new state for local expenses
  const isInitialMount = useRef(true);
  const initializedFromCache = useRef(false);
  const localUpdatesRef = useRef(new Map<string, boolean>());
  const [localExpenses, setLocalExpenses] = useState<Expense[]>(() => {
    const cachedExpenses = queryClient.getQueryData(['expenses', organizationId]) as Expense[] | undefined;
    if (cachedExpenses) {
      initializedFromCache.current = true;
      return sortExpenses(cachedExpenses);
    }
    return [];
  });

  const { data: expenses, isLoading } = useExpenses();
  const { updateExpenseMutation, createExpenseMutation, deleteExpenseMutation, bulkDeleteExpensesMutation } = useExpenseMutations();
  const createInvoiceMutation = useCreateInvoiceMutation();

  // Add sort function
  const sortExpenses = useCallback((expenses: Expense[]) => {
    return [...expenses].sort((a, b) => 
      new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }, []);

  // Update expenses effect to handle initial mount
  useEffect(() => {
    if (expenses) {
      if (isInitialMount.current || !initializedFromCache.current) {
        setLocalExpenses(sortExpenses(expenses));
        initializedFromCache.current = true;
      }
      isInitialMount.current = false;
    }
  }, [expenses, sortExpenses]);

  // Add updateLocalExpense function
  const updateLocalExpense = useCallback((updatedExpense: Partial<Expense>) => {
    setLocalExpenses(prev =>
      prev.map(expense =>
        expense.id === updatedExpense.id ? { ...expense, ...updatedExpense } : expense
      )
    );
  }, []);

  // Update handleEditExpense to use local state
  const handleEditExpense = useCallback((expense: Expense) => {
    setEditingExpense(expense);
    setIsExpenseDrawerOpen(true);
  }, []);

  // Update handleSaveExpense to handle local updates
  const handleSaveExpense = useCallback((updatedExpense: Expense) => {
    if (!organizationId) {
      console.error('No organization ID found');
      return;
    }

    // Update local state immediately
    updateLocalExpense(updatedExpense);

    // Update server state with debounce
    updateExpenseMutation.mutate(updatedExpense, {
      onSuccess: (savedExpense) => {
        console.log('Expense saved successfully:', savedExpense);
        if (updatedExpense.id) {
          localUpdatesRef.current.delete(updatedExpense.id);
        }
      },
      onError: (error) => {
        console.error('Failed to save expense:', error);
        alert('Failed to save expense. Please try again.');
      },
    });
  }, [organizationId, updateExpenseMutation, updateLocalExpense]);

  const memoizedExpenses = useMemo(() => {
    if (!localExpenses) return [];
    
    let filtered = localExpenses;

    // Apply filters
    filters.forEach((filter: Filter) => {
      switch (filter.id) {
        case 'status': {
          const statusFilter = filter.value as string;
          filtered = filtered.filter(expense => 
            statusFilter === 'Invoiced' 
              ? expense.expense_invoice_items && expense.expense_invoice_items.length > 0
              : !expense.expense_invoice_items || expense.expense_invoice_items.length === 0
          );
          break;
        }
        case 'client': {
          const clientFilter = filter.value as string[];
          filtered = filtered.filter(expense => 
            expense.client_id && clientFilter.includes(expense.client_id)
          );
          break;
        }
        case 'date': {
          const dateFilter = filter.value as { startDate: Date | null; endDate: Date | null } | string;
          if (typeof dateFilter === 'object' && 'startDate' in dateFilter && 'endDate' in dateFilter) {
            const { startDate, endDate } = dateFilter;
            if (startDate && endDate) {
              filtered = filtered.filter(expense => {
                const expenseDate = new Date(expense.date);
                return isWithinInterval(expenseDate, { 
                  start: startOfDay(startDate), 
                  end: endOfDay(endDate) 
                });
              });
            }
          } else if (typeof dateFilter === 'string') {
            const { start, end } = getDateRange(dateFilter);
            filtered = filtered.filter(expense => {
              const expenseDate = new Date(expense.date);
              return isWithinInterval(expenseDate, { start, end });
            });
          }
          break;
        }
        case 'category': {
          const categoryFilter = filter.value as string[];
          filtered = filtered.filter(expense => 
            expense.category && categoryFilter.includes(expense.category.split(':')[0])
          );
          break;
        }
      }
    });

    // Filter by last 30 days if selected
    if (isLast30DaysSelected) {
      const thirtyDaysAgo = subDays(new Date(), 30);
      filtered = filtered.filter(expense => 
        isAfter(new Date(expense.date), thirtyDaysAgo)
      );
    }

    // Filter by date range
    if (dateRange.startDate && dateRange.endDate) {
      const start = dateRange.startDate;
      const end = dateRange.endDate;
      if (start instanceof Date && end instanceof Date) {
        filtered = filtered.filter(expense => {
          const expenseDate = new Date(expense.date);
          return isWithinInterval(expenseDate, {
            start: startOfDay(start),
            end: endOfDay(end)
          });
        });
      }
    }

    // Filter by tab
    switch (activeTab) {
      case "notInvoiced":
        filtered = filtered.filter(expense => !expense.expense_invoice_items || expense.expense_invoice_items.length === 0);
        break;
      case "invoiced":
        filtered = filtered.filter(expense => expense.expense_invoice_items && expense.expense_invoice_items.length > 0);
        break;
      // "all" tab doesn't need filtering
    }

    // Apply sorting
    if (sorting.length > 0) {
      const { id, desc } = sorting[0];
      filtered = [...filtered].sort((a, b) => {
        if (id === 'date') {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return desc ? dateB.getTime() - dateA.getTime() : dateA.getTime() - dateB.getTime();
        }
        // Add more sorting logic for other columns if needed
        return 0;
      });
    }

    return filtered;
  }, [localExpenses, dateRange, activeTab, filters, sorting, isLast30DaysSelected]);

  const handleCloseExpenseDrawer = useCallback(() => {
    setIsExpenseDrawerOpen(false);
    setEditingExpense(null);
  }, []);

  const handleCreateExpense = useCallback(async (expenseData: Partial<Expense>): Promise<Expense> => {
    if (!organizationId) {
      throw new Error("No organization selected. Please select an organization and try again.");
    }

    const newExpense = await createExpenseMutation.mutateAsync({
      ...expenseData,
      organization_id: organizationId,
    } as Omit<Expense, 'id'>);

    // Update both query cache and local state
    queryClient.setQueryData(['expenses', organizationId], (old: Expense[] | undefined) => 
      old ? [...old, newExpense] : [newExpense]
    );
    setLocalExpenses(prev => sortExpenses([...prev, newExpense]));

    return newExpense;
  }, [organizationId, createExpenseMutation, queryClient, sortExpenses]);

  const handleDeleteExpense = useCallback((expenseId: string) => {
    deleteExpenseMutation.mutate(expenseId, {
      onSuccess: () => {
        queryClient.setQueryData(['expenses', organizationId], (old: Expense[] | undefined) => 
          old ? old.filter(expense => expense.id !== expenseId) : []
        );
        setIsExpenseDrawerOpen(false);
        setEditingExpense(null);
      },
      onError: (error) => {
        console.error('Failed to delete expense:', error);
        alert('Failed to delete expense. Please try again.');
      },
    });
  }, [deleteExpenseMutation, queryClient, organizationId]);

  // {{ edit_1 }} Add Category Entities
  const categoryEntities = useMemo(() => {
    return Object.keys(EXPENSE_CATEGORIES).map(category => ({
      id: category,
      name: category
    }));
  }, []);

  // {{ edit_2 }} Update filterOptions to include 'category'
  const filterOptions: FilterOption[] = useMemo(() => [
    { id: 'status', label: 'Status', type: 'select', options: ['Not Invoiced', 'Invoiced'] },
    { id: 'client', label: 'Client', type: 'multipleEntity' },
    { 
      id: 'date', 
      label: 'Date', 
      type: 'dateRange',
      options: [
        'Last Week',
        'Last 30 Days',
        'This Month',
        'Last Month',
        'Last 6 Months',
        'Last Year'
      ]
    },
    { // {{ edit_3 }} Add Category Filter Option
      id: 'category',
      label: 'Category',
      type: 'multipleEntity',
      options: Object.keys(EXPENSE_CATEGORIES) // Dynamically generate category options
    },
  ], []);

  const getFilterOptions = (filterId: string) => {
    const filter = filterOptions.find(f => f.id === filterId);
    return filter?.options;
  };

  const handleAddFilter = useCallback((filterId: FilterId) => {
    if (!filters.some(filter => filter.id === filterId)) {
      const filterOption = filterOptions.find(option => option.id === filterId);
      if (filterOption) {
        const newFilter: Filter = {
          id: filterId,
          label: filterOption.label,
          value: filterId === 'client' ? [] : 
                 filterId === 'date' ? { startDate: null, endDate: null } : ''
        };
        setFilters(prevFilters => [...prevFilters, newFilter]);
      }
    }
  }, [filters, filterOptions]);

  const handleRemoveFilter = useCallback((filterId: FilterId) => {
    setFilters(prevFilters => prevFilters.filter(filter => filter.id !== filterId));
  }, []);

  const handleFilterChange = useCallback((filterId: FilterId, value: Filter['value']) => {
    setFilters(prevFilters => 
      prevFilters.map(filter => 
        filter.id === filterId ? { ...filter, value } : filter
      )
    );
  }, []);

  const clientEntities = useMemo(() => {
    return expenses?.reduce((acc, expense) => {
      if (expense.client?.id && expense.client?.full_name) {
        acc.push({
          id: expense.client.id,
          name: expense.client.full_name
        });
      }
      return acc;
    }, [] as { id: string; name: string }[]) || [];
  }, [expenses]);

  const handleApplyFilters = () => {
    setIsFilterDrawerOpen(false);
  };

  const handleSortingChange: OnChangeFn<SortingState> = useCallback((updater) => {
    setSorting(old => (typeof updater === 'function' ? updater(old) : updater));
  }, []);

  const isRowSelectable = useCallback((expense: Expense) => {
    return !(expense.expense_invoice_items && expense.expense_invoice_items.length > 0);
  }, []);

  const handleRowClick = useCallback((expense: Expense) => {
    handleEditExpense(expense);
  }, [handleEditExpense]);

  const getRowKey = useCallback((expense: Expense) => expense.id, []);

  const onSelectionChange = useCallback((selectedIds: string[]) => {
    setSelectedExpenses(prevSelected => {
      if (JSON.stringify(prevSelected) !== JSON.stringify(selectedIds)) {
        return selectedIds;
      }
      return prevSelected;
    });
  }, []);

  const columns: ColumnDef<Expense>[] = useMemo(() => [
    {
      accessorKey: 'date',
      header: 'Date',
      cell: ({ getValue }) => {
        const expenseDate = new Date(getValue() as string);
        return format(expenseDate, isThisYear(expenseDate) ? 'MMM d' : 'MMM d, yyyy');
      },
    },
    {
      accessorKey: 'merchant',
      header: 'Merchant',
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      cell: ({ getValue }) => formatCurrency(getValue() as number),
    },
    {
      accessorKey: 'client',
      header: 'Client',
      cell: ({ getValue }) => {
        const client = getValue() as { full_name: string } | null;
        return client ? client.full_name : '-';
      },
    },
    {
      accessorKey: 'project',
      header: 'Project',
      cell: ({ getValue }) => {
        const project = getValue() as { name: string } | null;
        return project ? (project.name || 'Untitled Project') : '-';
      },
    },
    {
      accessorKey: 'expense_invoice_items',
      header: 'Invoice',
      cell: ({ getValue }) => {
        const items = getValue() as ExpenseInvoiceItem[] | null | undefined;
        if (items && items.length > 0 && items[0].invoice_item?.invoice_id) {
          return (
            <Link 
              to="/invoice/$id" 
              params={{ id: items[0].invoice_item.invoice_id }}
              search={{ from: '/expenses' }}
            >
              View Invoice
            </Link>
          );
        }
        return 'Not Invoiced';
      },
    },
  ], []);

  const handleCreateInvoice = useCallback(async () => {
    if (!organizationId) {
      console.error('No organization found');
      return;
    }

    const selectedExpenseData = memoizedExpenses?.filter(expense => selectedExpenses.includes(expense.id)) || [];

    if (selectedExpenseData.length === 0) {
      console.error('No expenses selected');
      return;
    }

    try {
      const newInvoice = await createInvoiceMutation.mutateAsync({
        organization_id: organizationId,
        client_id: selectedExpenseData[0]?.client_id || undefined,
        project_id: selectedExpenseData[0]?.project_id || undefined,
        expenses: selectedExpenseData,
      });

      setSelectedExpenses([]);
      navigate({ to: '/invoice/$id', params: { id: newInvoice.id } });
    } catch (error) {
      console.error('Error creating invoice:', error);
      alert('Failed to create invoice. Please try again.');
    }
  }, [organizationId, memoizedExpenses, selectedExpenses, createInvoiceMutation, navigate]);

  const handleAddNewExpense = useCallback(() => {
    setEditingExpense(null);
    setIsExpenseDrawerOpen(true);
  }, [setIsExpenseDrawerOpen]);

  const tabCounts = useMemo(() => {
    if (!expenses) return { all: 0, notInvoiced: 0, invoiced: 0 };
    return expenses.reduce((acc, expense) => {
      acc.all++;
      if (expense.expense_invoice_items && expense.expense_invoice_items.length > 0) {
        acc.invoiced++;
      } else {
        acc.notInvoiced++;
      }
      return acc;
    }, { all: 0, notInvoiced: 0, invoiced: 0 });
  }, [expenses]);

  const tabs = useMemo(() => {
    const tabData = [
      { id: "all", label: "All", count: tabCounts.all, isLoading: isLoading },
      { id: "notInvoiced", label: "Not Invoiced", count: tabCounts.notInvoiced, isLoading: isLoading },
      { id: "invoiced", label: "Invoiced", count: tabCounts.invoiced, isLoading: isLoading },
    ];

    return tabData;
  }, [tabCounts, isLoading]);

  const handleTabChange = useCallback((tabId: string) => {
    setActiveTab(tabId);
  }, []);

  const emptyStateConfig = {
    icon: <Expense32 />,
    message: "No expenses found",
    subMessage: "Start by adding a new expense to your list.",
    action: {
      label: "Add New Expense",
      onClick: handleAddNewExpense
    }
  };

  // Modify the calculateSummaries function
  const calculateSummaries = useCallback(() => {
    if (!expenses) return { last30DaysExpenses: 0, reimbursed: 0, pending: 0 };

    const thirtyDaysAgo = subDays(new Date(), 30);

    return expenses.reduce((acc, expense) => {
      const expenseDate = new Date(expense.date);
      if (expenseDate >= thirtyDaysAgo) {
        acc.last30DaysExpenses += expense.amount;
      }
      if (expense.expense_invoice_items && expense.expense_invoice_items.length > 0) {
        acc.reimbursed += expense.amount;
      } else {
        acc.pending += expense.amount;
      }
      return acc;
    }, { last30DaysExpenses: 0, reimbursed: 0, pending: 0 });
  }, [expenses]);

  const summaries = useMemo(() => calculateSummaries(), [calculateSummaries]);

  const summaryCards = useMemo(() => {
    const cards = [
      {
        title: "Last 30 Days",
        amount: formatCurrency(summaries.last30DaysExpenses),
        onClick: () => {
          setIsLast30DaysSelected(prev => !prev);
          setActiveTab('all');
        },
        isSelected: isLast30DaysSelected,
        selectedBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        isLoading: isLoading
      },
      {
        title: "Reimbursed",
        amount: formatCurrency(summaries.reimbursed),
        onClick: () => {
          setActiveTab('invoiced');
          setIsLast30DaysSelected(false);
        },
        isSelected: activeTab === 'invoiced' && !isLast30DaysSelected,
        selectedBackgroundColor: '#305D50',
        isLoading: isLoading
      },
      {
        title: "Pending",
        amount: formatCurrency(summaries.pending),
        onClick: () => {
          setActiveTab('notInvoiced');
          setIsLast30DaysSelected(false);
        },
        isSelected: activeTab === 'notInvoiced' && !isLast30DaysSelected,
        selectedBackgroundColor: '#d53624',
        isLoading: isLoading
      }
    ];

    return cards;
  }, [summaries, activeTab, setActiveTab, isLast30DaysSelected, isLoading]);

  useEffect(() => {
    setPageHeaderProps({
      title: "Expenses",
      right: (
        <ButtonGroup>
          <Button buttonType="primary" onClick={handleAddNewExpense}>
            New Expense
          </Button>
          {selectedExpenses.length > 0 && (
            <Button onClick={handleCreateInvoice}>
              Create Invoice from Selected
            </Button>
          )}
        </ButtonGroup>
      ),
    });
  }, [setPageHeaderProps, selectedExpenses.length]);

  const handleBulkDelete = useCallback(async () => {
    setIsDeleteModalOpen(true);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    try {
      await bulkDeleteExpensesMutation.mutateAsync(selectedExpenses);
      setSelectedExpenses([]);
      queryClient.setQueryData(['expenses', organizationId], (old: Expense[] | undefined) => 
        old ? old.filter(expense => !selectedExpenses.includes(expense.id)) : []
      );
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Failed to delete expenses:', error);
      alert('Failed to delete expenses. Please try again.');
    }
  }, [selectedExpenses, bulkDeleteExpensesMutation, queryClient, organizationId]);

  const handleExportCSV = useCallback(() => {
    if (!expenses) return;
    
    const selectedExpenseData = expenses.filter(expense => selectedExpenses.includes(expense.id));
    const csvContent = exportExpensesToCSV(selectedExpenseData);
    const timestamp = format(new Date(), 'yyyy-MM-dd_HH-mm');
    downloadCSV(csvContent, `expenses_export_${timestamp}.csv`);
  }, [selectedExpenses, expenses]);

  return (
    <PageContainer ref={containerRef}>
      <SummaryCards 
        cards={summaryCards}
      />
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={handleTabChange}
        actionButtons={
          <FilterContainer>
            <FilterBoxesWrapper>
              {filters.map(filter => (
                <FilterBox
                  key={filter.id}
                  filter={filter}
                  onRemove={() => handleRemoveFilter(filter.id)}
                  onChange={(value) => handleFilterChange(filter.id, value)}
                  options={getFilterOptions(filter.id)}
                  entities={
                    filter.id === 'client' 
                      ? clientEntities 
                      : filter.id === 'category' 
                        ? categoryEntities 
                        : undefined
                  }
                />
              ))}
            </FilterBoxesWrapper>
            <FilterPicker
              options={filterOptions}
              onAddFilter={handleAddFilter}
              activeFilters={filters.map(filter => filter.id)}
            />
          </FilterContainer>
        }
      />
      <TableWrapper>
        <DataTable<Expense>
          columns={columns}
          data={memoizedExpenses}
          isLoading={isLoading}
          onRowClick={handleRowClick}
          getRowKey={getRowKey}
          onSelectionChange={onSelectionChange}
          selectedRows={selectedExpenses}
          isRowSelectable={isRowSelectable}
          containerRef={containerRef}
          emptyState={emptyStateConfig}
          rowClassName="expense-item"
          sorting={sorting}
          onSortingChange={handleSortingChange}
        />
      </TableWrapper>
      <AddExpenseDrawer
        isOpen={isExpenseDrawerOpen}
        setIsOpen={handleCloseExpenseDrawer}
        expense={editingExpense}
        onSave={handleSaveExpense}
        onDelete={handleDeleteExpense}
        onCreate={handleCreateExpense}
        organizationId={organizationId}
        updateLocalExpense={updateLocalExpense}
      />
      {isSmallScreen && (
        <FilterDrawer
          isOpen={isFilterDrawerOpen}
          setIsOpen={setIsFilterDrawerOpen}
          onApply={handleApplyFilters}
        >
          <MultipleEntityPicker
            selectedIds={filters.find(filter => filter.id === 'client')?.value as string[] || []}
            onChange={(value) => handleFilterChange('client', value)}
            entities={clientEntities}
            label="Clients"
            icon={<Client12 />}
            placement="bottom-start"
          />
          <DateRangePicker
            selectedRange={dateRange}
            onChange={(newRange) => {
              setDateRange(newRange);
              handleFilterChange('date', newRange);
            }}
            label="Date Range"
            id="expense-date-range"
            variant="preview"
            icon={<Calendar12 />}
          />
          <MultipleEntityPicker
            selectedIds={filters.find(filter => filter.id === 'category')?.value as string[] || []}
            onChange={(value) => handleFilterChange('category', value)}
            entities={categoryEntities}
            label="Category"
            icon={<Status12 />}
            placement="bottom-start"
          />
        </FilterDrawer>
      )}
      <SelectedExpensesOverlay
        selectedCount={selectedExpenses.length}
        onDelete={handleBulkDelete}
        onClearSelection={() => setSelectedExpenses([])}
        onExportCSV={handleExportCSV}
      />
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        title="Delete Expenses"
        message={`Are you sure you want to delete ${selectedExpenses.length} expense${selectedExpenses.length === 1 ? '' : 's'}? This action cannot be undone.`}
        confirmLabel="Delete"
        onConfirm={handleConfirmDelete}
        onCancel={() => setIsDeleteModalOpen(false)}
        isDestructive
      />
    </PageContainer>
  );
};

export default ExpensesPage;